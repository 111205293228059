import { CONSUMER_REQUEST_STATUS } from '../../constants/consumerRequestStatuses';

export const CONSUMER_REQ_ACTION_TYPE = Object.freeze({
  CONSUMER_REQ_CATCH_ERROR: 'CONSUMER_REQ_CATCH_ERROR',
  CONSUMER_REQ_CLEAR_ERROR: 'CONSUMER_REQ_CLEAR_ERROR',
  CONSUMER_REQ_SET_OVERLAY: 'CONSUMER_REQ_SET_OVERLAY',
  CONSUMER_REQ_SET_IN_EDIT_MODE: 'CONSUMER_REQ_SET_IN_EDIT_MODE',
  CONSUMER_REQ_UPDATE_EDITS: 'CONSUMER_REQ_UPDATE_EDITS',
  CONSUMER_REQ_CLEAR_EDITS: 'CONSUMER_REQ_CLEAR_EDITS',
  CONSUMER_REQ_SET_IS_UPDATING: 'CONSUMER_REQ_SET_IS_UPDATING',
  CONSUMER_REQ_SET_ID: 'CONSUMER_REQ_SET_ID',
});

export const CONSUMER_REQ_OVERLAY = Object.freeze({
  confirm_delete: 'confirm_delete',
});

export const CONSUMER_REQ_ERROR_MESSAGE = Object.freeze({
  accept_pro_fail:
    'Failed to accept pro on behalf of customer. Please try again.',
  approve_for_pm_fail:
    'Failed to approve on behalf of property manager. Please try again.',
  cancel_fail: 'Failed to cancel request. Please try again.',
  decline_for_pm_fail:
    'Failed to decline on behalf of property manager. Please try again.',
  decline_pro_fail:
    'Failed to decline pro on behalf of customer. Please try again.',
  resend_to_network_fail: 'Failed to resend to network. Please try again.',
  update_fail: 'Failed to update request. Please try again.',
  re_request_consumer_request_fail:
    'Failed to re-request consumer request. Please try again.',
});

// List of consumer req statuses that can be updated to 'rejected pr'
export const REJECTED_PR_ALLOWED_STATUSES = [
  CONSUMER_REQUEST_STATUS.pending_approval,
  CONSUMER_REQUEST_STATUS.pending,
];
