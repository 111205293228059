import React from 'react';
import Link from 'next/link';
import Text from './Text';
import { COLOR, SPACING } from './theme';

const CrumbSeparator: React.FC = () => (
  <Text
    aria-hidden="true"
    size="small"
    color="gray"
    element="span"
    style={{
      paddingLeft: SPACING.xxs,
      paddingRight: SPACING.xxs,
    }}
  >
    &gt;
  </Text>
);

export type Crumb = {
  route: string;
  label: string;
};

type Props = {
  crumbs: Crumb[];
  maxVisible?: number;
};

const Breadcrumbs: React.FC<Props> = ({ crumbs, maxVisible = 6 }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(
    maxVisible < crumbs.length,
  );
  const crumbLength = crumbs.length - 1;

  const renderExpanderCrumb = () => (
    <React.Fragment>
      <Text size="small" color="gray" element="span">
        <button
          type="button"
          title="Expand Breadcrumbs"
          onClick={() => setIsCollapsed(false)}
        >
          &hellip;
        </button>
        <style jsx>
          {`
            button {
              border: none;
              background: none;
              padding: 0;
            }
            button:hover {
              color: ${COLOR.blueHover};
              text-decoration: underline;
              cursor: pointer;
            }
          `}
        </style>
      </Text>
      <CrumbSeparator />
    </React.Fragment>
  );

  const renderAnchorCrumb = (route, label) => (
    <React.Fragment>
      <Text size="small" color="gray" element="span">
        <Link href={route} title={label}>
          {label}
        </Link>
        <style jsx>
          {`
            a:hover {
              color: ${COLOR.blueHover};
            }
          `}
        </style>
      </Text>
      <CrumbSeparator />
    </React.Fragment>
  );

  const renderCurrentCrumb = (label) => (
    <Text
      size="small"
      color="link"
      weight="bold"
      element="strong"
      style={{ textDecoration: 'none' }}
      title="Current Page"
      aria-current="page"
    >
      {label}
    </Text>
  );

  return (
    <nav aria-label="Breadcrumb">
      <ol>
        {crumbs.map((crumb, index) => {
          const { route, label } = crumb;
          return (
            <React.Fragment key={label}>
              <li className={isCollapsed ? 'collapsed' : ''}>
                {index < crumbLength
                  ? renderAnchorCrumb(route, label)
                  : renderCurrentCrumb(label)}
              </li>
              {index === 0 && (
                <li className={isCollapsed ? '' : 'collapsed'}>
                  {renderExpanderCrumb()}
                </li>
              )}
            </React.Fragment>
          );
        })}
        <style jsx>
          {`
            ol {
              margin: ${SPACING.xs}px 0;
              padding: 0;
            }
            li {
              display: inline-block;
              list-style-type: none;
            }
            li.collapsed:not(:first-child):not(:last-child) {
              display: none;
            }
          `}
        </style>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
