export enum MarketStatus {
  Active = 20,
  Pending = 10,
  Inactive = 0,
}

/**
 * @deprecated use MarketStatus enum
 */
export const MARKET_STATUS = Object.freeze({
  active: 20,
  pending: 10,
  inactive: 0,
});

export const ALL_MARKET_STATUSES = Object.values(MarketStatus);

export const ALL_MARKETS = Object.freeze({
  id: 'all_markets',
  name: 'All Markets',
});
