import { Promo } from '../../components/ProfilePage/ProfileCards/Promos';
import { IJobMaterial } from '../../__generated__/graphql';

export type JobEditsState = {
  address: string;
  consumerTotalCost: number;
  consumerFlatRate: number;
  consumerRateBase: number;
  consumerRateHour: number;
  imagesToAdd: File[];
  imagesToRemove: string[];
  lat: number;
  lng: number;
  materialsToUpdate: IJobMaterial[];
  materialsToAdd: IJobMaterial[];
  materialsToRemove: IJobMaterial[];
  newElapsedTime: number;
  newElapsedTimeNote: string;
  note: string;
  providerFlatRate: number;
  providerRateBase: number;
  providerRateHour: number;
  laborLineItemId: string;
};

export type JobState = {
  edits: JobEditsState;
  inEditMode: boolean;
  isUpdating: boolean;
  promo: Partial<Promo>;
  error?: Error;
  errorMessage?: string;
  id?: string;
  overlay?: React.ReactNode;
};

const initialPromoState: Promo = {} as Promo;

const initialEditsState: JobEditsState = {} as JobEditsState;

const initialState: JobState = {
  edits: initialEditsState,
  inEditMode: false,
  isUpdating: false,
  promo: initialPromoState,
} as JobState;

const consumerReqReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'JOB_CATCH_ERROR':
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case 'JOB_CLEAR_ERROR':
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
      };
    case 'JOB_SET_OVERLAY':
      return {
        ...state,
        overlay: action.overlay,
      };
    case 'JOB_SET_IN_EDIT_MODE':
      return {
        ...state,
        edits: initialEditsState,
        inEditMode: action.inEditMode,
      };
    case 'JOB_UPDATE_EDITS':
      return {
        ...state,
        edits: {
          ...state.edits,
          ...action.edits,
        },
      };
    case 'JOB_CLEAR_EDITS':
      return {
        ...state,
        edits: initialEditsState,
      };
    case 'JOB_SET_IS_UPDATING':
      return {
        ...state,
        error: action.isUpdating ? undefined : state.error,
        errorMessage: action.isUpdating ? undefined : state.errorMessage,
        isUpdating: action.isUpdating,
      };
    case 'JOB_SET_ID':
      return {
        ...state,
        promo: initialPromoState,
        id: action.id,
      };
    case 'JOB_SET_PROMO':
      return {
        ...state,
        promo: action.promo,
      };
    default:
      return state;
  }
};

export default consumerReqReducer;
