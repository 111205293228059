import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getIronSession } from 'iron-session';
import { initStore } from '../redux/store';

const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

const getOrCreateStore = async (ctx, initialState) => {
  if (isServer) {
    const ironSessionOptions = (await import('../server/session')).default;
    const session = await getIronSession(ctx.req, ctx.res, ironSessionOptions);
    return initStore(ctx, initialState, session);
  }

  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initStore(null, initialState);
  }

  return window[__NEXT_REDUX_STORE__];
};

const withStore = (App) =>
  class Redux extends Component {
    static propTypes = {
      initialReduxState: PropTypes.shape({}),
    };

    static defaultProps = {
      initialReduxState: {},
    };

    constructor(props) {
      super(props);
      this.reduxStore = initStore(null, props.initialReduxState);
    }

    /**
     * @param {import('next').NextPageContext} appContext
     * */
    static async getInitialProps(appContext) {
      const reduxStore = await getOrCreateStore(appContext.ctx);

      appContext.ctx.reduxStore = reduxStore; // eslint-disable-line no-param-reassign

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(appContext);
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      };
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  };

export default withStore;
