import { UserData } from '../reducers/userReducer';

export const CATCH_USER_ERROR = 'CATCH_USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_USER = 'SET_USER';
export const START_USER_NETWORK_REQ = 'START_USER_NETWORK_REQ';
export const LOGGING_OUT = 'LOGGING_OUT';

interface CatchUserErrorAction {
  type: typeof CATCH_USER_ERROR;
  error: Error | string;
}

interface ClearUserAction {
  type: typeof CLEAR_USER;
}

interface SetUserAction {
  type: typeof SET_USER;
  user: UserData;
}

interface StartUserNetworkReqAction {
  type: typeof START_USER_NETWORK_REQ;
}

interface LoggingOutAction {
  type: typeof LOGGING_OUT;
}

export type UserActionTypes =
  | CatchUserErrorAction
  | ClearUserAction
  | SetUserAction
  | StartUserNetworkReqAction
  | LoggingOutAction;
