import React from 'react';
import { components as SelectComponents } from 'react-select';

function Input(props) {
  // Override react-select autoComplete prop from "off" to "new-password" because "off" is ignored by most browsers
  // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
  const modifiedProps = {
    ...props,
    autoComplete: 'new-password',
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SelectComponents.Input {...modifiedProps} />;
}

export default Input;
