import classnames from 'classnames';
import css from 'styled-jsx/css';
import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

import { COLOR, SPACING, TRANSITION } from './theme';
import Heading from './Heading';
import Icon from './Icon';
import ButtonGroup from '../common/ButtonGroup';

try {
  // Next js uses #__next as app element
  ReactModal.setAppElement('#__next');
} catch (errorOne) {
  try {
    // Storybook uses #root as app element
    ReactModal.setAppElement('#root');
  } catch (errorTwo) {
    // Do nothing; nothing will break, but a11y won't be 100%
  }
}

const { className: overlayClassName, styles: overlayStyles } = css.resolve`
  div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(31, 47, 60, 0.9);
    z-index: 10;
  }
`;

const { className: modalClassName, styles: modalStyles } = css.resolve`
  div {
    background: ${COLOR.offWhite};
    border-radius: 4px;
    left: 50%;
    max-height: 90vh;
    width: 90%;
    max-width: 580px;
    outline: none;
    overflow: auto;
    padding: 0;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

type Props = PropsWithChildren<{
  className?: string;
  isOpen?: boolean;
  onRequestClose: () => void | Promise<void>;
  title: string;
  actions?: React.ReactNode;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  subHeading?: React.ReactNode;
}>;

const SimpleModal: React.FC<Props> = ({
  children,
  className,
  isOpen = false,
  onRequestClose,
  title,
  actions,
  handleSubmit,
  subHeading,
}) => (
  <ReactModal
    className={classnames(className, modalClassName)}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    overlayClassName={overlayClassName}
  >
    <div className="inner">
      <header
        style={{
          marginBottom: SPACING.sm,
        }}
      >
        <div>
          {title && (
            <Heading variant="h5" element="h2">
              {title}
            </Heading>
          )}
        </div>
        {subHeading}
      </header>
      {handleSubmit ? (
        <form onSubmit={handleSubmit}>
          {children}
          <ButtonGroup marginTop={SPACING.lg} marginBottom={0} autoWidth>
            {actions}
          </ButtonGroup>
        </form>
      ) : (
        <React.Fragment>
          {children}
          <ButtonGroup marginTop={SPACING.lg} marginBottom={0} autoWidth>
            {actions}
          </ButtonGroup>
        </React.Fragment>
      )}
      <button
        className="exit"
        type="button"
        onClick={() => {
          if (typeof onRequestClose === 'function') {
            onRequestClose();
          }
        }}
      >
        <Icon icon="close" style={{ fontSize: 14 }} />
      </button>
    </div>
    <style jsx>
      {`
        .inner {
          padding: ${SPACING.md}px;
          position: relative;
        }

        .inner :global(.button-group) {
          justify-content: flex-end;
        }

        .exit {
          align-items: center;
          background: transparent;
          border: none;
          color: ${COLOR.darkGray};
          cursor: pointer;
          display: flex;
          height: ${SPACING.md}px;
          justify-content: center;
          margin: 0;
          padding: 0;
          position: absolute;
          top: ${SPACING.md}px;
          right: ${SPACING.md}px;
          width: ${SPACING.md}px;
          transition: color ${TRANSITION.duration} ${TRANSITION.timingFunction};
        }

        .exit:hover,
        .exit:focus {
          color: ${COLOR.blue};
        }
      `}
    </style>
    {modalStyles}
    {overlayStyles}
  </ReactModal>
);

export default SimpleModal;
