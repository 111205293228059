import React, { ChangeEvent, PropsWithChildren } from 'react';
import { COLOR, SPACING } from './theme';
import Text from './Text';
import Icon from './Icon';

type Props = PropsWithChildren<{
  checked?: boolean;
  disabled?: boolean;
  id: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any | Promise<any>;
  value?: string;
  type?: 'radio' | 'checkbox';
}>;

const Selector: React.FC<Props> = ({
  checked,
  children,
  disabled = false,
  id,
  name,
  onChange,
  value,
  type = 'radio',
}) => {
  const fontColor = () => {
    if (disabled) {
      return 'gray';
    }
    if (checked) {
      return 'link';
    }
    return 'default';
  };

  return (
    <div className="root">
      <input
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
      />
      <label htmlFor={id}>
        <Text
          className="label-span"
          element="span"
          size="medium"
          color={fontColor()}
          weight={checked ? 'semi' : 'regular'}
        >
          {children}
        </Text>
        <div className="input-icon" aria-hidden="true">
          {type === 'checkbox' && (
            <Icon icon={checked ? 'checkBox' : 'checkBoxOutlineBlank'} />
          )}
          {type === 'radio' && (
            <Icon
              icon={checked ? 'radioButtonChecked' : 'radioButtonUnchecked'}
            />
          )}
        </div>
      </label>
      <style jsx>
        {`
          /* Default State */
          .root {
            height: ${SPACING.lg}px;
            margin-bottom: ${SPACING.sm}px;
            position: relative;
          }

          .root:last-child {
            margin-bottom: unset;
          }

          label {
            align-items: center;
            background-color: ${COLOR.white};
            border: 1px solid ${COLOR.darkGray};
            border-radius: 2.5px;
            color: ${COLOR.black};
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            height: ${SPACING.lg}px;
            padding: 0 ${SPACING.xs}px 0 ${SPACING.sm}px;
          }
          label :global(span.label-span) {
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: none !important;
          }
          input {
            height: 1px; /* making 0 hides from some screen readers */
            left: -10px; /* hide by pushing out of container */
            opacity: 0;
            position: absolute;
            width: 1px;
          }

          input ~ label > .input-icon {
            color: ${COLOR.darkGray};
          }

          /* Disabled State */
          input:disabled ~ label {
            cursor: auto;
            color: ${COLOR.neutral30};
            border-color: ${COLOR.neutral10};
          }

          input:disabled ~ label > .input-icon {
            color: ${COLOR.neutral10};
          }

          /* Checked State */
          input:checked ~ label > .input-icon {
            color: ${COLOR.blue};
          }

          input:checked ~ label {
            border-color: ${COLOR.blue};
            border-width: 2px;
          }

          /* Hover State */
          input:not(:disabled):not(:checked) ~ label:hover {
            background-color: ${COLOR.softGray};
            border-color: ${COLOR.black};
          }
        `}
      </style>
    </div>
  );
};

export default Selector;
