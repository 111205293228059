import builder, { Sort } from 'elastic-builder';
import { SEARCH_SORT_DIRECTION } from '../../constants/search';
import { MustQuery, SearchFilters } from './common';

export const generateUserPropertySearchBarSearch = (
  searchTerm: string,
): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder.boolQuery().should([
        builder.matchQuery('id', searchTerm),
        builder
          .functionScoreQuery()
          .functions([
            builder
              .decayScoreFunction('exp', 'created_at')
              .origin('now')
              .scale('365d')
              .offset('2d')
              .decay(0.9),
          ])
          .query(
            builder
              .boolQuery()
              .should([
                builder.matchQuery('zipcode', searchTerm),
                builder.matchQuery('address', searchTerm),
                builder.multiMatchQuery(['name', 'name.joined'], searchTerm),
              ]),
          ),
      ]),
    )
    .minScore(0.01);

  return searchBody;
};

export const generateUserPropertySearchPage = ({
  searchTerm,
  propertyClasses,
  sortBy,
  sortDirection,
}: SearchFilters): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [];
  if (propertyClasses?.length) {
    mustQueries.push(builder.termsQuery('type', propertyClasses));
  }

  const filterBoolQuery = builder
    .boolQuery()
    .must([...mustQueries, builder.boolQuery()]);

  const searchBody = builder
    .requestBodySearch()
    .query(
      builder
        .boolQuery()
        .filter(filterBoolQuery)
        .should([
          builder.matchQuery('id', searchTerm),
          builder
            .functionScoreQuery()
            .functions([
              builder
                .decayScoreFunction('exp', 'created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
            ])
            .query(
              builder
                .boolQuery()
                .should([
                  builder.matchQuery('zipcode', searchTerm),
                  builder.matchQuery('address', searchTerm),
                  builder.multiMatchQuery(['name', 'name.joined'], searchTerm),
                ]),
            ),
        ]),
    )
    .minScore(0.01);

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection || SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};
