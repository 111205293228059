// react-select docs: https://react-select.com
// design system docs: https://zeroheight.com/83195d329/p/81e4f5/b/803486
import css from 'styled-jsx/css';
import React, { FocusEventHandler, ReactNode } from 'react';
import Select from 'react-select';

import getInputAccentColor from '../utils/getInputAccentColor';
import getInputTextColorName from '../utils/getInputTextColorName';
import Icon from '../Icon';
import Input from './Input';
import InputHelperText from '../InputHelperText';
import Option from './Option';
import styles from './styles';
import Text from '../Text';
import { SPACING } from '../theme';

const { className: glyphClassName, styles: glyphStyles } = css.resolve`
  svg {
    left: 8px;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const { className: labelSpanClassName, styles: labelSpanStyles } = css.resolve`
  span {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 1px 0;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export type DropdownOption<
  TVal = string | number | Record<string, unknown> | null,
> = {
  value?: TVal;
  label?: string;
  subLabel?: string | string[];
  icon?: string | ReactNode | Record<string, unknown>;
  [key: string]: unknown;
};
type BaseDropdownProps = {
  inputWrapperDataTestId?: string;
  className?: string;
  components?: Record<string, unknown>;
  filterOption?: (
    option: {
      label: string;
      value: string;
      data: DropdownOption;
    },
    inputValue: string,
  ) => boolean;
  glyph?: string;
  helperText?: string;
  id?: string;
  inputValue?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  label?: ReactNode;
  loadingMessage?: () => string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  minMenuHeight?: number;
  name?: string;
  noOptionsMessage?: () => string;
  // onBlur?: (e: FocusEvent) => void;
  onBlur?: FocusEventHandler<HTMLElement> | undefined;
  onFocus?: FocusEventHandler<HTMLElement> | undefined;
  blurInputOnSelect?: boolean;
  onInputChange?: (
    newValue: string,
    actionMeta: {
      action: 'set-value' | 'input-change' | 'input-blur' | 'menu-close';
    },
  ) => void;
  onMenuScrollToBottom?: (e: MouseEvent) => void;
  options: DropdownOption[] | null;
  required?: boolean;
  hideLabel?: boolean;
  isTruncated?: boolean;
  placeholder?: string;
  tooltip?: JSX.Element | null;
};

export type OnDropdownChange<TIsMulti extends 'multi' | 'single'> = (
  o: TIsMulti extends 'multi' ? DropdownOption[] : DropdownOption,
  meta: {
    name:
      | 'clear'
      | 'create-option'
      | 'deselect-option'
      | 'pop-value'
      | 'remove-value'
      | 'select-option'
      | 'set-value';
  },
) => void;

export type DropdownProps = BaseDropdownProps &
  (
    | {
        isMulti: true;
        value?: DropdownOption[] | null;
        onChange?: OnDropdownChange<'multi'>;
      }
    | {
        isMulti?: false;
        value?: DropdownOption | null;
        onChange?: OnDropdownChange<'single'>;
      }
    | {
        isMulti?: boolean;
        value?: DropdownOption | DropdownOption[] | null;
        onChange?: OnDropdownChange<'single' | 'multi'>;
      }
  );

function Dropdown({
  className,
  components = {},
  filterOption,
  glyph,
  helperText,
  id,
  inputValue,
  isClearable = false,
  isDisabled = false,
  isInvalid = false,
  isLoading = false,
  isMulti = false,
  isSearchable,
  label,
  loadingMessage,
  menuPlacement = 'auto',
  minMenuHeight = 252,
  name,
  noOptionsMessage,
  onBlur,
  blurInputOnSelect,
  onChange,
  onFocus,
  onInputChange,
  onMenuScrollToBottom,
  options,
  placeholder,
  required = false,
  value,
  hideLabel = false,
  isTruncated = false,
  inputWrapperDataTestId,
  tooltip = null,
}: DropdownProps): JSX.Element {
  const [isFocused, setIsFocused] = React.useState(false);

  const accentColor = getInputAccentColor({
    disabled: isDisabled,
    isFocused,
    isInvalid,
  });
  const labelColor = getInputTextColorName({
    disabled: isDisabled,
    isFocused,
    isInvalid,
  });

  const menuPortalTarget =
    typeof window === 'undefined' ? undefined : document.body || undefined;

  return (
    <div className={className}>
      <label>
        {label && !hideLabel && (
          <Text
            className={labelSpanClassName}
            color={labelColor}
            element="span"
            size="medium"
            weight="bold"
          >
            {required && '* '}
            {label}
            {tooltip && <div className="hint">{tooltip}</div>}
          </Text>
        )}
        <div
          aria-label={hideLabel && typeof label === 'string' ? label : ''}
          data-testid={inputWrapperDataTestId}
        >
          <Select
            components={{
              Input,
              Option,
              ...components,
            }}
            filterOption={filterOption}
            hideSelectedOptions={false}
            id={id}
            blurInputOnSelect={blurInputOnSelect}
            inputValue={inputValue}
            instanceId={id}
            isClearable={!!isClearable}
            isDisabled={!!isDisabled}
            isLoading={!!isLoading}
            isMulti={isMulti}
            isSearchable={isSearchable}
            loadingMessage={loadingMessage}
            menuPlacement={menuPlacement}
            menuPortalTarget={menuPortalTarget}
            // @ts-expect-error(2322) can provide custom props for changing style dynamically
            showGlyph={glyph !== undefined}
            minMenuHeight={minMenuHeight}
            name={name || id}
            noOptionsMessage={noOptionsMessage}
            onBlur={(event) => {
              setIsFocused(false);
              if (typeof onBlur === 'function') {
                onBlur(event);
              }
            }}
            onFocus={(event) => {
              setIsFocused(true);
              if (typeof onFocus === 'function') {
                onFocus(event);
              }
            }}
            onChange={onChange as any}
            onInputChange={onInputChange}
            onMenuScrollToBottom={onMenuScrollToBottom as any}
            options={options as any}
            placeholder={placeholder}
            styles={styles}
            value={value}
            classNamePrefix={isTruncated ? 'truncate' : ''}
            closeMenuOnSelect={!isMulti}
          />
          {glyph && (
            <Icon
              className={glyphClassName}
              icon={glyph}
              style={{ color: accentColor }}
            />
          )}
        </div>
      </label>
      {helperText && (
        <InputHelperText isInvalid={isInvalid}>{helperText}</InputHelperText>
      )}
      <style jsx>
        {`
          label {
            display: block;
            margin: 0;
            padding: 0;
          }

          div {
            position: relative;
          }

          :global(.truncate__menu-list div.wrapper) {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .hint {
            display: inline;
          }
          :global(.consumer-dropdown > label > span) {
            padding: 0 0 ${SPACING.xxs}px 0;
          }
        `}
      </style>
      {glyphStyles}
      {labelSpanStyles}
    </div>
  );
}

export default Dropdown;
