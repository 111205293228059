import builder, { Sort } from 'elastic-builder';
import { SEARCH_SORT_DIRECTION } from '../../constants/search';
import { MustQuery, SearchFilters } from './common';

export const generateOrganizationDropownSearch = (
  searchTerm: string | undefined,
  sortBy?: string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
): builder.RequestBodySearch => {
  const searchBody = builder.requestBodySearch();

  if (searchTerm) {
    searchBody.query(
      builder
        .boolQuery()
        .must([builder.matchPhrasePrefixQuery('name', searchTerm)]),
    );
  }

  if (sortBy) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection || SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};

export const generateOrganizationSearchBarSearch = (
  searchTerm: string,
): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(builder.multiMatchQuery(['id', 'name'], searchTerm))
    .minScore(0.01);

  return searchBody;
};

export const generateOrganizationSearchPage = ({
  searchTerm,
  marketCountRange,
  sortBy,
  sortDirection,
}: SearchFilters): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [];
  if (marketCountRange?.gte) {
    mustQueries.push(
      builder.rangeQuery('market_count').gte(marketCountRange.gte),
    );
  }

  if (marketCountRange?.lte) {
    mustQueries.push(
      builder.rangeQuery('market_count').lt(marketCountRange.lte),
    );
  }

  const filterBoolQuery = builder
    .boolQuery()
    .must([...mustQueries, builder.boolQuery()]);

  const searchBody = builder
    .requestBodySearch()
    .query(
      builder
        .boolQuery()
        .filter(filterBoolQuery)
        .should([
          builder.matchQuery('id', searchTerm),
          builder.matchQuery('name', searchTerm),
        ]),
    )
    .minScore(0.01);

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection || SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};
