// TODO move to homee-utils
export enum InsuranceStatus {
  Clear = 'clear',
  Pending = 'pending',
  Fail = 'fail',
  Expired = 'expired',
  Missing = 'missing',
}

/**
 * @deprecated use enum
 */
export const INSURANCE_STATUS = {
  cleared: 'clear',
  cleared_coi: 'cleared:coi',
  cleared_coterie: 'cleared:coterie',
  cleared_manual: 'cleared:manual',
  expired: 'expired',
  fail: 'fail',
  missing: 'missing',
  pending: 'pending',
};

export const ALL_INSURANCE_STATUSES = Object.values(INSURANCE_STATUS);

export const INSURANCE_CLEARED_STATUS = {
  coi: 'cleared:coi',
  coterie: 'cleared:coterie',
  manual: 'cleared:manual',
};
