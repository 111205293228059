import React from 'react';
import classnames from 'classnames';

import Text from './Text';
import ThreeLoadingDots from './ThreeLoadingDots';
import { COLOR, TRANSITION } from './theme';

type Props = {
  className?: string;
  checked?: boolean;
  checkedText?: string;
  disabled?: boolean;
  id?: string;
  isLoading?: boolean;
  onChange?: (isChecked: boolean) => any | Promise<any>;
  unCheckedText?: string;
};

const Switch = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      checked = false,
      checkedText = '',
      className,
      disabled = false,
      id,
      isLoading = false,
      onChange,
      unCheckedText = '',
    },
    ref,
  ) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(checked);

    React.useEffect(() => {
      setIsChecked(!!checked);
    }, [checked]);

    return (
      <button
        aria-busy={isLoading ? 'true' : undefined}
        aria-checked={isChecked}
        aria-label="Switch"
        className={classnames(className, {
          checked: isChecked,
          unchecked: !isChecked,
          loading: isLoading,
        })}
        data-action="aria-switch"
        disabled={disabled || isLoading}
        id={id}
        onClick={(event) => {
          event.preventDefault();
          if (typeof onChange === 'function') {
            onChange(!isChecked);
          } else {
            setIsChecked((prev) => !prev);
          }
        }}
        ref={ref}
        role="switch"
        type="button"
      >
        {isLoading && (
          <div className="loading-overlay">
            <ThreeLoadingDots height={13} />
          </div>
        )}
        <Text
          className="inner checked-text"
          element="span"
          size="small"
          weight="bold"
        >
          {checkedText}
        </Text>
        <Text
          className="inner unchecked-text"
          element="span"
          size="small"
          weight="bold"
        >
          {unCheckedText}
        </Text>
        <style jsx>
          {`
            button {
              background-color: ${COLOR.darkGray};
              border: 0;
              border-radius: 16px;
              cursor: pointer;
              display: inline-block;
              height: 24px;
              position: relative;
              width: 72px;
              transition: all ${TRANSITION.duration};
            }

            button:after {
              background-color: #fff;
              border-radius: 50% 50%;
              content: ' ';
              cursor: pointer;
              height: 16px;
              left: 4px;
              position: absolute;
              top: 4px;
              transition: left ${TRANSITION.duration}
                cubic-bezier(0.35, 0, 0.25, 1);
              width: 16px;
            }

            button :global(.inner) {
              color: ${COLOR.white} !important;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              text-align: center;
              text-overflow: ellipsis;
              top: 5px;
              transition: opacity 40ms ${TRANSITION.timingFunction} 0ms;
              width: 34px;
            }

            button :global(.unchecked-text) {
              left: 26px;
            }

            button :global(.checked-text) {
              left: 11px;
            }

            /* checked */

            button.checked {
              background-color: ${COLOR.green};
              border: 0;
            }

            button.checked:after {
              left: 51px;
            }

            button.checked :global(.checked-text),
            button.unchecked :global(.unchecked-text) {
              opacity: 1;
              transition: opacity ${TRANSITION.duration}
                ${TRANSITION.timingFunction} 50ms;
            }

            /* disabled */

            button:disabled {
              opacity: 0.4;
            }

            /* Pointer events */

            button:disabled {
              pointer-events: none;
            }

            /* Hover states */

            button:not(:disabled):hover {
              filter: brightness(115%);
            }

            /* Loading */

            .loading-overlay {
              align-items: center;
              bottom: 0;
              display: flex;
              height: 100%;
              justify-content: center;
              left: 28px;
              position: absolute;
              width: 45%;
            }

            button.checked .loading-overlay {
              left: 10px;
            }

            .loading {
              color: ${COLOR.lightGray};
            }

            .loading :global(i),
            .loading :global(span) {
              visibility: hidden;
            }
          `}
        </style>
      </button>
    );
  },
);

Switch.displayName = 'Switch';

export default Switch;
