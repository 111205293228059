import React from 'react';
import Icon from './Icon';
import { Icons } from './Icon/Icon';
import Text from './Text';
import { COLOR, TRANSITION, SPACING, BREAKPOINT_VALUE } from './theme';

export type Option = {
  label: string;
  value: string;
  disabled?: boolean;
  icon?: Icons;
};

type Props = {
  className?: string;
  options?: Option[];
  onChange?: (value: string) => any | Promise<any>;
  value?: string | null;
};

const Tabs: React.FC<Props> = ({
  className,
  options = [],
  onChange,
  value = null,
}) => {
  const [currentValue, setCurrentValue] = React.useState<string | null>(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <ul role="tablist" className={className}>
      {options.map((option) => {
        const isSelected = option.value === currentValue;

        return (
          <li key={option.label} role="presentation">
            <button
              type="button"
              className={isSelected ? 'tab selected' : 'tab'}
              role="tab"
              aria-setsize={options.length}
              aria-selected={isSelected ? 'true' : 'false'}
              aria-label={option.label}
              disabled={option.disabled}
              onClick={(event) => {
                event.preventDefault();
                if (typeof onChange === 'function') {
                  onChange(option.value);
                } else {
                  setCurrentValue(option.value);
                }
              }}
            >
              <Text
                color={isSelected ? 'selected' : 'default'}
                element="span"
                size="medium"
                weight={isSelected ? 'bold' : 'regular'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {option?.icon && (
                  <span className="icon">
                    <Icon icon={option.icon} />
                  </span>
                )}
                <span className="label">{option.label}</span>
              </Text>
            </button>
          </li>
        );
      })}
      <style jsx>
        {`
          ul {
            align-items: stretch;
            background-color: transparent;
            display: flex;
            min-height: ${SPACING.lg}px;
            padding: 0;
            position: relative;
          }

          li {
            display: block;
            flex: 1 1 0;
            margin: 0;
            padding: 0;
          }

          button.tab {
            background-color: transparent;
            border: none;
            border-bottom: ${SPACING.xxs}px solid ${COLOR.lightGray};
            display: block;
            height: 100%;
            transition: border-bottom-color ${TRANSITION.duration}
              ${TRANSITION.timingFunction};
            width: 100%;
          }

          button.tab:not(.selected):not(:disabled):hover,
          button.tab:not(.selected):not(:disabled):focus {
            border-bottom-color: ${COLOR.darkGray};
          }

          button.tab.selected {
            border-bottom-color: ${COLOR.blue};
            pointer-events: none;
          }

          button.tab :global(span > .icon) {
            display: none;
          }

          @media (max-width: ${BREAKPOINT_VALUE.md}px) {
            button.tab :global(span > .icon + .label) {
              display: none;
            }

            button.tab :global(span > .icon) {
              display: block;
            }

            button.tab :global(span > .icon > svg) {
              width: ${SPACING.md}px;
              height: ${SPACING.md}px;
            }
          }
        `}
      </style>
    </ul>
  );
};

export default Tabs;
