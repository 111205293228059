import bodybuilder from 'bodybuilder/browser/bodybuilder.min';
import { defaultsDeep } from 'lodash';

import { SEARCH_DEFAULT_OPTION } from '../../constants/search';

const createQueryBuilder = (options) => {
  const {
    page,
    limit: size,
    sort,
  } = defaultsDeep(options, SEARCH_DEFAULT_OPTION);

  const builder = bodybuilder();

  // Pagination.
  const from = size * (page - 1);
  builder.from(from).size(size);

  // Sorting.
  if (sort.fields) {
    sort.fields.forEach((field) => {
      builder.sort(field, { order: sort.direction, mode: 'max' });
    });
  }

  return builder;
};

export default createQueryBuilder;
