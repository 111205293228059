import {
  CompanyAvailability,
  CompanyOrderByType,
  CompanyStatus,
} from '../../__generated__/graphql';
import {
  CLEAR_COMPANIES_FILTERS,
  CompaniesFiltersActionTypes,
  UPDATE_COMPANIES_FILTERS,
} from '../constants/companiesFiltersTypes';

export type CompaniesFiltersState = {
  status: CompanyStatus | null;
  market: { id: string | null; name: string | null };
  availability: CompanyAvailability | null;
  limit: number;
  offset: number;
  companyIds: string[] | null;
  companyNames: string[] | null;
  orderBy: CompanyOrderByType;
};

export const initialState: CompaniesFiltersState = {
  status: null,
  market: { id: null, name: null },
  availability: null,
  orderBy: CompanyOrderByType.Name,
  limit: 20,
  offset: 0,
  companyIds: null,
  companyNames: null,
} as const;

export const FILTER_KEYS = [
  'status',
  'availability',
  'orderBy',
  'limit',
  'offset',
  'companyIds',
] as const;

const companiesFiltersReducer = (
  state: CompaniesFiltersState = initialState,
  action: CompaniesFiltersActionTypes,
): CompaniesFiltersState => {
  switch (action.type) {
    case CLEAR_COMPANIES_FILTERS:
      return {
        ...state,
        status: null,
        market: { id: null, name: null },
        availability: null,
        limit: 20,
        offset: 0,
        orderBy: CompanyOrderByType.Name,
        companyIds: null,
        companyNames: null,
      };
    case UPDATE_COMPANIES_FILTERS:
      return { ...state, ...action.updated };
    default:
      return state;
  }
};

export default companiesFiltersReducer;
