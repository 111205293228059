import css from 'styled-jsx/css';
import React from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { TOAST_DATA_TEST_IDS } from '@app/data-test-id';
import { removeToast, clearAllToasts } from '../../redux/actions/toastsActions';
import { Icon, Text } from '../homee-ui';
import { SPACING, COLOR, Z_INDEX } from '../homee-ui/theme';
import { ToastType } from '../../redux/constants';
import { RootDispatch, RootState } from '../../redux/store';
import { ToastsState } from '../../redux/reducers/toastsReducer';

const toastColors = {
  [ToastType.Standard]: COLOR.green,
  [ToastType.Error]: COLOR.red,
  [ToastType.Warn]: COLOR.orange,
};

const { className: overlayClassName, styles: overlayStyles } = css.resolve`
  div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(31, 47, 60, 0.9);
    z-index: ${Z_INDEX.toast};
  }
`;

const { className: modalClassName, styles: modalStyles } = css.resolve`
  div {
    outline: 0;
    max-height: 90vh;
    overflow: auto;
    padding: 0;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ToastMessageModal: React.FC = () => {
  const dispatch = useDispatch<RootDispatch>();

  const toastList = useSelector<RootState, ToastsState>(({ toasts }) => toasts);

  return (
    <ReactModal
      className={modalClassName}
      overlayClassName={overlayClassName}
      isOpen={!!toastList.length}
      onRequestClose={() => dispatch(clearAllToasts())}
    >
      {toastList.map((toast, index) => (
        <div key={uuidv4()}>
          <Text onDark>
            {}
            <span
              data-testid={TOAST_DATA_TEST_IDS.ToastContent}
              dangerouslySetInnerHTML={{ __html: toast.message }}
            />
          </Text>
          {toast.element}
          {!toast.hideClose && (
            <button
              data-testid={TOAST_DATA_TEST_IDS.ToastModalClose}
              id={TOAST_DATA_TEST_IDS.ToastModalClose}
              type="button"
              onClick={() => dispatch(removeToast(index))}
            >
              <Icon icon="close" />
            </button>
          )}
          <style jsx>
            {`
              div {
                background-color: ${toastColors[toast.type]};
                border-radius: 5px;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
                display: flex;
                padding: ${SPACING.md}px;
                margin-top: ${SPACING.md}px;
              }

              button {
                align-items: center;
                background: none;
                border: none;
                color: ${COLOR.white};
                padding: 0;
                margin-left: ${SPACING.md}px;
                display: flex;
              }

              div :global(a) {
                color: ${COLOR.white};
                text-decoration: underline;
              }
            `}
          </style>
        </div>
      ))}
      {modalStyles}
      {overlayStyles}
    </ReactModal>
  );
};

ToastMessageModal.displayName = 'ToastMessageModal';

export default ToastMessageModal;
