import { REQUEST_ACTION_TYPE } from '../constants/editRequestConstants';
import { NETWORK_TYPE } from '../../constants/networkTypes';

const initialEditsState = {
  networkType: NETWORK_TYPE.open,
  occupied: true,
  residentIsConsumer: false,
};

const initialState = {
  defaultLimit: 0,
  defaultLimitLoading: false,
  edits: initialEditsState,
  error: undefined,
  errorMessage: undefined,
  tab: 'review',
};

const editRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION_TYPE.REQUEST_CATCH_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case REQUEST_ACTION_TYPE.REQUEST_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
      };
    case REQUEST_ACTION_TYPE.REQUEST_UPDATE_EDITS:
      return {
        ...state,
        edits: { ...state.edits, ...action.edits },
      };
    case REQUEST_ACTION_TYPE.REQUEST_CLEAR_EDITS:
      return {
        ...state,
        edits: initialEditsState,
        tab: initialState.tab,
      };
    case REQUEST_ACTION_TYPE.REQUEST_SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case 'REQUEST_FETCH_DEFAULT_LIMIT':
      return {
        ...state,
        defaultLimit: 0,
        defaultLimitLoading: true,
      };
    case 'REQUEST_SET_DEFAULT_LIMIT':
      return {
        ...state,
        defaultLimit: action.defaultLimit,
        defaultLimitLoading: false,
      };
    default:
      return state;
  }
};

export default editRequestReducer;
