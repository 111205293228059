import {
  PROVIDER_FILTERS_UPDATE,
  CLEAR_PROVIDER_FILTERS,
} from '../actions/providerFiltersActions';

const initialState = {
  serviceTypes: '',
  backgroundCheckStatus: '',
  insuranceStatus: '',
  identityVerification: '',
  sort: {
    orderBy: 'createdAt',
    sortOrder: 'DESC',
  },
};

export const FILTER_KEYS = [
  'serviceTypes',
  'backgroundCheckStatus',
  'insuranceStatus',
  'identityVerification',
];

const providerFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROVIDER_FILTERS_UPDATE:
      return { ...state, ...action.filters };
    case CLEAR_PROVIDER_FILTERS:
      return {
        ...state,
        serviceTypes: null,
        backgroundCheckStatus: null,
        insuranceStatus: null,
        identityVerification: null,
      };
    default:
      return state;
  }
};

export default providerFiltersReducer;
