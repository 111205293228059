import { useTreatments } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';

export const useFlags = (flagNames) => {
  const {
    id: userId = 'anonymous',
    first_name: firstName = null,
    last_name: lastName = null,
    email = null,
  } = useSelector(({ user: { data = {} } = {} } = {}) => data);

  const treatments = useTreatments(
    flagNames,
    {
      firstName,
      lastName,
      fullName: [firstName, lastName].join(' '),
      email,
    },
    userId,
  );

  return Object.entries(treatments).reduce(
    (flags, [treatmentName, { treatment: value }]) => ({
      ...flags,
      [treatmentName]: value,
    }),
    {},
  );
};
