import {
  CATCH_USER_ERROR,
  CLEAR_USER,
  SET_USER,
  START_USER_NETWORK_REQ,
  LOGGING_OUT,
  UserActionTypes,
} from '../constants';
import { UserPermission } from '../../constants/userPermissions';
import { UserRole } from '../../constants/userRoles';

type Role = {
  role: UserRole;
  owner: 0 | 1;
  updatedAt: string;
};

export type Permission = {
  permission: UserPermission;
  updatedAt: string;
};

export type UserData = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  test: 0 | 1;
  ghost: 0 | 1;
  roles: Role[];
  permissions: Permission[];
  createdAt: string;
  csrf: string;
  loginRole: UserRole;
  isProfileGorilla: number;
  externalId: string | null;
};

export type UserState = {
  data: UserData | Record<string, never>;
  errors?: string[];
  finishedLoadAt?: string;
  isLoading: boolean;
  startedLoadAt?: string;
  isLoggingOut: boolean;
};

const defaultState: UserState = {
  data: {},
  errors: undefined,
  finishedLoadAt: undefined,
  isLoading: false,
  startedLoadAt: undefined,
  isLoggingOut: false,
};

const userReducer = (
  state: UserState = defaultState,
  action: UserActionTypes,
): UserState => {
  switch (action.type) {
    case CATCH_USER_ERROR:
      return {
        ...state,
        errors: [
          ...(Array.isArray(state.errors) ? state.errors : []),
          action.error instanceof Error ? action.error.message : action.error,
        ],
        finishedLoadAt: new Date().toISOString(),
        isLoading: false,
      };
    case CLEAR_USER:
      return defaultState;
    case SET_USER:
      return {
        ...state,
        data: action.user,
        finishedLoadAt: new Date().toISOString(),
        isLoading: false,
        isLoggingOut: false,
      };
    case START_USER_NETWORK_REQ:
      return {
        ...state,
        isLoading: true,
        startedLoadAt: new Date().toISOString(),
      };
    case LOGGING_OUT:
      return {
        ...state,
        isLoggingOut: true,
      };
    default:
      return state;
  }
};

export default userReducer;
