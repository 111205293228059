import classnames from 'classnames';
import React from 'react';
import Text from './Text';
import { COLOR, TRANSITION, SPACING } from './theme';

type Option = {
  label: string;
  value: string;
};

type Props = {
  className?: string;
  options: Option[];
  onChange?: (value: string) => any | Promise<any>;
  value: string;
  size?: 'medium' | 'small';
  variant?: 'primary' | 'secondary';
  width?: string;
  style?: React.CSSProperties | undefined;
};

const TextToggle: React.FC<Props> = ({
  className,
  onChange,
  options = [],
  value,
  size = 'medium',
  variant = 'primary',
  width = '100%',
  style,
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={classnames('text-toggle', className)} style={style}>
      {options.map((option) => {
        const isSelected = option.value === currentValue;

        return (
          <button
            className={classnames(variant, { selected: isSelected })}
            disabled={isSelected}
            key={option.value}
            onClick={(event) => {
              event.preventDefault();
              if (typeof onChange === 'function') {
                onChange(option.value);
              } else {
                setCurrentValue(option.value);
              }
            }}
            type="button"
          >
            <Text
              color={
                variant === 'secondary' && isSelected ? 'selected' : 'default'
              }
              element="span"
              onDark={isSelected && variant === 'primary'}
              size={size}
              weight={isSelected ? 'bold' : 'regular'}
            >
              {option.label}
            </Text>
          </button>
        );
      })}
      <style jsx>
        {`
          .text-toggle {
            border: 1px solid ${COLOR.lightGray};
            border-radius: 2px;
            display: flex;
            flex-direction: row;
            min-height: ${size === 'medium' ? '40px' : '32px'};
            margin: 0;
            overflow: hidden;
            padding: 0;
            width: ${width};
          }

          button {
            align-items: center;
            background-color: ${COLOR.white};
            border: none;
            cursor: pointer;
            display: flex;
            flex: 1 1 0;
            justify-content: center;
            margin: 0;
            min-height: ${size === 'medium' ? '40px' : '32px'};
            overflow: hidden;
            padding: ${size === 'medium'
              ? `${SPACING.xs}px ${SPACING.sm}px`
              : `${SPACING.xxs}px ${SPACING.xs}px`};
            text-align: center;
            transition: background-color ${TRANSITION.duration}
                ${TRANSITION.timingFunction},
              color ${TRANSITION.duration} ${TRANSITION.timingFunction};
          }

          button:disabled {
            pointer-events: none;
          }

          button:not(:first-child) {
            border-left: 1px solid ${COLOR.lightGray};
          }

          button:not(.selected):hover,
          button:not(.selected):focus {
            background-color: ${COLOR.softGray};
          }

          .selected {
            border-left: none;
            border-right: none;
          }

          .selected.primary {
            background-color: ${COLOR.blue};
          }

          .selected.secondary {
            background-color: ${COLOR.offWhite};
          }
        `}
      </style>
    </div>
  );
};

TextToggle.defaultProps = {
  className: undefined,
  onChange: undefined,
  size: 'medium',
};

export default TextToggle;
