import {
  CLEAR_MY_TASKS_FILTERS,
  MyTasksFiltersActionTypes,
  UPDATE_MY_TASKS_FILTERS,
} from '../constants/myTasksFiltersTypes';

export type MyTasksFiltersState = {
  taskStatus: string[] | null;
  taskType: string[] | null;
  claim: {
    id: string | null;
    name: string | null;
  };
  consolidatedJob: {
    value: string;
    label: string;
  };
  subAssignment: string | null;
  subAssignmentCode?: string;
  dueDateStart: string | null;
  dueDateEnd: string | null;
  sort: {
    orderBy: string;
    sortOrder: 'ASC' | 'DESC';
  };
};

export const initialState: MyTasksFiltersState = {
  taskStatus: null,
  taskType: null,
  claim: { id: null, name: null },
  consolidatedJob: { value: '', label: '' },
  subAssignment: null,
  dueDateEnd: null,
  dueDateStart: null,
  sort: {
    orderBy: 'dueDate',
    sortOrder: 'DESC',
  },
} as const;

// Keys for filtering
export const FILTER_KEYS = [
  'taskStatus',
  'taskType',
  'claimNumber',
  'dueDateStart',
  'dueDateEnd',
] as const;

const myTasksFilterReducer = (
  state: MyTasksFiltersState = initialState,
  action: MyTasksFiltersActionTypes,
): MyTasksFiltersState => {
  switch (action.type) {
    case CLEAR_MY_TASKS_FILTERS:
      return {
        ...state,
        taskStatus: null,
        taskType: null,
        claim: { id: null, name: null },
        consolidatedJob: { value: '', label: '' },
        subAssignment: null,
        dueDateEnd: null,
        dueDateStart: null,
      };
    case UPDATE_MY_TASKS_FILTERS:
      return {
        ...state,
        ...action.updated,
      };
    default:
      return state;
  }
};

export default myTasksFilterReducer;
