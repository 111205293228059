import classnames from 'classnames';
import React from 'react';

import Icon from './Icon';
import { COLOR, SPACING, TRANSITION } from './theme';

type Props = {
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
  onChange?: (isChecked: boolean) => any | Promise<any>;
};

const Toggle: React.FC<Props> = ({
  checked,
  className,
  disabled,
  id,
  onChange,
}) => {
  const [isChecked, setIsChecked] = React.useState(!!checked);

  React.useEffect(() => {
    setIsChecked(!!checked);
  }, [checked]);

  return (
    <button
      aria-checked={isChecked}
      aria-label="Toggle"
      className={classnames(className, { checked: isChecked })}
      data-action="aria-switch"
      disabled={disabled}
      id={id}
      onClick={(event) => {
        event.preventDefault();
        if (typeof onChange === 'function') {
          onChange(!isChecked);
        } else {
          setIsChecked((prev) => !prev);
        }
      }}
      role="switch"
      type="button"
    >
      <div className="yes">
        <Icon icon="done" />
      </div>
      <div className="no">
        <Icon icon="close" />
      </div>
      <style jsx>
        {`
          button {
            border-radius: ${SPACING.xxs}px 0 0 ${SPACING.xxs}px;
            border: none;
            display: flex;
            height: 32px;
            margin: 0;
            padding: 0;
            cursor: default !important;
          }

          .yes,
          .no {
            background-color: ${COLOR.white};
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 32px;
            justify-content: center;
            transition: background-color ${TRANSITION.duration}
                ${TRANSITION.timingFunction},
              border-color ${TRANSITION.duration} ${TRANSITION.timingFunction},
              color ${TRANSITION.duration} ${TRANSITION.timingFunction};
            width: 36px;
            padding: ${SPACING.xxs}px ${SPACING.xs}px;
            border: 1px solid ${COLOR.darkGray};
          }

          .yes {
            border-radius: ${SPACING.xxs}px 0 0 ${SPACING.xxs}px;
            border-right: none;
          }

          .no {
            border-radius: 0 ${SPACING.xxs}px ${SPACING.xxs}px 0;
            border-left: none;
          }

          /* checked */

          button.checked > .yes {
            color: ${COLOR.white};
          }

          button.checked > .no {
            background-color: transparent;
          }

          /* unchecked */

          button:not(.checked) > .no {
            color: ${COLOR.white};
          }

          button:not(.checked) > .yes {
            background-color: transparent;
          }

          /* checked & enabled */

          button.checked:not(:disabled) > .yes {
            background-color: ${COLOR.blue};
            border-color: ${COLOR.blue};
          }

          button.checked:not(:disabled) > .no {
            color: ${COLOR.blue};
          }

          /* checked & disabled */

          button.checked:disabled > .yes {
            background-color: ${COLOR.neutral30};
          }

          button.checked:disabled > .no {
            color: ${COLOR.neutral30};
          }

          /* unchecked & enabled */

          button:not(.checked):not(:disabled) > .no {
            background-color: ${COLOR.blue};
            border-color: ${COLOR.blue};
          }

          button:not(.checked):not(:disabled) > .yes {
            color: ${COLOR.blue};
          }

          /* unchecked & disabled */

          button:not(.checked):disabled > .no {
            background-color: ${COLOR.neutral30};
          }

          button:not(.checked):disabled > .yes {
            color: ${COLOR.neutral30};
          }

          /* Pointer events */

          button.checked > .yes,
          button:not(.checked) > .no,
          button:disabled > .yes,
          button:disabled > .no {
            pointer-events: none;
          }

          /* Hover states */

          button:not(.checked):not(:disabled) > .yes:hover,
          button:not(.checked):not(:disabled):focus > .yes {
            background-color: ${COLOR.white};
          }

          button.checked:not(:disabled) > .no:hover,
          button.checked:not(:disabled):focus > .no {
            background-color: ${COLOR.white};
          }
        `}
      </style>
    </button>
  );
};

Toggle.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  id: undefined,
  onChange: undefined,
};

export default Toggle;
