/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import produce from 'immer';
import { INPUT_VALUE, LOGGED_IN } from '../constants';

const initialState = {};

const formReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case INPUT_VALUE:
        if (draft[action.title]) {
          draft[action.title][action.name] = action.val;
        } else {
          draft[action.title] = { [action.name]: action.val };
        }
        break;

      case LOGGED_IN:
        return initialState;
    }
  });

export default formReducer;
