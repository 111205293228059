import { COLOR } from '../theme';

type Args = {
  disabled?: boolean;
  isFocused?: boolean;
  isInvalid?: boolean;
};

export default function getInputAccentColor({
  disabled = false,
  isFocused = false,
  isInvalid = false,
}: Args): string {
  if (disabled) return COLOR.neutral20;

  if (isInvalid) return COLOR.red;

  if (isFocused) return COLOR.blue;

  return COLOR.darkGray;
}
