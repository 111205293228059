export const CREATE_WORKORDER_ACTION_TYPE = Object.freeze({
  CREATE_WORKORDER_CATCH_ERROR: 'CREATE_WORKORDER_CATCH_ERROR',
  CREATE_WORKORDER_CLEAR_ERROR: 'CREATE_WORKORDER_CLEAR_ERROR',
  CREATE_WORKORDER_UPDATE_EDITS: 'CREATE_WORKORDER_UPDATE_EDITS',
  CREATE_WORKORDER_CLEAR_EDITS: 'CREATE_WORKORDER_CLEAR_EDITS',
  CREATE_WORKORDER_SET_IS_SUBMITTING: 'CREATE_WORKORDER_SET_IS_SUBMITTING',
  CREATE_WORKORDER_SET_TAB: 'CREATE_WORKORDER_SET_TAB',
  CREATE_WORKORDER_FETCH_DEFAULT_LIMIT: 'CREATE_WORKORDER_FETCH_DEFAULT_LIMIT',
  CREATE_WORKORDER_SET_DEFAULT_LIMIT: 'CREATE_WORKORDER_SET_DEFAULT_LIMIT',
});

export const CREATE_WORKORDER_MODAL_TAB = Object.freeze({
  customer_details: 1,
  job_details: 2,
  advanced: 3,
  review: 4,
});

export const CREATE_WORKORDER_ERROR_MESSAGE = Object.freeze({
  submit_fail: 'Failed to create workorder. Please try again.',
  unauthorized:
    'You are not authorized to create a work order on behalf of this customer',
});

export default CREATE_WORKORDER_ACTION_TYPE;
