import { CompaniesFiltersState } from '../reducers/companiesFiltersReducer';

export const UPDATE_COMPANIES_FILTERS = 'UPDATE_COMPANIES_FILTERS';
export const CLEAR_COMPANIES_FILTERS = 'CLEAR_COMPANIES_FILTERS';

interface ClearCompaniesFiltersAction {
  type: typeof CLEAR_COMPANIES_FILTERS;
}

interface UpdateCompaniesFiltersAction {
  type: typeof UPDATE_COMPANIES_FILTERS;
  updated: Partial<CompaniesFiltersState>;
}

export type CompaniesFiltersActionTypes =
  | ClearCompaniesFiltersAction
  | UpdateCompaniesFiltersAction;
