import { SWRConfiguration } from 'swr';
import { SWRError } from './swr/error';

const SWRConfig: SWRConfiguration = {
  async fetcher(resource: string, init?: RequestInit) {
    const res = await fetch(resource, init);

    if (res.status >= 400) {
      const errorData = await res.json();

      throw new SWRError(errorData, res.status);
    }

    return res.json();
  },
};

export default SWRConfig;
