const COMPANY_PAGE_DATA_TEST_IDS = {
  CompanyDetailsWrapper: 'company-details-wrapper',
  ActivateModal: {
    InvitePendingEmployeesDescription: 'invite-pending-employees-description',
    SetCompanyToActivateBtn: 'set-company-to-activate-btn',
  },
  OfflineModal: {
    SetCompanyOfflineBtn: 'set-company-offline-btn',
  },
  OnlineModal: {
    SetCompanyOnlineBtn: 'set-company-online-btn',
  },
  SuspendModal: {
    SetCompanySuspendBtn: 'set-company-suspend-btn',
  },
  DeactivateModal: {
    SetCompanyDeactivateBtn: 'set-company-deactivate-btn',
  },
  PendingModal: {
    SetCompanyToPendingBtn: 'set-company-to-pending-btn',
  },
  PointOfContactModal: {
    UpdatePointOfContactBtn: 'update-point-of-contact-btn',
  },
} as const;

const CLAIMS_PAGE_DATA_TEST_IDS = {
  AssigneeDropdown: 'claims-filter-assignee',
  InsuranceDropdown: 'claims-filter-insurance',
  ManualCompany: 'manual-company-option',
  ClaimLinkRow: 'claim-link-row',
} as const;

const CLAIM_DETAIL_PAGE_DATA_TEST_IDS = {
  AssociatedWorkorderOrJob: {
    container: 'associated-workorder-or-job-container',
    unlinkButton: 'associated-workorder-or-job-remove-button',
    unlinkModal: {
      confirmButton: 'unlink-modal-confirm-button',
      cancelButton: 'unlink-modal-cancel-button',
    },
  },
  AdjusterUserExistNote: 'adjuster-user-exist-note',
  SaveAdjusterInfoBtn: 'save-adjuster-info-btn',
};

const JOBS_PAGE_DATA_TEST_IDS = {
  JobsTableWrapper: 'container-jobs-table-wrapper',
  AssigneeDropdown: 'jobs-filter-assignee',
  ProviderDropdown: 'jobs-filter-provider',
  CompanyDropdown: 'jobs-filter-company',
} as const;

const JOB_DETAIL_PAGE_DATA_TEST_IDS = {
  Links: {
    ViewRepairJobButton: 'view-repair-job-button',
    ViewEstimateJobButton: 'view-estimate-job-button',
  },
  Pricing: {
    EditButton: 'pricing-edit-button',
    SaveEditsButton: 'pricing-save-edits-button',
    CancelEditsButton: 'pricing-cancel-edits-button',
    Dispatch: {
      ProTotalInput: 'dispatch-pro-total-input',
      CustomerTotalInput: 'dispatch-customer-total-input',
      ProTotalView: 'dispatch-pro-total-view',
      CustomerTotalView: 'dispatch-customer-total-view',
      /** Present when the dispatch fee has been paid */
      PaidIcon: 'dispatch-paid-icon',
    },
    Labor: {
      ProTotalInput: 'labor-pro-total-input',
      CustomerTotalInput: 'labor-customer-total-input',
      ProTotalView: 'labor-pro-total-view',
      CustomerTotalView: 'labor-customer-total-view',
      /** Present when labor has been paid */
      PaidIcon: 'labor-paid-icon',
    },
  },
  /** Right side container showing homee assignee and ability to edit */
  HomeeAssigneeContainer: 'homee-assignee-container',
  /** Right side panel on a job containing service, address, company, etc */
  SideDetailsContainer: 'side-details-container',
  StatusContainer: 'status-container',
  Actions: {
    ProOnSite: 'on-site-button',
    StartEstimateV2: 'start-estimate-v2-button',
    PauseJob: 'pause-job-button',
    ResumeJob: 'resume-job-button',
    SuspendJob: 'suspended-job-button',
    SuspendJobModal: {
      ReasonInput: 'suspend-job-reason-input',
      CancelButton: 'suspend-job-cancel-button',
      ConfirmButton: 'suspend-job-confirm-button',
    },
    CompleteJob: 'complete-job-button',
    SendReceiptToCustomer: 'send-receipt-button',
    AcceptReceipt: 'accept-receipt-button',
    DeclineReceipt: 'decline-receipt-button',
  },
  RequestNotes: {
    Container: 'request-notes-container',
    FileUploadInput: 'request-notes-file-upload',
    CancelEdit: 'request-notes-cancel-edit',
    SaveEdit: 'request-notes-save-edit',
  },
  ProviderNotes: {
    Container: 'provider-notes-container',
    NoteInput: 'provider-notes-note-input',
    ImageInput: 'provider-notes-image-input',
    AddNoteButton: 'provider-notes-add-note-button',
    AddAttachmentButton: 'provider-notes-add-attachment-button',
    CancelEditButton: 'provider-notes-cancel-edit',
    SaveEditButton: 'provider-notes-save-edit',
  },
} as const;

const ESTIMATE_V2_DATA_TEST_IDS = {
  StatusDescriptionText: 'estimate-status-description-text',
  /** Action buttons at the top of an estimate v2 page */
  EstimateActions: {
    EditDraftButton: 'edit-draft-button',
    EditDraftModal: {
      CancelButton: 'edit-draft-cancel-button',
      ConfirmButton: 'edit-draft-save-button',
    },
    SubmitEstimateButton: 'submit-estimate-button',
    SubmitEstimateModal: {
      CancelButton: 'submit-estimate-cancel-button',
      ConfirmButton: 'submit-estimate-save-button',
    },
    InternalApproveButton: 'internal-approve-button',
    InternalApproveModal: {
      ModalDispatchFeeText: 'internal-approve-modal-text',
      CancelButton: 'internal-approve-cancel-button',
      ConfirmButton: 'internal-approve-save-button',
    },
    InternalDeclineButton: 'internal-decline-button',
    InternalDeclineModal: {
      CancelButton: 'internal-decline-cancel-button',
      ConfirmButton: 'internal-decline-save-button',
    },
    ExternalRejectButton: 'external-reject-button',
    ExternalRejectModal: {
      CancelButton: 'external-reject-cancel-button',
      ConfirmButton: 'external-reject-save-button',
    },
    ExternalApproveButton: 'external-approve-button',
    ExternalApproveModal: {
      CancelButton: 'external-approve-cancel-button',
      ConfirmButton: 'external-approve-save-button',
    },
  },
  UploadEstimateRadio: 'automated-estimate',
  ManualEstimateRadio: 'manual-estimate',
  PricingEstimateByDropdown: 'pricing-estimate-by',
  EstimateTotal: {
    ForProInput: 'estimate-total-for-pro-input',
    ForBillingInput: 'estimate-total-for-billing-input',
    ForProView: 'estimate-total-for-pro-view',
    ForBillingView: 'estimate-total-for-billing-view',
  },
  RoomForm: {
    AddButton: 'add-room-button',
    NameInput: 'room-name-input',
    PhotosInput: 'room-photos-input',
    NotesInput: 'room-notes-input',
    CancelButton: 'cancel-add-room-button',
    SaveButton: 'save-add-room-button',
  },
  LineItemForm: {
    AddButton: 'form-add-line-item-button',
    NameDropdown: 'form-line-item-name',
    ActionDropdown: 'form-line-item-action',
    TypeDropdown: 'form-line-item-type',
    GradeDropdown: 'form-line-item-grade',
    QuantityInput: 'form-line-item-quantity',
    MeasurementUnitDropdown: 'form-line-item-measurement-unit',
    NotesInput: 'form-line-item-notes',
    CancelButton: 'form-cancel-add-line-item-button',
    SaveButton: 'form-save-add-line-item-button',
  },
  EditingButtons: {
    Cancel: 'editing-cancel-edits-button',
    SaveDraft: 'editing-save-draft-button',
    SubmitEstimate: 'editing-submit-estimate-button',
  },
  RoomView: {
    ExpandButton: 'view-expand-room-button',
    CollapseButton: 'view-collapse-room-button',
    Name: 'view-room-name',
    Notes: 'view-room-notes',
    PhotosContainer: 'view-room-photos',
    Cost: 'view-room-cost',
  },
  LineItemView: {
    ExpandButton: 'expand-line-item-button',
    CollapseButton: 'collapse-line-item-button',
    Name: 'line-item-name',
    Action: 'line-item-action',
    Type: 'line-item-type',
    Grade: 'line-item-grade',
    QuantityAndUnit: 'line-item-quantity',
    Notes: 'line-item-notes',
  },
  AttachmentsView: {
    ExpandButton: 'expand-attachments-button',
    CollapseButton: 'collapse-attachments-button',
  },
} as const;

const WORKORDER_PAGE_DATA_TEST_IDS = {
  CompanyNameText: 'company-name-text',
  EditWorkorderCompanyNameText: 'edit-workorder-company-name-text',
  SendWorkorderButton: 'send-workorder-button',
  AssignProButton: 'assign-pro-button',
  CancelWorkorderButton: 'cancel-workorder-button',
  ConfirmCancelWorkorderButton: 'confirm-cancel-workorder-button',
  ReferenceNumberInput: 'reference-number-input',
} as const;

const DROPDOWN_DATA_TEST_IDS = {
  HomeeAssigneeWrapper: 'homee-assign',
  Organization: 'organization',
  ResponsiblyParty: 'consumer-select',
  ServiceType: 'serviceType',
  Reason: 'reason',
} as const;

const TOAST_DATA_TEST_IDS = {
  ToastContent: 'toast-content',
  ToastModalClose: 'toast-modal-close',
} as const;

const ASSIGN_PRO_MODAL_DATA_TEST_IDS = {
  AssignProInput: 'assign-pro-input',
  AssignProConfirmButton: 'assign-pro-confirm-btn',
  AssignProCancelButton: 'assign-pro-cancel-btn',
} as const;

const STATUS_ICON_DATA_TEST_IDS = {
  StatusIcon: 'status-icon-avatar',
} as const;

const BUTTON_DATA_TEST_IDS = {
  CreateSubAssignment: 'create-sub-assignment',
} as const;

export {
  COMPANY_PAGE_DATA_TEST_IDS,
  CLAIMS_PAGE_DATA_TEST_IDS,
  JOB_DETAIL_PAGE_DATA_TEST_IDS,
  JOBS_PAGE_DATA_TEST_IDS,
  WORKORDER_PAGE_DATA_TEST_IDS,
  ESTIMATE_V2_DATA_TEST_IDS,
  TOAST_DATA_TEST_IDS,
  DROPDOWN_DATA_TEST_IDS,
  STATUS_ICON_DATA_TEST_IDS,
  ASSIGN_PRO_MODAL_DATA_TEST_IDS,
  CLAIM_DETAIL_PAGE_DATA_TEST_IDS,
  BUTTON_DATA_TEST_IDS,
};
