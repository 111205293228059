import builder, { Sort } from 'elastic-builder';
import { SEARCH_SORT_DIRECTION } from '../../constants/search';
import { SearchFilters } from './common';

export const generateCouponSearchBarSearch = (
  searchTerm: string,
): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder.boolQuery().should([
        builder.wildcardQuery('code', `*${searchTerm}*`),
        builder.wildcardQuery('code.joined', `*${searchTerm}*`),
        builder
          .functionScoreQuery()
          .functions([
            builder
              .decayScoreFunction('exp', 'created_at')
              .origin('now')
              .scale('365d')
              .offset('2d')
              .decay(0.9),
          ])
          .query(
            builder
              .boolQuery()
              .should([builder.matchQuery('description', searchTerm)]),
          ),
      ]),
    )
    .minScore(0.01);

  return searchBody;
};

export const generateCouponSearchPage = ({
  searchTerm,
  sortBy,
  sortDirection,
}: SearchFilters): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder.boolQuery().should([
        builder.wildcardQuery('code', `*${searchTerm}*`),
        builder.wildcardQuery('code.joined', `*${searchTerm}*`),
        builder
          .functionScoreQuery()
          .functions([
            builder
              .decayScoreFunction('exp', 'created_at')
              .origin('now')
              .scale('365d')
              .offset('2d')
              .decay(0.9),
          ])
          .query(
            builder
              .boolQuery()
              .should([builder.matchQuery('description', searchTerm)]),
          ),
      ]),
    )
    .minScore(0.01);

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection || SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};
