import moment from 'moment';
import { createStore, applyMiddleware, Store, Dispatch } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import Cookies from 'universal-cookie';
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  NextPageContext,
} from 'next';

import { IronSession } from 'iron-session';
import { excludeFields } from '@homee/util-common';
import reducer from './reducers';
import { FiltersState } from './reducers/filtersReducer';
import {
  ClaimsFiltersActionTypes,
  FiltersActionTypes,
  JobFiltersActionTypes,
  RateLookupActionTypes,
  ToastsActionTypes,
  UserActionTypes,
  WorkbenchActionTypes,
  CompaniesFiltersActionTypes,
} from './constants';
import { JobFiltersState } from './reducers/jobFiltersReducer';
import { RateLookupState } from './reducers/rateLookupReducer';
import { ToastsState } from './reducers/toastsReducer';
import { UserData, UserState } from './reducers/userReducer';
import { WorkbenchState } from './reducers/workbenchReducer';
import { ClaimsFiltersState } from './reducers/claimsFiltersReducer';
import { MyTasksFiltersState } from './reducers/myTasksFiltersReducer';
import { CompanyTasksFiltersState } from './reducers/companyTasksFiltersReducer';
import { CompanyTasksFiltersActionTypes } from './constants/companyTasksFiltersTypes';
import { MyTasksFiltersActionTypes } from './constants/myTasksFiltersTypes';
import { CompaniesFiltersState } from './reducers/companiesFiltersReducer';
import { initialCreateWorkorderState } from './reducers/createWorkorderReducer';

import { JobState } from './reducers/jobReducer';

const PERSISTENT_STATE_STORAGE_KEY = 'state';
const PERSISTENT_STATE_SCHEMA_VERSION = 2;
const PERSISTENT_STATE_COOKIE_NAME = 'state';
const PERSISTENT_STATE_COOKIE_OPTS = {
  expires: moment().add(1, 'year').toDate(),
  path: '/',
  secure: process.env.NODE_ENV === 'production',
};

const isBrowser = typeof window !== 'undefined';

export type RootState = {
  activeMarketLabels: any;
  claimsFilters: ClaimsFiltersState;
  companiesFilters: CompaniesFiltersState;
  companyTasksFilters: CompanyTasksFiltersState;
  consumerReq: any;
  createWorkorder: typeof initialCreateWorkorderState;
  editRequest: any;
  filters: FiltersState;
  formReducer: any;
  job: JobState;
  jobFilters: JobFiltersState;
  jobsPage: any;
  requestsPage: any;
  providerFilters: any;
  rateLookup: RateLookupState;
  regions: any;
  search: any;
  organization: any;
  team_members: any;
  myTasksFilters: MyTasksFiltersState;
  billing_records: any;
  toasts: ToastsState;
  user: UserState;
  workbench: WorkbenchState;
  workorder: any;
};

type ActionTypes =
  | ClaimsFiltersActionTypes
  | CompaniesFiltersActionTypes
  | CompanyTasksFiltersActionTypes
  | FiltersActionTypes
  | JobFiltersActionTypes
  | RateLookupActionTypes
  | ToastsActionTypes
  | UserActionTypes
  | MyTasksFiltersActionTypes
  | WorkbenchActionTypes;

export type RootDispatch = Dispatch<ActionTypes>;

export type ReduxContextGetServerSideProps<
  C extends Context = Context,
  P extends { [key: string]: unknown } = { [key: string]: unknown },
> = (context: C) => Promise<GetServerSidePropsResult<P>>;

export type Context = {
  reduxStore: Store<RootState, ActionTypes>;
} & NextPageContext &
  GetServerSidePropsContext;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  ActionTypes
>;

type StoragePersistentState = Pick<
  RootState,
  | 'search'
  | 'companyTasksFilters'
  | 'myTasksFilters'
  | 'companiesFilters'
  | 'claimsFilters'
  | 'providerFilters'
> & { _v: number };

type CookiesPersistentState = Pick<RootState, 'user'> & { _v: number };

function setStoragePersistentState(state: RootState): void {
  if (!isBrowser) {
    return;
  }

  const persistentStateWithVersion: StoragePersistentState = {
    search: state.search,
    companyTasksFilters: state.companyTasksFilters,
    myTasksFilters: state.myTasksFilters,
    companiesFilters: state.companiesFilters,
    claimsFilters: state.claimsFilters,
    providerFilters: state.providerFilters,
    _v: PERSISTENT_STATE_SCHEMA_VERSION,
  };
  localStorage.setItem(
    PERSISTENT_STATE_STORAGE_KEY,
    JSON.stringify(persistentStateWithVersion),
  );
}

function getStoragePersistentState(): Partial<
  Omit<StoragePersistentState, '_v'>
> {
  if (!isBrowser) {
    return {};
  }

  const json = localStorage.getItem(PERSISTENT_STATE_STORAGE_KEY);
  if (!json) {
    return {};
  }

  const persistentState: Partial<StoragePersistentState> = JSON.parse(json);
  if (persistentState._v !== PERSISTENT_STATE_SCHEMA_VERSION) {
    localStorage.removeItem(PERSISTENT_STATE_STORAGE_KEY);
    return {};
  }

  delete persistentState._v;
  return persistentState;
}

function setCookiesPersistentState(state: RootState): void {
  const user = state?.user;

  // If this somehow gets in here, let's make sure it gets stripped out
  if (user?.data && ('token' in user.data || 'apiKey' in user.data)) {
    user.data = excludeFields(user.data, ['token', 'apiKey']);
  }

  const persistentState: CookiesPersistentState = {
    user,
    _v: PERSISTENT_STATE_SCHEMA_VERSION,
  };
  new Cookies().set(
    PERSISTENT_STATE_COOKIE_NAME,
    persistentState,
    PERSISTENT_STATE_COOKIE_OPTS,
  );
}

function getCookiesPersistentState(
  context: Context,
  session?: IronSession,
): Partial<Omit<CookiesPersistentState, '_v'>> {
  let persistentState: Partial<CookiesPersistentState> = {};

  if (session?.user) {
    // In an SSR context, when we have the full decoded user
    // The redux state doesn't need apiKey or token so let's omit those
    const user: UserData = excludeFields(session.user, ['apiKey', 'token']);

    persistentState.user = {
      data: user,
      isLoading: false,
      isLoggingOut: false,
    };
  }

  if (isBrowser) {
    // Parse document.cookie to restore coookie state
    const { state: cookieState } =
      new RegExp(`${PERSISTENT_STATE_COOKIE_NAME}=(?<state>[^;]+)`).exec(
        document.cookie,
      )?.groups ?? {};

    if (cookieState) {
      const value = JSON.parse(decodeURIComponent(cookieState));
      if (value._v === PERSISTENT_STATE_SCHEMA_VERSION) {
        persistentState = {
          ...value,
          ...persistentState,
        };
      } else {
        // Cookie was made with an old schema version, remove it
        document.cookie = `${PERSISTENT_STATE_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
  }

  const header = context?.req?.headers?.cookie;
  if (header) {
    const cookies = new Cookies(header);
    const value = cookies.get(PERSISTENT_STATE_COOKIE_NAME);
    if (value) {
      if (value._v === PERSISTENT_STATE_SCHEMA_VERSION) {
        persistentState = {
          ...value,
          ...persistentState,
        };
      } else {
        cookies.remove(PERSISTENT_STATE_COOKIE_NAME);
      }
    }
  }

  delete persistentState._v;
  return persistentState;
}

export const initStore = (
  context: Context,
  initialServerState?: RootState,
  session?: IronSession,
): Store<RootState, ActionTypes> => {
  const cookiesPersistentState = getCookiesPersistentState(context, session);
  const localStoragePersistentState = getStoragePersistentState();

  const store = createStore(
    reducer,
    {
      ...cookiesPersistentState,
      ...initialServerState,
      // local storage should override server state, because only server state available should be from user
      ...localStoragePersistentState,
      user: {
        isLoading: false,
        isLoggingOut: false,
        ...(initialServerState?.user ?? {}),
        ...(cookiesPersistentState.user ?? {}),
        data: {
          ...(cookiesPersistentState.user?.data ?? {}),
          ...(initialServerState?.user.data ?? {}),
        },
      },
    },
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
  );

  store.subscribe(() => {
    const state = store.getState();
    setStoragePersistentState(state);
    setCookiesPersistentState(state);
  });

  return store;
};

export default initStore;
