import moment from 'moment';

import { ALL_MARKETS } from '../../constants/markets';
import {
  CLEAR_FILTERS,
  FiltersActionTypes,
  RESET_SUB_BUCKETS,
  UPDATE_FILTERS,
} from '../constants';
import {
  SortOrder,
  SubBucket,
  WorkbenchBucket,
} from '../../__generated__/graphql';

const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');

export type FiltersState = {
  bucket: WorkbenchBucket;
  dateEnd?: string;
  dateStart: string;
  defaultPicker: string;
  laborCostMax?: number;
  laborCostMin?: number;
  marketId: string;
  marketName: string;
  materialCostMax?: number;
  materialCostMin?: number;
  networkTypes?: number[];
  origin?: string;
  organizationIds?: string[];
  regionId: string | null;
  regionName: string | null;
  serviceTypes?: number[];
  sortOrder: SortOrder;
  subBuckets?: SubBucket[];
  totalCostMax?: number;
  totalCostMin?: number;
  updated: string;
};

export const initialState: FiltersState = {
  bucket: WorkbenchBucket.Active,
  dateEnd: undefined,
  dateStart: startOfWeek,
  defaultPicker: 'currentWeek',
  laborCostMax: undefined,
  laborCostMin: undefined,
  marketId: ALL_MARKETS.id,
  marketName: ALL_MARKETS.name,
  materialCostMax: undefined,
  materialCostMin: undefined,
  networkTypes: undefined,
  origin: undefined,
  organizationIds: undefined,
  regionId: null,
  regionName: null,
  serviceTypes: undefined,
  sortOrder: SortOrder.Descending,
  subBuckets: undefined,
  totalCostMax: undefined,
  totalCostMin: undefined,
  updated: new Date().toISOString(),
};

export const FILTER_KEYS = Object.keys(initialState);

const filtersReducer = (
  state: FiltersState = initialState,
  action: FiltersActionTypes,
): FiltersState => {
  switch (action.type) {
    case CLEAR_FILTERS:
      return {
        ...initialState,
        bucket: state.bucket,
        marketId: state.marketId,
        marketName: state.marketName,
        regionId: state.regionId,
        regionName: state.regionName,
        updated: new Date().toISOString(),
      };
    case RESET_SUB_BUCKETS:
      return {
        ...state,
        subBuckets: undefined,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        ...action.updated,
        updated: new Date().toISOString(),
      };
    default:
      return state;
  }
};

export default filtersReducer;
