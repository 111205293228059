import { defaultsDeep, valuesIn } from 'lodash';
import { ALL_MARKETS } from '../../../constants/markets';

import { SEARCH_DEFAULT_OPTION } from '../../../constants/search';
import createQueryBuilder from '../createQueryBuilder';

export const ORGANIZATION_SEARCH_FIELD = Object.freeze({
  id: 'id',
  name: 'name',
});

export const ALL_ORGANIZATION_SEARCH_FIELDS = Object.freeze(
  valuesIn(ORGANIZATION_SEARCH_FIELD),
);

export const buildOrganizationsQuery = (query, option) => {
  const {
    fields = ALL_ORGANIZATION_SEARCH_FIELDS,
    filter: { marketId, marketCountRange, regionId },
  } = defaultsDeep(option, SEARCH_DEFAULT_OPTION);

  const builder = createQueryBuilder(option);

  if (query && fields.length) {
    builder.query('multi_match', {
      query,
      fields: ['id', 'name'],
    });
  }

  // Market filter.
  if (marketId != null && marketId !== ALL_MARKETS.id) {
    builder.andFilter('term', 'market_id', marketId);
  }

  // Market count range filter.
  if (marketCountRange && (marketCountRange.gte || marketCountRange.lte)) {
    builder.andFilter('range', 'market_count', marketCountRange);
  }

  // Region filter.
  if (regionId != null) {
    builder.andFilter('term', 'region_id', regionId);
  }

  return builder.build();
};

export default buildOrganizationsQuery;
