/**
 * request specific organization info: eg. org name
 */
import axios from 'axios';

export const actionTypes = {
  CATCH_ORGANIZATION_ERROR: 'CATCH_ORGANIZATION_ERROR',
  RECEIVE_ORGANIZATION: 'RECEIVE_ORGANIZATION',
  CLEAR_ORGANIZATION: 'CLEAR_ORGANIZATION',
  REQUEST_ORGANIZATION: 'REQUEST_ORGANIZATION',
};

export const catchOrganizationError = ({ error, id }) => ({
  type: actionTypes.CATCH_ORGANIZATION_ERROR,
  error,
  id,
});

export const receiveOrganization = (organization) => ({
  type: actionTypes.RECEIVE_ORGANIZATION,
  organization,
});

export const clearOrganization = (id) => ({
  type: actionTypes.CLEAR_ORGANIZATION,
  id,
});

export const requestOrganization = (id) => ({
  type: actionTypes.REQUEST_ORGANIZATION,
  id,
});

export const fetchOrganization = (id) => async (dispatch) => {
  dispatch(requestOrganization(id));

  try {
    const orgData = await axios({
      method: 'GET',
      url: `/v2/organizations/${id}`,
    });
    const results = orgData.data.data;

    return dispatch(receiveOrganization(results));
  } catch (e) {
    return dispatch(catchOrganizationError(e));
  }
};

const shouldFetchOrganization = (state) => {
  try {
    return !state.organization.isLoading;
  } catch (e) {
    return true;
  }
};

export const fetchOrganizationIfNeeded = (id) => (dispatch, getState) => {
  if (shouldFetchOrganization(getState())) {
    return dispatch(fetchOrganization(id));
  }
  return undefined;
};
