export enum UserStatus {
  Deleted = -1,
  Active = 1,
  Pending = 2,
  Suspended = 3,
}

/**
 * @deprecated use UserStatus enum
 */
export const USER_STATUS = Object.freeze({
  deleted: -1,
  active: 1,
});

export const ALL_USER_STATUSES = Object.values(UserStatus);
