import css from 'styled-jsx/css';
import React from 'react';

import getInputAccentColor from './utils/getInputAccentColor';
import getInputTextColorName from './utils/getInputTextColorName';
import InputHelperText from './InputHelperText';
import Text from './Text';
import { COLOR, SPACING } from './theme';

const { className: labelSpanClassName, styles: labelSpanStyles } = css.resolve`
  span {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 1px 0;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

type Props = {
  id?: string;
  name?: string;
  className?: string;
  helperText?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  label?: string;
  onChange:
    | ((event: React.ChangeEvent<HTMLTextAreaElement>) => void)
    | undefined;
  required?: boolean;
  value: string | undefined;
  onBlur?: ((event: React.FocusEvent<HTMLTextAreaElement>) => void) | undefined;
  dataTestID?: string;
};

const TextArea: React.FC<Props> = ({
  id,
  name,
  className,
  helperText,
  isInvalid,
  label,
  onChange,
  required,
  value,
  onBlur,
  dataTestID,
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const labelColor = getInputTextColorName({ isFocused, isInvalid });
  const accentColor = getInputAccentColor({ isFocused, isInvalid });

  return (
    <div className={className}>
      <label>
        {label && (
          <Text
            className={labelSpanClassName}
            color={labelColor}
            element="span"
            size="medium"
            weight="bold"
          >
            {required && '* '}
            {label}
          </Text>
        )}
        <textarea
          data-testid={dataTestID}
          id={id}
          name={name}
          rows={3}
          disabled={disabled}
          onChange={onChange}
          value={value}
          onBlur={(event) => {
            setIsFocused(false);
            if (onBlur) {
              onBlur(event);
            }
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          required={required}
          aria-required={required ? 'true' : 'false'}
        />
        <style jsx>
          {`
            label {
              display: block;
              margin: 0;
              padding: 0;
            }

            textarea {
              padding: ${SPACING.xs}px ${SPACING.xs}px ${SPACING.sm}px;
              background-color: ${disabled ? '#fbfcfc' : COLOR.white};
              border: none;
              border-bottom: 2px solid ${accentColor};
              border-radius: 0;
              color: ${disabled ? COLOR.neutral30 : COLOR.black};
              display: block;
              font-size: 1rem;
              margin: 0;
              height: auto;
              width: 100%;
            }

            textarea::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+  */
              color: ${COLOR.darkGray};
              opacity: 1;
            }
            textarea:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: ${COLOR.darkGray};
            }
            textarea::-ms-input-placeholder {
              /* Microsoft Edge */
              color: ${COLOR.darkGray};
            }

            textarea:active,
            textarea:focus {
              outline: none;
            }
          `}
        </style>
        {labelSpanStyles}
      </label>
      {helperText && (
        <InputHelperText isInvalid={isInvalid}>{helperText}</InputHelperText>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  isInvalid: false,
  required: false,
};

export default TextArea;
