import { ConsumerRequestStatus } from '@internal-tools/utils';
import builder, { Sort } from 'elastic-builder';
import { SEARCH_SORT_DIRECTION } from '../../constants/search';
import { MustQuery, SearchFilters } from './common';

export const generateConsolidatedJobSearchBarSearch = (
  searchTerm: string,
): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder
        .boolQuery()
        .filter(
          builder.boolQuery().mustNot([
            builder.termsQuery('consumer_request_status', [350]), // not a valid status but is present in the db
            builder
              .boolQuery()
              .must(
                builder.termsQuery('consumer_request_status', [
                  ConsumerRequestStatus.AcceptedProviderRequest,
                ]),
              )
              .mustNot([builder.existsQuery('job_status')]),
          ]),
        )
        .should([
          builder.matchQuery('consumer_request_id', searchTerm),
          builder.matchQuery('consumer_request_code', searchTerm),
          builder.matchQuery('job_id', searchTerm),
          builder.matchQuery('job_code', searchTerm),
          builder.matchQuery('job_invoice_id', searchTerm),
          builder.matchQuery('job_invoice_external_id', searchTerm),
          builder.matchQuery('consumer_request_id', searchTerm),
          builder.matchQuery('workorder_id', searchTerm),
          builder.matchQuery('workorder_external_id', searchTerm),
          builder
            .functionScoreQuery()
            .functions([
              builder
                .decayScoreFunction('exp', 'consumer_request_created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
              builder
                .decayScoreFunction('exp', 'job_created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
              builder
                .decayScoreFunction('exp', 'workorder_created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
            ])
            .query(
              builder
                .boolQuery()
                .should([
                  builder.matchQuery('job_code', searchTerm).boost(2),
                  builder.matchQuery('workorder_id', searchTerm).boost(2),
                  builder.matchQuery('consumer_request_address', searchTerm),
                  builder.matchQuery('consumer_request_zipcode', searchTerm),
                  builder.matchQuery(
                    'consumer_request_consumer_first_name',
                    searchTerm,
                  ),
                  builder.matchQuery(
                    'consumer_request_consumer_last_name',
                    searchTerm,
                  ),
                  builder
                    .multiMatchQuery(
                      [
                        'consumer_request_consumer_first_name',
                        'consumer_request_consumer_last_name',
                      ],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder
                    .multiMatchQuery(
                      [
                        'consumer_request_consumer_first_name.joined',
                        'consumer_request_consumer_last_name.joined',
                      ],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder.matchQuery('job_address', searchTerm),
                  builder.matchQuery('job_zipcode', searchTerm),
                  builder.matchQuery('job_consumer_first_name', searchTerm),
                  builder.matchPhrasePrefixQuery(
                    'job_consumer_first_name',
                    searchTerm,
                  ),
                  builder.wildcardQuery(
                    'job_consumer_first_name',
                    `*${searchTerm}*`,
                  ),
                  builder.matchQuery('job_consumer_last_name', searchTerm),
                  builder
                    .multiMatchQuery(
                      ['job_consumer_first_name', 'job_consumer_last_name'],
                      searchTerm,
                    )
                    .type('cross_fields'),

                  builder.matchQuery('job_provider_first_name', searchTerm),
                  builder.matchQuery('job_provider_last_name', searchTerm),
                  builder
                    .multiMatchQuery(
                      ['job_provider_first_name', 'job_provider_last_name'],
                      searchTerm,
                    )
                    .type('cross_fields'),
                ]),
            ),
        ]),
    )
    .minScore(0.01);

  return searchBody;
};

export const generateConsolidatedJobSearchPageSearch = ({
  searchTerm,
  serviceTypes,
  jobStatuses,
  consumerRequestStatuses,
  rangeStart,
  rangeEnd,
  marketId,
  regionId,
  sortBy,
  sortDirection,
}: SearchFilters): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [];
  const mustNotQueries = [
    builder.termsQuery('consumer_request_status', [350]),
    builder
      .boolQuery()
      .must(
        builder.termsQuery('consumer_request_status', [
          ConsumerRequestStatus.AcceptedProviderRequest,
        ]),
      )
      .mustNot([builder.existsQuery('job_status')]),
  ];

  if (marketId) {
    mustQueries.push(builder.termQuery('market_id', marketId));
  }

  if (regionId) {
    mustQueries.push(builder.termQuery('region_id', regionId));
  }

  if (rangeStart) {
    mustQueries.push(
      builder.boolQuery().should([
        builder.rangeQuery('job_created_at').gte(rangeStart),
        builder
          .boolQuery()
          .must([
            builder.rangeQuery('consumer_request_created_at').gte(rangeStart),
          ])
          .mustNot([builder.existsQuery('job_created_at')]),
      ]),
    );
  }

  if (rangeEnd) {
    mustQueries.push(
      builder.boolQuery().should([
        builder.rangeQuery('job_created_at').lte(rangeEnd),
        builder
          .boolQuery()
          .must([
            builder.rangeQuery('consumer_request_created_at').lte(rangeEnd),
          ])
          .mustNot([builder.existsQuery('job_created_at')]),
      ]),
    );
  }

  if (serviceTypes?.length) {
    mustQueries.push(builder.termsQuery('job_service_type', serviceTypes));
    mustQueries.push(
      builder.termsQuery('consumer_request_service_type', serviceTypes),
    );
  }

  if (jobStatuses?.length) {
    mustQueries.push(builder.termsQuery('job_status', jobStatuses));
  }

  if (consumerRequestStatuses?.length) {
    mustQueries.push(
      builder.termsQuery('consumer_request_status', consumerRequestStatuses),
    );
  }

  const filterBoolQuery = builder
    .boolQuery()
    .must([...mustQueries, builder.boolQuery().mustNot(mustNotQueries)]);

  const searchBody = builder
    .requestBodySearch()
    .query(
      builder
        .boolQuery()
        .filter(filterBoolQuery)
        .should([
          builder.matchQuery('consumer_request_id', searchTerm),
          builder.matchQuery('consumer_request_code', searchTerm),
          builder.matchQuery('job_id', searchTerm),
          builder.matchQuery('job_code', searchTerm),
          builder.matchQuery('job_invoice_id', searchTerm),
          builder.matchQuery('job_invoice_external_id', searchTerm),
          builder.matchQuery('consumer_request_id', searchTerm),
          builder.matchQuery('workorder_id', searchTerm),
          builder.matchQuery('workorder_external_id', searchTerm),
          builder
            .functionScoreQuery()
            .functions([
              builder
                .decayScoreFunction('exp', 'consumer_request_created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
              builder
                .decayScoreFunction('exp', 'job_created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
              builder
                .decayScoreFunction('exp', 'workorder_created_at')
                .origin('now')
                .scale('365d')
                .offset('2d')
                .decay(0.9),
            ])
            .query(
              builder
                .boolQuery()
                .should([
                  builder.matchQuery('consumer_request_address', searchTerm),
                  builder.matchQuery('consumer_request_zipcode', searchTerm),
                  builder.matchQuery(
                    'consumer_request_consumer_first_name',
                    searchTerm,
                  ),
                  builder.matchQuery(
                    'consumer_request_consumer_last_name',
                    searchTerm,
                  ),
                  builder
                    .multiMatchQuery(
                      [
                        'consumer_request_consumer_first_name',
                        'consumer_request_consumer_last_name',
                      ],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder
                    .multiMatchQuery(
                      [
                        'consumer_request_consumer_first_name.joined',
                        'consumer_request_consumer_last_name.joined',
                      ],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder.matchQuery('job_address', searchTerm),
                  builder.matchQuery('job_zipcode', searchTerm),
                  builder.matchQuery('job_consumer_first_name', searchTerm),
                  builder.matchPhrasePrefixQuery(
                    'job_consumer_first_name',
                    searchTerm,
                  ),
                  builder.wildcardQuery(
                    'job_consumer_first_name',
                    `*${searchTerm}*`,
                  ),
                  builder.matchQuery('job_consumer_last_name', searchTerm),
                  builder
                    .multiMatchQuery(
                      ['job_consumer_first_name', 'job_consumer_last_name'],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder
                    .multiMatchQuery(
                      [
                        'job_consumer_first_name.joined',
                        'job_consumer_last_name.joined',
                      ],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder.matchQuery('job_provider_first_name', searchTerm),
                  builder.matchQuery('job_provider_last_name', searchTerm),
                  builder
                    .multiMatchQuery(
                      ['job_provider_first_name', 'job_provider_last_name'],
                      searchTerm,
                    )
                    .type('cross_fields'),
                  builder
                    .multiMatchQuery(
                      [
                        'job_provider_first_name.joined',
                        'job_provider_last_name.joined',
                      ],
                      searchTerm,
                    )
                    .type('cross_fields'),
                ]),
            ),
        ]),
    )
    .minScore(0.01);

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection ?? SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};
