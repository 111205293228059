export enum BackgroundStatus {
  Clear = 'clear',
  Consider = 'consider',
  Declined = 'declined',
  Dispute = 'dispute',
  Fail = 'fail',
  Expired = 'expired',
  Expiring = 'expiring',
  Missing = 'missing',
  Pending = 'pending',
  ConsiderPreAdverse = 'consider_pre_adverse',
  Suspended = 'suspended',
  Timeout = 'timeout',
}

/**
 * @deprecated use BackgroundStatus enum
 */
export const BACKGROUND_STATUS = {
  BACKGROUND_CLEAR: 'clear',
  BACKGROUND_CONSIDER: 'consider',
  BACKGROUND_DECLINED: 'declined',
  BACKGROUND_DISPUTE: 'dispute',
  BACKGROUND_FAIL: 'fail',
  BACKGROUND_EXPIRED: 'expired',
  BACKGROUND_EXPIRING: 'expiring',
  BACKGROUND_MISSING: 'missing',
  BACKGROUND_PENDING: 'pending',
  BACKGROUND_CONSIDER_PRE_ADVERSE: 'consider_pre_adverse',
  BACKGROUND_SUSPENDED: 'suspended',
  BACKGROUND_TIMEOUT: 'timeout',
};

export const ALL_BACKGROUND_STATUSES = Object.values(BackgroundStatus);

export default BACKGROUND_STATUS;
