import bodybuilder from 'bodybuilder/browser/bodybuilder.min';
import { defaultsDeep, valuesIn } from 'lodash';

import moment from 'moment';
import { ALL_MARKETS } from '../../../constants/markets';

import {
  DATE_PICKER_LIFETIME_DEFAULT_GTE,
  SEARCH_DEFAULT_OPTION,
  SEARCH_QUERY_DATE_FORMAT,
} from '../../../constants/search';
import createQueryBuilder from '../createQueryBuilder';
import { CONSUMER_REQUEST_STATUS } from '../../../constants/consumerRequestStatuses';

export const JOB_SEARCH_FIELD = Object.freeze({
  address: 'address',
  consumerFirstName: 'consumer_first_name',
  consumerLastName: 'consumer_last_name',
  consumerRequestId: 'consumer_request_id',
  jobId: 'job_id',
  jobCode: 'job_code',
  jobInvoiceId: 'job_invoice_id',
  jobInvoiceExternalId: 'job_invoice_external_id',
  providerFirstName: 'provider_first_name',
  providerLastName: 'provider_last_name',
  workorderExternalId: 'workorder_external_id',
  workorderId: 'workorder_id',
  zipcode: 'zipcode',
});

export const ALL_JOB_SEARCH_FIELDS = Object.freeze(valuesIn(JOB_SEARCH_FIELD));

const JOB_SEARCH_DECAY_PARAMS = Object.freeze({
  origin: 'now',
  offset: '2d',
  decay: 0.8,
  scale: '90d',
});

export const buildJobsQuery = (query, option) => {
  const {
    fields = ALL_JOB_SEARCH_FIELDS,
    filter: {
      marketId,
      serviceTypes,
      consumerRequestStatuses,
      dateRange,
      jobStatuses,
      regionId,
    },
    page,
    limit: size,
    sort,
  } = defaultsDeep(option, SEARCH_DEFAULT_OPTION);

  let builder = createQueryBuilder(option);

  // Frontend-only solution to sort "jobs" by date.
  if (
    sort.fields &&
    sort.fields.some((field) =>
      [
        'job_created_at',
        'workorder_created_at',
        'consumer_request_created_at',
      ].includes(field),
    )
  ) {
    builder = bodybuilder();

    // Pagination.
    const from = size * (page - 1);
    builder.from(from).size(size);

    // Sorting.
    // Script to use the job date if it exists, else use the consumer request date.
    builder.rawOption('sort', {
      _script: {
        type: 'number',
        script: {
          lang: 'painless',
          source: `
             if (doc["job_created_at"].size() != 0) {
               return doc["job_created_at"].value.getMillis();
             }
             if (doc["workorder_created_at"].size() != 0) {
               return doc["workorder_created_at"].value.getMillis();
             }
             return doc["consumer_request_created_at"].value.getMillis();
            `,
        },
        order: sort.direction,
      },
    });
  }

  if (query && fields.length) {
    // Define the minimum score.
    builder.rawOption('min_score', 0.01);

    // Dedicated queries for keywords to prevent them from being included in decay scoring.
    if (fields.includes(JOB_SEARCH_FIELD.jobId)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.jobId, query);
    }
    if (fields.includes(JOB_SEARCH_FIELD.jobCode)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.jobCode, query);
    }
    if (fields.includes(JOB_SEARCH_FIELD.jobInvoiceId)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.jobInvoiceId, query);
    }
    if (fields.includes(JOB_SEARCH_FIELD.jobInvoiceExternalId)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.jobInvoiceExternalId, query);
    }
    if (fields.includes(JOB_SEARCH_FIELD.consumerRequestId)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.consumerRequestId, query);
    }
    if (fields.includes(JOB_SEARCH_FIELD.workorderId)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.workorderId, query);
    }
    if (fields.includes(JOB_SEARCH_FIELD.workorderExternalId)) {
      builder.orQuery('match', JOB_SEARCH_FIELD.workorderExternalId, query);
    }

    builder.orQuery(
      'function_score',
      {
        functions: [
          { exp: { consumer_request_created_at: JOB_SEARCH_DECAY_PARAMS } },
          { exp: { job_created_at: JOB_SEARCH_DECAY_PARAMS } },
        ],
      },
      (agg) => {
        if (JOB_SEARCH_FIELD.address) {
          agg.orQuery('match', JOB_SEARCH_FIELD.address, query);
        }

        if (JOB_SEARCH_FIELD.zipcode) {
          agg.orQuery('match', JOB_SEARCH_FIELD.zipcode, query);
        }

        if (JOB_SEARCH_FIELD.consumerFirstName) {
          agg.orQuery('match', JOB_SEARCH_FIELD.consumerFirstName, query);
        }
        if (JOB_SEARCH_FIELD.consumerLastName) {
          agg.orQuery('match', JOB_SEARCH_FIELD.consumerLastName, query);

          if (JOB_SEARCH_FIELD.consumerFirstName) {
            agg.orQuery('multi_match', {
              query,
              fields: [
                JOB_SEARCH_FIELD.consumerFirstName,
                JOB_SEARCH_FIELD.consumerLastName,
              ],
              type: 'cross_fields',
            });
            agg.orQuery('multi_match', {
              query,
              fields: [
                `${JOB_SEARCH_FIELD.consumerFirstName}.joined`,
                `${JOB_SEARCH_FIELD.consumerLastName}.joined`,
              ],
              type: 'cross_fields',
            });
          }
        }

        if (JOB_SEARCH_FIELD.providerFirstName) {
          agg.orQuery('match', JOB_SEARCH_FIELD.providerFirstName, query);
        }
        if (JOB_SEARCH_FIELD.providerLastName) {
          agg.orQuery('match', JOB_SEARCH_FIELD.providerLastName, query);

          if (JOB_SEARCH_FIELD.providerFirstName) {
            agg.orQuery('multi_match', {
              query,
              fields: [
                JOB_SEARCH_FIELD.providerFirstName,
                JOB_SEARCH_FIELD.providerLastName,
              ],
              type: 'cross_fields',
            });
            agg.orQuery('multi_match', {
              query,
              fields: [
                `${JOB_SEARCH_FIELD.providerFirstName}.joined`,
                `${JOB_SEARCH_FIELD.providerLastName}.joined`,
              ],
              type: 'cross_fields',
            });
          }
        }

        return agg;
      },
    );
  }

  // Market filter.
  if (marketId != null && marketId !== ALL_MARKETS.id) {
    builder.andFilter('term', 'market_id', marketId);
  }

  // Region filter.
  if (regionId != null) {
    builder.andFilter('term', 'region_id', regionId);
  }

  // Service type filter.
  if (serviceTypes && serviceTypes.length) {
    builder.andFilter('terms', 'service_type', serviceTypes);
  }

  // Statuses filter.
  const hasConsumerRequestStatuses =
    consumerRequestStatuses && consumerRequestStatuses.length;
  const hasJobStatuses = jobStatuses && jobStatuses.length;
  if (hasConsumerRequestStatuses || hasJobStatuses) {
    builder.andFilter('bool', (statusBuilder) => {
      statusBuilder.notFilter('terms', 'consumer_request_status', [
        350, // Not a valid status for consumer requests, but it exists in the database.
      ]);
      if (hasJobStatuses) {
        statusBuilder.orFilter('terms', 'job_status', jobStatuses);
      }
      if (hasConsumerRequestStatuses) {
        statusBuilder.orFilter('bool', (consumerRequestStatusBuilder) =>
          consumerRequestStatusBuilder
            .andFilter(
              'terms',
              'consumer_request_status',
              consumerRequestStatuses,
            )
            .notFilter('exists', 'job_status'),
        );
      }
      return statusBuilder;
    });
  } else {
    builder.andFilter('bool', (statusBuilder) => {
      statusBuilder.notFilter('terms', 'consumer_request_status', [
        350, // Not a valid status for consumer requests, but it exists in the database.
      ]);
      statusBuilder.notFilter('bool', (consumerRequestStatusBuilder) =>
        consumerRequestStatusBuilder
          .andFilter('terms', 'consumer_request_status', [
            CONSUMER_REQUEST_STATUS.accepted_PR,
          ])
          .notFilter('exists', 'job_status'),
      );
      return statusBuilder;
    });
  }

  // Date range filter.
  if (dateRange) {
    const filterDateRange = {};
    if (dateRange.gte) {
      const filterDateRangeGte = moment(dateRange.gte);
      if (
        !filterDateRangeGte.isSameOrBefore(
          DATE_PICKER_LIFETIME_DEFAULT_GTE,
          'day',
        )
      ) {
        filterDateRange.gte = filterDateRangeGte.format(
          SEARCH_QUERY_DATE_FORMAT,
        );
      }
    }
    if (dateRange.lte) {
      const filterDateRangeLte = moment(dateRange.lte);
      if (!filterDateRangeLte.isSameOrAfter(moment(), 'day')) {
        filterDateRange.lte = filterDateRangeLte.format(
          SEARCH_QUERY_DATE_FORMAT,
        );
      }
    }
    if (filterDateRange.gte || filterDateRange.lte) {
      builder.andFilter('bool', (dateRangeBuilder) =>
        dateRangeBuilder
          .orFilter('range', 'job_created_at', filterDateRange)
          .orFilter('bool', (consumerRequestDateRangeBuilder) =>
            consumerRequestDateRangeBuilder
              .notFilter('exists', 'job_created_at')
              .andFilter(
                'range',
                'consumer_request_created_at',
                filterDateRange,
              ),
          ),
      );
    }
  }

  return builder.build();
};

export default buildJobsQuery;
