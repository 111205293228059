/* eslint-disable no-param-reassign */
import moment from 'moment';
import produce from 'immer';
import { actionTypes } from '../actions/billingRecordsActions';

const {
  CATCH_BILLING_RECORDS_ERROR,
  RECEIVE_BILLING_RECORDS,
  REQUEST_BILLING_RECORDS,
} = actionTypes;

const defaultState = {
  data: [],
  errors: undefined,
  finishedLoadAt: undefined,
  isLoading: false,
  startedLoadAt: undefined,
};

const billingRecordsReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case CATCH_BILLING_RECORDS_ERROR:
        draft.errors = Array.isArray(action.errors)
          ? [...draft.errors, action.error.message || action.error]
          : [action.error.message || action.error];
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case RECEIVE_BILLING_RECORDS:
        draft.data = action.billing_records;
        draft.errors = undefined;
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case REQUEST_BILLING_RECORDS:
        draft.errors = undefined;
        draft.finishedLoadAt = undefined;
        draft.isLoading = true;
        draft.startedLoadAt = moment().format();
    }
  });

export default billingRecordsReducer;
