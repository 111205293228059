import moment from 'moment';

const datePickerFormat = 'YYYY-MM-DD';

export default {
  SingleDatePicker: {
    date_start: moment().format(datePickerFormat),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment().add(-1, 'days').format(datePickerFormat),
    date_end_secondary: moment().add(-1, 'days').format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'days')
        .format(datePickerFormat),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'days')
        .format(datePickerFormat),
    }),
    urlParam: 'hourly',
    axisBottom: (d) => moment(d, 'YYYY MM DD hha').format('hh:mm a'),
    axisBottomLabel: 'Day',
    formatter: (d) => moment(d, 'YYYY MM DD hha').format('hh:mm a'),
    indexBy: 'date',
    showTotals: true,
  },
  WeeklyDatePicker: {
    date_start: moment()
      .startOf('isoWeek')
      .subtract(1, 'd')
      .format(datePickerFormat),
    date_end: moment()
      .endOf('isoWeek')
      .subtract(1, 'd')
      .format(datePickerFormat),
    date_start_secondary: moment()
      .startOf('isoWeek')
      .subtract(1, 'w')
      .subtract(1, 'd')
      .format(datePickerFormat),
    date_end_secondary: moment()
      .endOf('isoWeek')
      .subtract(1, 'w')
      .subtract(1, 'd')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .subtract(6, 'd')
        .startOf('isoWeek')
        .subtract(1, 'd')
        .format(datePickerFormat),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .subtract(6, 'd')
        .endOf('isoWeek')
        .subtract(1, 'd')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d) => moment(d, 'YYYY MM DD').format('ddd'),
    axisBottomLabel: 'Week',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: true,
  },
  MonthlyDatePicker: {
    date_start: moment().format('YYYY-MM-01'),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment().add(-1, 'months').format('YYYY-MM-01'),
    date_end_secondary: moment()
      .add(-1, 'months')
      .endOf('month')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'months')
        .startOf('month')
        .format('YYYY-MM-01'),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'months')
        .endOf('month')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d, info = null) => {
      if (info.ticks < 7) return moment(d, 'YYYY MM DD').format('DD MMM');
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('DD MMM');
      }
      return '';
    },
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    axisBottomLabel: 'Month',
    indexBy: 'date',
    showTotals: false,
  },
  QuarterDatePicker: {
    date_start: moment().startOf('quarter').format(datePickerFormat),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment()
      .startOf('quarter')
      .add(-1, 'quarters')
      .format(datePickerFormat),
    date_end_secondary: moment().add(-1, 'quarters').format(datePickerFormat),
    date_end_secondary_chart: moment()
      .add(-1, 'quarters')
      .endOf('quarter')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'quarters')
        .startOf('quarter')
        .format('YYYY-MM-01'),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'quarters')
        .endOf('quarter')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d, info = null) => {
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('DD MMM');
      }
      return '';
    },
    axisBottomLabel: 'Quarter',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  YearlyDatePicker: {
    date_start: moment(`${new Date().getFullYear()}-01-01`).format(
      datePickerFormat,
    ),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment(`${new Date().getFullYear()}-01-01`)
      .add(-1, 'years')
      .format('YYYY-MM-01'),
    date_end_secondary: moment()
      .add(-1, 'years')
      .endOf('year')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'years')
        .startOf('year')
        .format('YYYY-MM-01'),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'years')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      const mockYear = moment(primaryDates[0].date, 'YYYY MM DD').format(
        'YYYY',
      );
      if (mockYear === '2016') {
        return moment(d, 'YYYY MM DD').format('2017 MM DD');
      }
      return moment(d, 'YYYY MM DD').format(`${mockYear} MM DD`);
    },
    urlParam: 'monthly',
    axisBottom: (d) => moment(d, 'YYYY MM DD').format('MMM'),
    axisBottomLabel: 'Year',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  currentDay: {
    date_start: moment().format(datePickerFormat),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment().subtract(1, 'day').format(datePickerFormat),
    date_end_secondary: moment().subtract(1, 'day').format(datePickerFormat),
    date_end_secondary_chart: moment()
      .subtract('1', 'day')
      .format(datePickerFormat),
    setSecondaryDefault: (d) => ({
      date_start_secondary: moment(d, 'YYYY MM DD')
        .subtract(1, 'day')
        .format(datePickerFormat),
      date_end_secondary: moment(d, 'YYYY MM DD')
        .subtract(1, 'day')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .subtract(diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d, info = null) => {
      if (info.ticks < 7) return moment(d, 'YYYY MM DD').format('DD MMM');
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('DD MMM');
      }
      return '';
    },
    axisBottomLabel: 'Week',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  currentWeek: {
    date_start: moment().startOf('week').format(datePickerFormat),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment()
      .startOf('week')
      .subtract(1, 'week')
      .format(datePickerFormat),
    date_end_secondary: moment().subtract(1, 'week').format(datePickerFormat),
    date_end_secondary_chart: moment()
      .startOf('week')
      .subtract('1', 'week')
      .endOf('week')
      .format(datePickerFormat),
    setSecondaryDefault: (d) => ({
      date_start_secondary: moment(d, 'YYYY MM DD')
        .subtract(1, 'week')
        .startOf('week')
        .format(datePickerFormat),
      date_end_secondary: moment(d, 'YYYY MM DD')
        .subtract(1, 'week')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .subtract(diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d, info = null) => {
      if (info.ticks < 7) return moment(d, 'YYYY MM DD').format('DD MMM');
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('DD MMM');
      }
      return '';
    },
    axisBottomLabel: 'Week',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  monthToDate: {
    date_start: moment().format('YYYY-MM-01'),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment().add(-1, 'months').format('YYYY-MM-01'),
    date_end_secondary: moment().add(-1, 'months').format(datePickerFormat),
    date_end_secondary_chart: moment()
      .add(-1, 'months')
      .endOf('month')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'months')
        .startOf('month')
        .format('YYYY-MM-01'),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'months')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d, info = null) => {
      if (info.ticks < 7) return moment(d, 'YYYY MM DD').format('DD MMM');
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('DD MMM');
      }
      return '';
    },
    axisBottomLabel: 'Month',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  quarterToDate: {
    date_start: moment().startOf('quarter').format(datePickerFormat),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment()
      .startOf('quarter')
      .add(-1, 'quarters')
      .format(datePickerFormat),
    date_end_secondary: moment().add(-1, 'quarters').format(datePickerFormat),
    date_end_secondary_chart: moment()
      .add(-1, 'quarters')
      .endOf('quarter')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'quarters')
        .startOf('quarter')
        .format('YYYY-MM-01'),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'quarters')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d, info = null) => {
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('DD MMM');
      }
      return '';
    },
    axisBottomLabel: 'Quarter',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  yearToDate: {
    date_start: moment(`${new Date().getFullYear()}-01-01`).format(
      datePickerFormat,
    ),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment(`${new Date().getFullYear()}-01-01`)
      .add(-1, 'years')
      .format('YYYY-MM-01'),
    date_end_secondary: moment().add(-1, 'years').format(datePickerFormat),
    date_end_secondary_chart: moment()
      .add(-1, 'years')
      .endOf('year')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => ({
      date_start_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'years')
        .startOf('year')
        .format('YYYY-MM-01'),
      date_end_secondary: moment(date_end, 'YYYY MM DD')
        .add(-1, 'years')
        .format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'months',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'months')
        .format('YYYY MM DD');
    },
    urlParam: 'monthly',
    axisBottom: (d) => moment(d, 'YYYY MM DD').format('MMM'),
    axisBottomLabel: 'Year',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  lifetime: {
    date_start: moment('2016-07-01').format(datePickerFormat),
    date_end: moment().format(datePickerFormat),
    date_start_secondary: moment('2016-07-01').format(datePickerFormat),
    date_end_secondary: moment().format(datePickerFormat),
    setSecondaryDefault: (_date_start, _date_end) => ({
      date_start_secondary: moment('2016-07-01').format(datePickerFormat),
      date_end_secondary: moment().format(datePickerFormat),
    }),
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'months',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'months')
        .format('YYYY MM DD');
    },
    urlParam: 'monthly',
    axisBottom: (d, info = null) => {
      if (info.index % 7 === 0) {
        return moment(d, 'YYYY MM DD').format('MMM YY');
      }
      return '';
    },
    axisBottomLabel: 'All Time',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
  custom: {
    date_start: moment()
      .startOf('isoWeek')
      .subtract(1, 'd')
      .format(datePickerFormat),
    date_end: moment()
      .endOf('isoWeek')
      .subtract(1, 'd')
      .format(datePickerFormat),
    date_start_secondary: moment()
      .startOf('isoWeek')
      .subtract(1, 'w')
      .subtract(1, 'd')
      .format(datePickerFormat),
    date_end_secondary: moment()
      .endOf('isoWeek')
      .subtract(1, 'w')
      .subtract(1, 'd')
      .format(datePickerFormat),
    setSecondaryDefault: (date_start, date_end) => {
      const diffDays = moment(date_start, 'YYYY MM DD').diff(
        moment(date_end, 'YYYY MM DD'),
        'days',
      );
      return {
        date_start_secondary: moment(date_start, 'YYYY MM DD')
          .add(diffDays, 'days')
          .format(datePickerFormat),
        date_end_secondary: moment(date_start)
          .add(diffDays, 'days')
          .format(datePickerFormat),
      };
    },
    secondaryReverse: (d, index, filterDates, primaryDates) => {
      if (index === 0) {
        return moment(primaryDates[0].date, 'YYYY MM DD').format('YYYY MM DD');
      }
      const diffDays = moment(filterDates[0].date, 'YYYY MM DD').diff(
        moment(d, 'YYYY MM DD'),
        'days',
      );
      return moment(primaryDates[0].date, 'YYYY MM DD')
        .add(-diffDays, 'days')
        .format('YYYY MM DD');
    },
    urlParam: 'daily',
    axisBottom: (d) => moment(d, 'YYYY MM DD').format('ddd'),
    axisBottomLabel: 'Day',
    formatter: (d) => moment(d, 'YYYY MM DD').format('MMM DD, YYYY'),
    indexBy: 'date',
    showTotals: false,
  },
};
