import builder, { Query, Sort } from 'elastic-builder';

import { SEARCH_SORT_DIRECTION } from '../../constants/search';
import { BackgroundStatus } from '../../constants/backgroundStatuses';
import { UserRole } from '../../constants/userRoles';
import { IdentityVerificationStatus } from '../../constants/identityVerificationStatuses';
import { InsuranceStatus } from '../../constants/insuranceStatuses';
import { BackgroundAdjudicationStatus } from '../../constants/backgroundAdjudicationStatuses';
import {
  IDENTITY_CONFIDENCE_PASSING_SCORE,
  IDENTITY_MANUALLY_DECLINED,
} from '../../utils/background/getIdentityStatus';
import { UserStatus } from '../../constants/userStatuses';
import {
  MustQuery,
  MustNotQuery,
  SearchFilters,
  GenerateConsolidatedJobQuery,
} from './common';
import { WORKORDER_STATUS } from '../../constants/workorderStatuses';

export type GenerateUsersSearchArgs = {
  ids: string[];
  searchTerm?: string;
  status?: number;
  registrationStatus?: number;
  sortBy?: string;
  sortDirection?: SEARCH_SORT_DIRECTION;
};

const addBackgroundSearch = (
  backgroundStatus: BackgroundStatus[],
  automatedBackgroundCheck: boolean,
  mustQueries: MustQuery[],
  mustNotQueries: MustNotQuery[],
): void => {
  backgroundStatus.forEach((status) => {
    switch (status) {
      case BackgroundStatus.Clear:
        if (automatedBackgroundCheck) {
          mustQueries.push(
            builder
              .boolQuery()
              .should([
                builder.termQuery('background_status', BackgroundStatus.Clear),
                builder
                  .boolQuery()
                  .must([
                    builder.termQuery(
                      'background_status',
                      BackgroundStatus.Consider,
                    ),
                    builder.termQuery(
                      'background_adjudication',
                      BackgroundAdjudicationStatus.Engaged,
                    ),
                  ]),
              ]),
          );
        } else {
          mustQueries.push(
            builder.termQuery('pro_status', BackgroundStatus.Clear),
          );
        }

        break;
      case BackgroundStatus.Fail:
        mustQueries.push(
          builder
            .boolQuery()
            .should([
              builder.termQuery('background_status', BackgroundStatus.Fail),
              builder
                .boolQuery()
                .must([
                  builder.termQuery(
                    'background_status',
                    BackgroundStatus.Consider,
                  ),
                  builder.termQuery(
                    'background_adjudication',
                    BackgroundAdjudicationStatus.Declined,
                  ),
                ]),
            ]),
        );

        break;
      case BackgroundStatus.Missing:
        mustNotQueries.push(builder.existsQuery('background_status'));
        mustNotQueries.push(builder.existsQuery('background_adjudication'));

        break;
      case BackgroundStatus.Pending:
        if (automatedBackgroundCheck) {
          mustQueries.push(
            builder.termQuery('background_status', BackgroundStatus.Pending),
          );
        } else {
          mustNotQueries.push(
            builder.termQuery('pro_status', BackgroundStatus.Clear),
          );
        }

        break;
      default:
        mustQueries.push(builder.termQuery('background_status', status));

        break;
    }
  });
};

const addInsuranceSearch = (
  insuranceStatus: InsuranceStatus[],
  mustQueries: MustQuery[],
  mustNotQueries: MustNotQuery[],
): void => {
  insuranceStatus.forEach((status) => {
    if (InsuranceStatus.Clear === status) {
      mustQueries.push(
        builder.boolQuery().filter([
          builder.termQuery('insured', 1), // TODO: replace value with enum
          builder.termQuery('insurance_status', InsuranceStatus.Clear),
          builder.termQuery('insurance_submitted_coterie_tos', 1),
        ]),
      );
    } else {
      mustQueries.push(builder.termQuery('insured', 0));
      mustQueries.push(builder.termQuery('insurance_submitted_coterie_tos', 0));

      if (InsuranceStatus.Missing) {
        mustNotQueries.push(builder.existsQuery('insurance_status'));
      } else {
        mustQueries.push(builder.termQuery('insurance_status', status));
      }
    }
  });
};

const addIdentityVerificationStatusSearch = (
  identityVerificationStatus: IdentityVerificationStatus[],
  mustQueries: MustQuery[],
): void => {
  identityVerificationStatus.forEach((status) => {
    switch (status) {
      case IdentityVerificationStatus.Cleared:
        mustQueries.push(
          builder
            .rangeQuery('identity_confidence')
            .gte(IDENTITY_CONFIDENCE_PASSING_SCORE),
        );

        break;
      case IdentityVerificationStatus.Pending:
        mustQueries.push(
          builder
            .boolQuery()
            .should([
              builder.termQuery(
                'identity_confidence',
                IDENTITY_MANUALLY_DECLINED,
              ),
              builder
                .boolQuery()
                .must([
                  builder.rangeQuery('identity_fail_count').lt(3),
                  builder
                    .rangeQuery('identity_confidence')
                    .lt(IDENTITY_CONFIDENCE_PASSING_SCORE),
                ]),
            ]),
        );

        break;
      case IdentityVerificationStatus.Failed:
        mustQueries.push(builder.rangeQuery('identity_fail_count').gte(3));

        mustQueries.push(
          builder
            .rangeQuery('identity_confidence')
            .lt(IDENTITY_CONFIDENCE_PASSING_SCORE),
        );
        break;
      default:
        break;
    }
  });
};

export const generateConsumerSearch = (
  marketId: string | undefined,
  regionId: string | undefined,
  sortBy?: string | string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [
    builder.termsQuery('roles', [UserRole.Consumer]),
  ];

  if (marketId) {
    mustQueries.push(builder.matchQuery('market_id', marketId));
  }

  if (regionId) {
    mustQueries.push(builder.matchQuery('region_id', regionId));
  }

  const searchBody = builder.requestBodySearch().query(
    builder.boolQuery().filter(
      builder
        .boolQuery()
        .must(mustQueries)
        .mustNot(builder.termsQuery('roles', [UserRole.Provider])),
    ),
  );

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sort) =>
      builder.sort(sort, sortDirection),
    );
    searchBody.sorts(sorts);
  } else if (sortBy) {
    searchBody.sort(builder.sort(sortBy, sortDirection));
  }

  return searchBody;
};

export const generateProviderSearch = (
  marketId: string | undefined,
  regionId: string | undefined,
  serviceTypes: number[] | undefined,
  backgroundStatus: BackgroundStatus[] | undefined,
  insuranceStatus: InsuranceStatus[] | undefined,
  identityVerificationStatus: IdentityVerificationStatus[] | undefined,
  automatedBackgroundCheck: boolean,
  sortBy: string | string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [
    builder.termsQuery('roles', [UserRole.Provider]),
  ];

  const mustNotQueries: MustNotQuery[] = [
    builder.termsQuery('roles', [UserRole.Internal]),
  ];

  if (marketId) {
    mustQueries.push(builder.matchQuery('market_id', marketId));
  }

  if (regionId) {
    mustQueries.push(builder.matchQuery('region_id', regionId));
  }

  if (serviceTypes) {
    mustQueries.push(builder.termsQuery('services.type', serviceTypes));
  }

  if (backgroundStatus) {
    addBackgroundSearch(
      backgroundStatus,
      automatedBackgroundCheck,
      mustQueries,
      mustNotQueries,
    );
  }

  if (insuranceStatus) {
    addInsuranceSearch(insuranceStatus, mustQueries, mustNotQueries);
  }

  if (identityVerificationStatus) {
    addIdentityVerificationStatusSearch(
      identityVerificationStatus,
      mustQueries,
    );
  }

  const searchBody = builder
    .requestBodySearch()
    .query(
      builder
        .boolQuery()
        .filter(builder.boolQuery().must(mustQueries).mustNot(mustNotQueries)),
    );

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sort) =>
      builder
        .sort()
        .type('string')
        .script(
          builder
            .script('inline', `doc['${sort}'].value.trim()`)
            .lang('painless'),
        )
        .order(sortDirection),
    );
    searchBody.sorts(sorts);
  } else {
    searchBody.sort(builder.sort(sortBy, sortDirection));
  }

  return searchBody;
};

export const generateProviderDropdownSearch = (
  marketId: string | undefined,
  organizationId: string | undefined,
  serviceType: number | undefined,
  searchTerm: string | undefined,
  sortBy?: string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [
    builder.termsQuery('roles', [UserRole.Provider]),
    builder.termQuery('status', UserStatus.Active),
  ];

  if (serviceType) {
    mustQueries.push(builder.termsQuery('services.type', [serviceType]));
  }

  if (marketId) {
    mustQueries.push(builder.termQuery('market_id', marketId));
  }

  if (organizationId) {
    mustQueries.push(
      builder
        .boolQuery()
        .should([
          builder.termsQuery('in_house_organization_ids', [organizationId]),
        ]),
    );
  }

  const boolQuery = builder
    .boolQuery()
    .filter(builder.boolQuery().must(mustQueries));

  if (searchTerm) {
    boolQuery.should([
      builder.matchQuery('id', searchTerm),
      builder
        .functionScoreQuery()
        .functions([generateDecayScore()])
        .query(
          builder
            .boolQuery()
            .should(generateBuilderQuery({ searchTerm, needsMobile: false })),
        ),
    ]);
  }

  boolQuery.minimumShouldMatch('75%');

  const searchBody = builder.requestBodySearch().query(boolQuery);

  if (sortBy) {
    const sorts: Sort[] = sortBy.map((sort) =>
      builder.sort(sort, sortDirection),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};

export const generateAssigneeSearch = (
  searchTerm: string,
  roles: UserRole[],
  sortBy?: string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [
    builder.termsQuery('roles', roles),
    builder.termQuery('status', UserStatus.Active),
  ];

  const innerBoolQuery = builder.boolQuery();
  innerBoolQuery.must(mustQueries);

  const mainBoolQuery = builder.boolQuery().filter(innerBoolQuery);

  if (searchTerm) {
    mainBoolQuery.should([
      builder.matchAllQuery(),
      builder.matchQuery('id', searchTerm),
      builder
        .functionScoreQuery()
        .functions([generateDecayScore()])
        .query(
          builder
            .boolQuery()
            .should(generateBuilderQuery({ searchTerm, needsMobile: true })),
        ),
    ]);
  }

  mainBoolQuery.minimumShouldMatch('75%');

  const searchBody = builder.requestBodySearch().query(mainBoolQuery);

  if (sortBy) {
    const sorts: Sort[] = sortBy.map((sort) =>
      builder.sort(sort, sortDirection),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};

export const generateAdjusterSearch = (
  searchTerm: string,
  organizationId?: string,
  sortBy?: string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [
    builder.termsQuery('roles', [UserRole.ClaimsAdjuster]),
    builder.termQuery('status', UserStatus.Active),
  ];

  if (organizationId) {
    mustQueries.push(builder.termQuery('organizations', organizationId));
  }

  const innerBoolQuery = builder.boolQuery();
  innerBoolQuery.must(mustQueries);

  const mainBoolQuery = builder.boolQuery().filter(innerBoolQuery);

  if (searchTerm) {
    mainBoolQuery.should([
      builder.matchQuery('id', searchTerm),
      builder
        .functionScoreQuery()
        .functions([generateDecayScore()])
        .query(
          builder
            .boolQuery()
            .should(generateBuilderQuery({ searchTerm, needsMobile: true })),
        ),
    ]);
  }

  mainBoolQuery.minimumShouldMatch('75%');

  const searchBody = builder.requestBodySearch().query(mainBoolQuery);

  if (sortBy) {
    const sorts: Sort[] = sortBy.map((sort) =>
      builder.sort(sort, sortDirection),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};

export const generateManagerSearch = (
  searchTerm?: string,
  organizationId?: string,
  partner?: string,
  userId?: string,
  sortBy?: string[],
  sortDirection: SEARCH_SORT_DIRECTION = SEARCH_SORT_DIRECTION.asc,
  consumerId?: string,
): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [
    builder.termsQuery('roles', [UserRole.Consumer]),
    builder.termQuery('status', UserStatus.Active),
  ];
  if (consumerId) {
    mustQueries.push(builder.termQuery('_id', consumerId));
  }
  if (organizationId) {
    mustQueries.push(builder.termQuery('organizations', organizationId));
  }

  if (partner) {
    mustQueries.push(builder.termsQuery('partners', [partner]));
  }

  const innerBoolQuery = builder.boolQuery();

  if (userId) {
    innerBoolQuery.should([
      builder.boolQuery().must(mustQueries),
      builder.termQuery('_id', userId),
    ]);
  } else {
    innerBoolQuery.must(mustQueries);
  }

  const mainBoolQuery = builder.boolQuery().filter(innerBoolQuery);

  if (searchTerm) {
    mainBoolQuery.should([
      builder.matchQuery('id', searchTerm),
      builder
        .functionScoreQuery()
        .functions([generateDecayScore()])
        .query(
          builder
            .boolQuery()
            .should(generateBuilderQuery({ searchTerm, needsMobile: true })),
        ),
    ]);
  }

  mainBoolQuery.minimumShouldMatch('75%');

  const searchBody = builder.requestBodySearch().query(mainBoolQuery);

  if (sortBy) {
    const sorts: Sort[] = sortBy.map((sort) =>
      builder.sort(sort, sortDirection),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};

export const generateUserSearchBarSearch = (
  searchTerm: string,
): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder.boolQuery().should([
        builder.matchQuery('id', searchTerm),
        builder
          .functionScoreQuery()
          .functions([generateDecayScore()])
          .query(
            builder
              .boolQuery()
              .should(generateBuilderQuery({ searchTerm, needsMobile: true })),
          ),
      ]),
    )
    .minScore(0.01);

  return searchBody;
};

export const generateUsersSearch = ({
  ids,
  searchTerm,
  status,
  registrationStatus,
  sortBy,
  sortDirection = SEARCH_SORT_DIRECTION.asc,
}: GenerateUsersSearchArgs): builder.RequestBodySearch => {
  const terms = [
    builder.termsQuery('id', ids),
    builder.functionScoreQuery().functions([generateDecayScore()]),
  ];

  if (status) {
    terms.push(builder.termsQuery('status', status));
  }

  if (registrationStatus) {
    terms.push(builder.termsQuery('registration_status', registrationStatus));
  }

  const boolQuery = builder.boolQuery().must(terms);

  if (searchTerm) {
    boolQuery.should([
      builder.matchQuery('id', searchTerm),
      builder
        .functionScoreQuery()
        .functions([generateDecayScore()])
        .query(
          builder
            .boolQuery()
            .should(generateBuilderQuery({ searchTerm, needsMobile: false })),
        ),
    ]);
  }

  boolQuery.minimumShouldMatch('75%');

  const searchBody = builder.requestBodySearch().query(boolQuery);

  if (sortBy) {
    searchBody.sort(builder.sort(sortBy, sortDirection));
  }

  return searchBody;
};

export const generateUserSearchPageSearch = ({
  searchTerm,
  roles,
  marketId,
  regionId,
  numberJobsStart,
  numberJobsEnd,
  sortBy,
  sortDirection,
}: SearchFilters): builder.RequestBodySearch => {
  const mustQueries: MustQuery[] = [];

  if (marketId) {
    mustQueries.push(builder.matchQuery('market_id', marketId));
  }

  if (regionId) {
    mustQueries.push(builder.matchQuery('region_id', regionId));
  }

  if (roles.length) {
    mustQueries.push(builder.termsQuery('roles', roles));
  }

  if (numberJobsStart) {
    // min number of jobs
    mustQueries.push(builder.rangeQuery('job_count').gte(numberJobsStart));
  }

  if (numberJobsEnd) {
    // max number of jobs
    mustQueries.push(builder.rangeQuery('job_count').lt(numberJobsEnd));
  }

  const searchBody = builder
    .requestBodySearch()
    .query(
      builder
        .boolQuery()
        .filter(builder.boolQuery().must(mustQueries))
        .should([
          builder.matchQuery('id', searchTerm),
          builder
            .functionScoreQuery()
            .functions([generateDecayScore()])
            .query(
              builder
                .boolQuery()
                .should(
                  generateBuilderQuery({ searchTerm, needsMobile: false }),
                ),
            ),
        ]),
    )
    .minScore(0.01);

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection || SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};

export function generateDecayScore() {
  return builder
    .decayScoreFunction('exp', 'created_at')
    .origin('now')
    .scale('365d')
    .offset('2d')
    .decay(0.9);
}

export const generateBuilderQuery = (args: {
  searchTerm: string;
  needsMobile: boolean;
}): Query[] => {
  const { searchTerm, needsMobile } = args;

  let builderArray: (
    | builder.MatchQuery
    | builder.WildcardQuery
    | builder.MultiMatchQuery
    | builder.MatchPhrasePrefixQuery
  )[] = [
    builder.matchQuery('email', searchTerm).boost(2),
    builder.wildcardQuery('email', `*${searchTerm}*`),

    builder
      .multiMatchQuery(['first_name', 'last_name'], searchTerm)
      .type('cross_fields')
      .operator('or')
      .boost(3),
  ];

  if (searchTerm.includes(' ')) {
    const newBuilderArray = [
      builder.matchPhrasePrefixQuery('full_name', searchTerm).boost(3),
    ];

    builderArray = newBuilderArray;
  }

  if (needsMobile) {
    builderArray.push(
      builder
        .wildcardQuery('mobile', `*${searchTerm.replace(/\W/g, '')}*`)
        .boost(3),
    );
  }

  return builderArray;
};

export function generateConsolidatedJobQuery({
  statuses,
  companyId,
  userId,
  isEstimate,
}: GenerateConsolidatedJobQuery): builder.RequestBodySearch {
  const mustNotMatchQueries: builder.MatchQuery[] = [];
  const matchQueries: builder.MatchQuery[] = [
    builder.matchQuery('company_identifier', companyId),
  ];

  const statusTermsQuery: builder.TermsQuery = builder.termsQuery(
    'job_status.keyword',
    statuses,
  );

  if (userId) {
    matchQueries.push(builder.matchQuery('job_provider_id', userId));
  }

  if (isEstimate === true) {
    matchQueries.push(builder.matchQuery('service_request_type', 'Estimate'));
  } else if (isEstimate === false) {
    mustNotMatchQueries.push(
      builder.matchQuery('service_request_type', 'Estimate'),
    );
  }

  const esQuery = builder.requestBodySearch().query(
    builder
      .boolQuery()
      .must([...matchQueries])
      .mustNot([
        ...mustNotMatchQueries,
        builder.termsQuery('workorder_status', [
          WORKORDER_STATUS.deleted,
          WORKORDER_STATUS.rejected,
          WORKORDER_STATUS.approval_needed,
          WORKORDER_STATUS.draft,
        ]),
      ]),
  );

  return esQuery;
}
