import classnames from 'classnames';
import css from 'styled-jsx/css';
import React from 'react';

import Icon from './Icon';
import { COLOR, SPACING, FONT_SIZE, LINE_HEIGHT, TRANSITION } from './theme';

type ButtonVariant = 'primary' | 'secondary' | 'negative' | 'disabled';

const colors = {
  secondary: {
    light: COLOR.black,
    dark: COLOR.white,
  },
  disabled: {
    light: COLOR.darkGray,
    dark: COLOR.lightGray,
  },
  primary: {
    light: COLOR.blue,
    dark: COLOR.blue,
  },
  negative: {
    light: COLOR.red,
    dark: COLOR.red,
  },
} as const;

const { className: glyphClassName, styles: glyphStyles } = css.resolve`
  .icon {
    font-size: ${FONT_SIZE.s18};
    line-height: ${LINE_HEIGHT.s18};
  }
`;

const getColor = (variant: ButtonVariant, disabled = false, onDark): string => {
  if (disabled) {
    return onDark ? colors.disabled.dark : colors.disabled.light;
  }

  return onDark ? colors[variant].dark : colors[variant].light;
};

type Props = {
  className?: string;
  disabled?: boolean;
  glyph?: string | React.ReactElement;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: Record<string, string | number>;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  onDark?: boolean;
  label: string;
} & React.HTMLProps<HTMLButtonElement>;

const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      disabled = false,
      glyph,
      id,
      onClick,
      style,
      type = 'button',
      variant = 'primary',
      onDark = false,
      label,
      ...buttonProps
    },
    ref,
  ) => {
    const iconColor = getColor(variant, disabled, onDark);

    const opacity = disabled ? 0.6 : 1;

    return (
      <button
        aria-live="polite"
        aria-label={label}
        className={classnames('root', className, variant)}
        disabled={disabled}
        id={id}
        onClick={onClick}
        ref={ref}
        style={style}
        type={type} // eslint-disable-line react/button-has-type
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      >
        {typeof glyph === 'string' && (
          <Icon
            className={`icon ${glyphClassName}`}
            icon={glyph}
            style={{
              color: iconColor,
            }}
          />
        )}
        {glyph && typeof glyph !== 'string' && (
          <div
            className={`icon ${glyphClassName}`}
            style={{
              color: iconColor,
            }}
          >
            {glyph}
          </div>
        )}
        <style jsx>
          {`
            /* Root */
            .root {
              border: none;
              background: none;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              padding: 0 ${SPACING.xs}px;
              position: relative;
            }

            .root:disabled {
              pointer-events: none;
            }

            .root :global(.icon),
            .root :global(span) {
              opacity: ${opacity};
              transition: color ${TRANSITION.duration}
                ${TRANSITION.timingFunction};
            }

            .primary:hover :global(.icon),
            .primary:hover :global(span) {
              color: ${COLOR.blueHover} !important;
            }
          `}
        </style>
        {glyphStyles}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
