import { z } from 'zod';

const clientConstantsSchema = z.object({
  FLAG_PROVIDER: z.string().default('split'),
  DATADOG_APPLICATION_ID: z.string().optional(),
  DATADOG_CLIENT_TOKEN: z.string().optional(),
  DATADOG_SERVICE: z.string().optional(),
  DATADOG_ENABLED: z.boolean(),
  NODE_ENV: z
    .enum(['development', 'production', 'test'])
    .default('development'),
  INTERCOM_APP_ID: z.string().optional(),
  SPLIT_API_KEY: z.string(),
  STRIPE_API_KEY: z.string(),
  BRAND_PATH: z.string(),
  BRAND_NAME: z.string(),
  MAPBOX_ACCESS_TOKEN: z.string(),
  RECAPTCHA_SITE_KEY: z.string().optional(),
  MAPBOX_STYLE_ID: z.string(),
  APP_ENV: z
    .enum(['development', 'staging', 'production'])
    .default('development'),
  PROPORTAL_URL: z.string(),
});

const clientConstants = clientConstantsSchema.parse({
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  FLAG_PROVIDER: process.env.NEXT_PUBLIC_FLAG_PROVIDER,
  DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  DATADOG_SERVICE: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  DATADOG_ENABLED: process.env.NEXT_PUBLIC_DATADOG_ENABLED === 'true',
  NODE_ENV: process.env.NODE_ENV,
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  SPLIT_API_KEY: process.env.NEXT_PUBLIC_SPLIT_API_KEY,
  STRIPE_API_KEY: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
  BRAND_PATH: `/static/assets/${process.env.NEXT_PUBLIC_BRAND_PATH}`,
  BRAND_NAME: process.env.NEXT_PUBLIC_BRAND_NAME,
  MAPBOX_ACCESS_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  MAPBOX_STYLE_ID: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID,
  PROPORTAL_URL: process.env.NEXT_PUBLIC_PROPORTAL_URL,
});

export default clientConstants;
