/* eslint-disable no-param-reassign */
import moment from 'moment';
import produce from 'immer';
import { actionTypes } from '../actions/teamMemberActions';

const { CATCH_TEAM_MEMBERS_ERROR, RECEIVE_TEAM_MEMBERS, REQUEST_TEAM_MEMBERS } =
  actionTypes;

const defaultState = {
  data: [],
  errors: undefined,
  finishedLoadAt: undefined,
  isLoading: false,
  startedLoadAt: undefined,
};

const teamMemberReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case CATCH_TEAM_MEMBERS_ERROR:
        draft.errors = Array.isArray(action.errors)
          ? [...draft.errors, action.error.message || action.error]
          : [action.error.message || action.error];
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case RECEIVE_TEAM_MEMBERS:
        draft.data = action.team_members;
        draft.errors = undefined;
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case REQUEST_TEAM_MEMBERS:
        draft.errors = undefined;
        draft.finishedLoadAt = undefined;
        draft.isLoading = true;
        draft.startedLoadAt = moment().format();
    }
  });

export default teamMemberReducer;
