import React from 'react';

import Icon from '../Icon';
import Text from '../Text';

import { COLOR, SPACING } from '../theme';

let optId = 0;
function uniqueOptionId(prefix) {
  optId += 1;
  return `${prefix}-${optId}`;
}

function Option({
  data,
  innerRef,
  innerProps,
  isDisabled,
  isFocused,
  isMulti,
  isSelected,
  options,
}) {
  const { label, subLabel, icon, value } = data;

  let backgroundColor = COLOR.white;
  if (isFocused || isSelected) {
    backgroundColor = COLOR.offWhite;
  }

  let renderedIcon;
  if (typeof icon === 'string') {
    renderedIcon = <Icon icon={icon} />;
  } else if (typeof icon === 'function') {
    renderedIcon = icon(value);
  } else if (typeof icon === 'object') {
    renderedIcon = icon;
  }

  let labelWeight = 'regular';
  if (subLabel) {
    labelWeight = 'semi';
  }

  const index = options.findIndex((option) => option === data);
  let borderTop;
  let borderBottom;
  if (isSelected) {
    borderTop = index !== 0 && `1px solid ${COLOR.black}`;
    borderBottom = index !== options.length - 1 && `1px solid ${COLOR.black}`;
  }

  const subLabels = [subLabel].flat();
  return (
    <div
      className="option"
      style={{
        backgroundColor,
        borderTop,
        borderBottom,
      }}
      ref={innerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    >
      {renderedIcon}
      <div className="wrapper" style={{ marginLeft: renderedIcon ? 16 : 0 }}>
        <Text
          color={isDisabled ? 'gray' : 'default'}
          element="span"
          size="large"
          weight={labelWeight}
          data-testid="options-selection"
        >
          {label}
        </Text>
        {subLabels &&
          subLabels.map((s) => (
            <Text
              color={isDisabled ? 'gray' : 'default'}
              element="span"
              size="large"
              weight="regular"
              key={uniqueOptionId(s)}
              data-testid="options-label-selection"
            >
              {s}
            </Text>
          ))}
      </div>
      {isMulti && (
        <Icon
          icon={isSelected ? 'checkBox' : 'checkBoxOutlineBlank'}
          style={{
            color: isSelected ? COLOR.blue : COLOR.darkGray,
            cursor: 'pointer',
            marginLeft: SPACING.xs,
            marginRight: SPACING.xs,
          }}
        />
      )}
      <style jsx>
        {`
          .option {
            align-items: center;
            display: flex;
            margin: 0;
            min-height: ${SPACING.lg}px;
            padding: ${SPACING.xxs}px 0px ${SPACING.xxs}px ${SPACING.xs}px;
            width: 100%;
            overflow: hidden;
            flex: 1 1 0;
            cursor: default;
          }

          .option:not(:last-child) {
            border-bottom: 1px solid ${COLOR.lightGray};
          }

          .wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
          }
        `}
      </style>
    </div>
  );
}

export default Option;
