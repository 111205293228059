import css from 'styled-jsx/css';
import classnames from 'classnames';
import React, { ChangeEvent } from 'react';
import Icon from './Icon';
import { COLOR } from './theme';

const { className: iconClassName, styles: iconStyles } = css.resolve`
  /* default state */
  .radio {
    cursor: pointer;
    color: ${COLOR.darkGray};
    position: absolute;
    top: 0;
    left: 0;
  }

  .focused:not(.checked):not(.error) {
    color: ${COLOR.black};
  }

  /* checked/selected state */
  .checked {
    color: ${COLOR.blue};
  }

  .checked.focused:not(.error) {
    color: ${COLOR.blueHover};
  }

  /* error state */
  .error {
    color: ${COLOR.red};
  }

  .error.focused:not(.checked) {
    color: ${COLOR.redHover};
  }
`;

type Props = {
  checked?: boolean;
  id?: string;
  isInvalid?: boolean;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any | Promise<any>;
  style?: React.CSSProperties;
  value?: string | number;
  disabled?: boolean;
  className?: string;
};

// eslint-disable-next-line react/function-component-definition
const Radio: React.FC<Props> = ({
  checked = false,
  id,
  isInvalid,
  name,
  onChange,
  style,
  value,
  disabled = false,
  className,
}: Props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const inputRef = React.createRef<HTMLInputElement>();

  const onKeyPress = () => {
    // Do nothing; The radio button is automatically selected when user tabs
    // to radio or uses the arrow keys.
  };

  return (
    <div
      aria-checked={checked ? 'true' : 'false'}
      className="root"
      onClick={() => {
        const { current } = inputRef;
        if (current) {
          current.focus();
          current.click();
        }
      }}
      onFocus={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      onKeyPress={onKeyPress}
      role="radio"
      tabIndex={-1}
    >
      <input
        className={className}
        checked={checked}
        id={id}
        data-testid={id}
        name={name}
        onBlur={() => {
          setIsFocused(false);
        }}
        onChange={(event) => {
          if (typeof onChange === 'function') {
            onChange(event);
          }
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        ref={inputRef}
        type="radio"
        value={value}
        disabled={disabled}
      />
      <Icon
        className={classnames('radio', iconClassName, {
          checked,
          focused: isFocused,
          error: isInvalid,
        })}
        size={24}
        icon={checked ? 'radioButtonChecked' : 'radioButtonUnchecked'}
        style={style}
      />
      <style jsx>
        {`
          .root {
            display: inline-block;
            height: 24px;
            overflow: hidden;
            position: relative;
            width: 24px;
          }

          input {
            height: 1px; /* making 0 hides from some screen readers */
            left: -10px; /* hide by pushing out of container */
            opacity: 0;
            position: absolute;
            width: 1px;
          }

          input:disabled + :global(.radio) {
            color: ${COLOR.lightGray};
            cursor: default;
          }
        `}
      </style>
      {iconStyles}
    </div>
  );
};

Radio.defaultProps = {
  checked: false,
  id: undefined,
  isInvalid: false,
  name: undefined,
  onChange: undefined,
  style: undefined,
  value: undefined,
  disabled: false,
};

export default Radio;
