import { defaultsDeep, valuesIn } from 'lodash';

import {
  SEARCH_DEFAULT_DECAY_PARAMS,
  SEARCH_DEFAULT_OPTION,
} from '../../../constants/search';
import createQueryBuilder from '../createQueryBuilder';

export const PROMOCODE_SEARCH_FIELD = Object.freeze({
  code: 'code',
  description: 'description',
});

export const ALL_PROMOCODE_SEARCH_FIELDS = Object.freeze(
  valuesIn(PROMOCODE_SEARCH_FIELD),
);

export const buildPromocodesQuery = (query, option) => {
  const { fields = ALL_PROMOCODE_SEARCH_FIELDS } = defaultsDeep(
    option,
    SEARCH_DEFAULT_OPTION,
  );

  const builder = createQueryBuilder(option);

  if (query && fields.length) {
    // Define the minimum score.
    builder.rawOption('min_score', 0.01);

    if (fields.includes(PROMOCODE_SEARCH_FIELD.code)) {
      builder
        .orQuery('wildcard', 'code', { value: `*${query}*` })
        .orQuery('wildcard', 'code.joined', { value: `*${query}*` });
    }

    builder.orQuery(
      'function_score',
      {
        exp: {
          start_date: SEARCH_DEFAULT_DECAY_PARAMS,
        },
      },
      (agg) => {
        if (fields.includes(PROMOCODE_SEARCH_FIELD.description)) {
          agg.orQuery('match', PROMOCODE_SEARCH_FIELD.description, query);
        }

        return agg;
      },
    );
  }

  return builder.build();
};

export default buildPromocodesQuery;
