import { defaultsDeep, valuesIn } from 'lodash';
import moment from 'moment';

import { BackgroundStatus } from '../../../constants/backgroundStatuses';
import { IDENTITY_VERIFICATION_STATUS } from '../../../constants/identityVerificationStatuses';
import { INSURANCE_STATUS } from '../../../constants/insuranceStatuses';
import { ALL_MARKETS } from '../../../constants/markets';
import {
  SEARCH_DEFAULT_DECAY_PARAMS,
  SEARCH_DEFAULT_OPTION,
  SEARCH_QUERY_DATE_FORMAT,
} from '../../../constants/search';
import {
  IDENTITY_CONFIDENCE_PASSING_SCORE,
  IDENTITY_MANUALLY_DECLINED,
} from '../../background/getIdentityStatus';
import createQueryBuilder from '../createQueryBuilder';

export const USER_SEARCH_FIELD = Object.freeze({
  email: 'email',
  firstName: 'first_name',
  id: 'id',
  lastName: 'last_name',
  mobile: 'mobile',
});

export const ALL_USER_SEARCH_FIELDS = Object.freeze(
  valuesIn(USER_SEARCH_FIELD),
);

export const buildUsersQuery = (query, option) => {
  const {
    fields = ALL_USER_SEARCH_FIELDS,
    filter: {
      inHouseOrganizationIds,
      marketId,
      jobCountRange,
      preferredOrganizationIds,
      regionId,
      roles,
      notRoles,
      serviceTypes,
      backgroundCheckStatus,
      insuranceStatus,
      insuranceStatusWithGracePeriod,
      identityVerification,
      organizationId,
      partner,
      status,
      userId,
    },
    isConsumerDropdownQuery,
    isAutomatedBackgroundCheckEnabled,
  } = defaultsDeep(option, SEARCH_DEFAULT_OPTION);

  const builder = createQueryBuilder(option);

  if (query && fields.length) {
    // Define the minimum score.
    builder.rawOption('min_score', 0.01);

    if (fields.includes(USER_SEARCH_FIELD.id)) {
      // Exclude matches on `id` from function score.
      builder.orQuery('match', USER_SEARCH_FIELD.id, query);
    }

    builder.orQuery(
      'function_score',
      {
        exp: {
          created_at: SEARCH_DEFAULT_DECAY_PARAMS,
        },
      },
      (agg) => {
        if (fields.includes(USER_SEARCH_FIELD.email)) {
          agg
            .orQuery('match', USER_SEARCH_FIELD.email, { query, boost: 2 })
            .orQuery('wildcard', USER_SEARCH_FIELD.email, {
              value: `*${query}*`,
            });
        }

        if (fields.includes(USER_SEARCH_FIELD.mobile)) {
          agg.orQuery('wildcard', USER_SEARCH_FIELD.mobile, {
            value: `*${query.replace(/\W/g, '')}*`,
            boost: 3,
          });
        }

        if (fields.includes(USER_SEARCH_FIELD.firstName)) {
          agg.orQuery('match', USER_SEARCH_FIELD.firstName, query);
        }

        if (fields.includes(USER_SEARCH_FIELD.lastName)) {
          agg.orQuery('match', USER_SEARCH_FIELD.lastName, query);

          if (fields.includes(USER_SEARCH_FIELD.firstName)) {
            agg.orQuery('multi_match', {
              query,
              fields: [USER_SEARCH_FIELD.firstName, USER_SEARCH_FIELD.lastName],
              type: 'cross_fields',
            });
            agg.orQuery('multi_match', {
              query,
              fields: ['first_name.joined', 'last_name.joined'],
              type: 'cross_fields',
            });
          }
        }

        return agg;
      },
    );
  }

  if (isConsumerDropdownQuery) {
    builder.orFilter('bool', (userBuilder) =>
      userBuilder
        .andFilter('terms', 'role', roles)
        .andFilter('term', 'status', status)
        .andFilter('terms', 'partners', partner),
    );

    builder.orFilter('term', '_id', userId);
  } else {
    // In house & preferred organization ids filter.
    if (inHouseOrganizationIds || preferredOrganizationIds) {
      builder.andFilter('bool', (networkBuilder) => {
        if (inHouseOrganizationIds) {
          networkBuilder.orFilter(
            'terms',
            'in_house_organization_ids',
            inHouseOrganizationIds,
          );
        }
        if (preferredOrganizationIds) {
          networkBuilder.orFilter(
            'terms',
            'preferredOrganizationIds',
            preferredOrganizationIds,
          );
        }
        return networkBuilder;
      });
    }

    // Market filter.
    if (marketId != null && marketId !== ALL_MARKETS.id) {
      builder.andFilter('term', 'market_id', marketId);
    }

    // Region filter.
    if (regionId != null) {
      builder.andFilter('term', 'region_id', regionId);
    }

    // Roles filter.
    if (roles && roles.length) {
      builder.andFilter('terms', 'role', roles);
    }

    // But block users who have these roles.
    if (notRoles && notRoles.length) {
      builder.notFilter('terms', 'role', notRoles);
    }

    // Job count range filter.
    if (jobCountRange && (jobCountRange.gte || jobCountRange.lte)) {
      builder.andFilter('range', 'job_count', jobCountRange);
    }

    // Status filter.
    if (status) {
      builder.andFilter('term', 'status', status);
    }

    // Organization filter.
    if (organizationId) {
      builder.andFilter('term', 'organizations', organizationId);
    }

    // Partners filter.
    if (partner) {
      builder.andFilter('terms', 'partners', partner);
    }

    // Provider service type filter.
    if (serviceTypes) {
      builder.andFilter('terms', 'services.type', serviceTypes);
    }

    // Provider background check status filter.
    if (backgroundCheckStatus) {
      if (backgroundCheckStatus === BackgroundStatus.Clear) {
        if (isAutomatedBackgroundCheckEnabled) {
          builder.andFilter('bool', (statusBuilder) =>
            statusBuilder
              .orFilter('term', 'background_status', BackgroundStatus.Clear)
              .orFilter('bool', (clearStatusBuilder) =>
                clearStatusBuilder
                  .andFilter(
                    'term',
                    'background_status',
                    BackgroundStatus.Consider,
                  )
                  .andFilter('term', 'background_adjudication', 'engaged'),
              ),
          );
        } else {
          builder.andFilter('term', 'pro_status', BackgroundStatus.Clear);
        }
      } else if (backgroundCheckStatus === BackgroundStatus.Fail) {
        builder.andFilter('bool', (statusBuilder) =>
          statusBuilder
            .orFilter('term', 'background_status', BackgroundStatus.Fail)
            .orFilter('bool', (failStatusBuilder) =>
              failStatusBuilder
                .andFilter(
                  'term',
                  'background_status',
                  BackgroundStatus.Consider,
                )
                .andFilter('term', 'background_adjudication', 'declined'),
            ),
        );
      } else if (backgroundCheckStatus === BackgroundStatus.Pending) {
        if (isAutomatedBackgroundCheckEnabled) {
          builder.andFilter(
            'term',
            'background_status',
            BackgroundStatus.Pending,
          );
        } else {
          builder.notFilter('term', 'pro_status', BackgroundStatus.Clear);
        }
      } else if (backgroundCheckStatus === BackgroundStatus.Missing) {
        builder
          .notFilter('exists', 'background_status')
          .notFilter('exists', 'background_adjudication');
      } else {
        builder.andFilter('term', 'background_status', backgroundCheckStatus);
      }
    }

    // Provider identity verification status filter.
    if (identityVerification) {
      if (identityVerification === IDENTITY_VERIFICATION_STATUS.cleared) {
        builder.andFilter('range', 'identity_confidence', {
          gte: IDENTITY_CONFIDENCE_PASSING_SCORE,
        });
      } else if (
        identityVerification === IDENTITY_VERIFICATION_STATUS.pending
      ) {
        builder.andFilter('bool', (statusBuilder) =>
          statusBuilder
            .orFilter('term', 'identity_confidence', IDENTITY_MANUALLY_DECLINED)
            .orFilter('bool', (notFailBuilder) =>
              notFailBuilder
                .andFilter('range', 'identity_fail_count', { lt: 3 })
                .andFilter('range', 'identity_confidence', {
                  lt: IDENTITY_CONFIDENCE_PASSING_SCORE,
                }),
            ),
        );
      } else if (identityVerification === IDENTITY_VERIFICATION_STATUS.failed) {
        builder.andFilter('range', 'identity_fail_count', { gte: 3 });
        builder.andFilter('range', 'identity_confidence', {
          lt: IDENTITY_CONFIDENCE_PASSING_SCORE,
        });
      }
    }

    // Provider insurance status filter.
    if (insuranceStatus) {
      if (insuranceStatus === INSURANCE_STATUS.cleared) {
        builder.andFilter('bool', (clearedBuilder) =>
          clearedBuilder
            .orFilter('term', 'insurance_status', insuranceStatus)
            .orFilter('term', 'insured', 1)
            .orFilter('term', 'insurance_submitted_coterie_tos', 1),
        );
      } else {
        builder
          .andFilter('term', 'insured', 0)
          .andFilter('term', 'insurance_submitted_coterie_tos', 0);
        if (insuranceStatus === INSURANCE_STATUS.missing) {
          builder.notFilter('exists', 'insurance_status');
        } else {
          builder.andFilter('term', 'insurance_status', insuranceStatus);
        }
      }
    }

    // Provider insurance status filter with consideration of grace override date.
    if (insuranceStatusWithGracePeriod) {
      const now = moment().format(SEARCH_QUERY_DATE_FORMAT);
      if (insuranceStatusWithGracePeriod === INSURANCE_STATUS.cleared) {
        builder.andFilter('bool', (clearedBuilder) =>
          clearedBuilder
            .orFilter(
              'term',
              'insurance_status',
              insuranceStatusWithGracePeriod,
            )
            .orFilter('term', 'insured', 1)
            .orFilter('term', 'insurance_submitted_coterie_tos', 1)
            .orFilter('range', 'insurance_grace_override', { gt: now }),
        );
      } else {
        builder
          .andFilter('term', 'insured', 1)
          .andFilter('term', 'insurance_submitted_coterie_tos', 0)
          .andFilter('bool', (graceBuilder) =>
            graceBuilder
              .orFilter('range', 'insurance_grace_override', { lte: now })
              .orFilter('bool', (nullGraceBuilder) =>
                nullGraceBuilder.notFilter(
                  'exists',
                  'insurance_grace_override',
                ),
              ),
          );

        if (insuranceStatusWithGracePeriod === INSURANCE_STATUS.missing) {
          builder.notFilter('exists', 'insurance_status');
        } else {
          builder.andFilter(
            'term',
            'insurance_status',
            insuranceStatusWithGracePeriod,
          );
        }
      }
    }
  }

  return builder.build();
};

export default buildUsersQuery;
