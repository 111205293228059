export const IDENTITY_CONFIDENCE_PASSING_SCORE = 90;
export const IDENTITY_MANUALLY_CLEARED = 101;
export const IDENTITY_MANUALLY_DECLINED = -1;

export default function getIdentityStatus({ confidence, fail_count }) {
  if (+confidence === IDENTITY_MANUALLY_DECLINED) {
    return 'pending';
  }
  if (+confidence >= IDENTITY_CONFIDENCE_PASSING_SCORE) {
    return 'cleared';
  }
  if (
    +fail_count >= 3 &&
    (!confidence || +confidence < IDENTITY_CONFIDENCE_PASSING_SCORE)
  ) {
    return 'failed';
  }
  return 'pending';
}
