import { CompanyTasksFiltersState } from '../reducers/companyTasksFiltersReducer';

export const UPDATE_COMPANY_TASKS_FILTERS = 'UPDATE_COMPANY_TASKS_FILTERS';
export const CLEAR_COMPANY_TASKS_FILTERS = 'CLEAR_COMPANY_TASKS_FILTERS';

interface ClearCompanyTasksFiltersAction {
  type: typeof CLEAR_COMPANY_TASKS_FILTERS;
}

interface UpdateCompanyTasksFiltersAction {
  type: typeof UPDATE_COMPANY_TASKS_FILTERS;
  updated: Partial<CompanyTasksFiltersState>;
}

export type CompanyTasksFiltersActionTypes =
  | ClearCompanyTasksFiltersAction
  | UpdateCompanyTasksFiltersAction;
