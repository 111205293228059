import {
  CLEAR_CLAIMS_FILTERS,
  ClaimsFiltersActionTypes,
  UPDATE_CLAIMS_FILTERS,
} from '../constants';

export type ClaimsAssigned = 'Assigned' | 'Unassigned' | null;

export enum ClaimsReferralType {
  GENERAL = 'General',
  EMERGENCY = 'Emergency',
  DIRECT_REPAIR = 'Direct Repair',
  EMERGENCY_REPAIR = 'Emergency Repair',
  POST_ESTIMATE_REFERRAL = 'Post Estimate Referral',
  CWS = 'Closed Without Settlement',
}

// TO DO: Find a new way to pull statuses

export enum ClaimAssignmentStatus {
  ASSIGNMENT_RECEIVED = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  CONTACT_ATTEMPTED = 4,
  INSURED_CONTACTED = 5,
  ESTIMATE_SCHEDULED = 6,
  ESTIMATE_PERFORMED = 7,
  ESTIMATE_REVIEW = 8,
  ESTIMATE_APPROVED = 17,
  ESTIMATE_REJECTED = 18,
  JOB_SCHEDULED = 12,
  JOB_STARTED = 13,
  JOB_COMPLETED = 14,
  ASSIGNMENT_CANCELLED = 15,
  ASSIGNMENT_COMPLETED = 16,
  DELETED_BY_PROVIDER = 19,
}

export type ClaimsFiltersState = {
  assigned: ClaimsAssigned;
  assigneeIds: string[] | null;
  assigneeNames: string[] | null;
  buckets: string[] | null;
  insuranceCompanyId: string | null;
  insuranceCompanyName: string | null;
  createdDateStart: string | null;
  createdDateEnd: string | null;
  following: boolean;
  lastActivityDateStart: string | null;
  lastActivityDateEnd: string | null;
  referralTypes: string[] | null;
  sort: {
    orderBy: string;
    sortOrder: 'ASC' | 'DESC';
  };
  status: string[] | null;
  claimNumber: string;
  adjusterId: string | null;
  adjusterName: string | null;
};

export const initialState: ClaimsFiltersState = {
  assigned: null,
  assigneeIds: null,
  assigneeNames: null,
  buckets: null,
  insuranceCompanyName: null,
  insuranceCompanyId: null,
  createdDateStart: null,
  createdDateEnd: null,
  following: false,
  lastActivityDateStart: null,
  lastActivityDateEnd: null,
  referralTypes: null,
  sort: {
    orderBy: 'createdAt',
    sortOrder: 'DESC',
  },
  status: null,
  claimNumber: '',
  adjusterId: null,
  adjusterName: null,
} as const;

// Keys for filtering
export const FILTER_KEYS = [
  'assigned',
  'assigneeIds',
  'buckets',
  'insuranceCompanyName',
  'createdDateStart',
  'createdDateEnd',
  'following',
  'lastActivityDateStart',
  'lastActivityDateEnd',
  'referralTypes',
  'status',
  'claimNumber',
  'adjusterId',
] as const;

const claimsFiltersReducer = (
  state: ClaimsFiltersState = initialState,
  action: ClaimsFiltersActionTypes,
): ClaimsFiltersState => {
  switch (action.type) {
    case CLEAR_CLAIMS_FILTERS:
      return {
        ...state,
        assigned: null,
        assigneeIds: null,
        assigneeNames: null,
        buckets: null,
        insuranceCompanyName: null,
        insuranceCompanyId: null,
        createdDateStart: null,
        createdDateEnd: null,
        following: false,
        lastActivityDateStart: null,
        lastActivityDateEnd: null,
        referralTypes: null,
        status: null,
        claimNumber: '',
        adjusterId: null,
        adjusterName: null,
      };
    case UPDATE_CLAIMS_FILTERS:
      return { ...state, ...action.updated };
    default:
      return state;
  }
};

export default claimsFiltersReducer;
