import React from 'react';

import {
  ADD_TOAST,
  CLEAR_ALL_TOASTS,
  REMOVE_TOAST,
  ToastsActionTypes,
  ToastType,
} from '../constants';

export type ToastFn = (
  message: string,
  toastType?: ToastType,
  element?: React.FC | JSX.Element,
  hideClose?: boolean,
) => ToastsActionTypes;

export type ClearAllToastsFn = () => ToastsActionTypes;

export const addToast: ToastFn = (
  message: string,
  toastType: ToastType = ToastType.Standard,
  element?: JSX.Element,
  hideClose?: boolean,
): ToastsActionTypes => ({
  type: ADD_TOAST,
  message,
  toastType,
  element,
  hideClose,
});

export const removeToast = (index: number): ToastsActionTypes => ({
  type: REMOVE_TOAST,
  index,
});

export const clearAllToasts = (): ToastsActionTypes => ({
  type: CLEAR_ALL_TOASTS,
});
