import { FiltersState } from '../reducers/filtersReducer';

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const RESET_SUB_BUCKETS = 'RESET_SUB_BUCKETS';
export const UPDATE_FILTERS = 'UPDATE FILTERS';

interface ClearFiltersAction {
  type: typeof CLEAR_FILTERS;
}

interface ResetSubBucketsAction {
  type: typeof RESET_SUB_BUCKETS;
}

interface UpdateFiltersAction {
  type: typeof UPDATE_FILTERS;
  updated: Partial<FiltersState>;
}

export type FiltersActionTypes =
  | ClearFiltersAction
  | ResetSubBucketsAction
  | UpdateFiltersAction;
