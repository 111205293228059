const initialState = {
  error: undefined,
  isLoading: false,
  results: [],
};

const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REGIONS_FETCH_REQUEST':
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case 'REGIONS_FETCH_UPDATED':
      return {
        ...state,
        error: null,
        isLoading: false,
        results: action.results,
      };
    case 'REGIONS_FETCH_FAILURE':
      return {
        ...state,
        error: action.error,
        isLoading: false,
        results: [],
      };
    default:
      return state;
  }
};

export default regionsReducer;
