import { defaultsDeep, valuesIn } from 'lodash';

import { ALL_MARKETS } from '../../../constants/markets';

import {
  SEARCH_DEFAULT_DECAY_PARAMS,
  SEARCH_DEFAULT_OPTION,
} from '../../../constants/search';
import createQueryBuilder from '../createQueryBuilder';

export const REFERRAL_SEARCH_FIELD = Object.freeze({
  code: 'code',
  recFirstName: 'rec_first_name',
  recLastName: 'rec_last_name',
  refFirstName: 'ref_first_name',
  refLastName: 'ref_last_name',
});

export const ALL_REFERRAL_SEARCH_FIELDS = Object.freeze(
  valuesIn(REFERRAL_SEARCH_FIELD),
);

export const buildReferralsQuery = (query, option) => {
  const {
    fields = ALL_REFERRAL_SEARCH_FIELDS,
    filter: { marketId, regionId },
  } = defaultsDeep(option, SEARCH_DEFAULT_OPTION);

  const builder = createQueryBuilder(option);

  if (query && fields.length) {
    // Define the minimum score.
    builder.rawOption('min_score', 0.01);

    if (fields.includes(REFERRAL_SEARCH_FIELD.code)) {
      // Exclude matches on `code` from function score.
      builder
        .orQuery('match', REFERRAL_SEARCH_FIELD.code, query)
        .orQuery('wildcard', REFERRAL_SEARCH_FIELD.code, {
          value: `*${query}*`,
        });
    }

    builder.orQuery(
      'function_score',
      {
        exp: {
          created_at: SEARCH_DEFAULT_DECAY_PARAMS,
        },
      },
      (agg) => {
        if (fields.includes(REFERRAL_SEARCH_FIELD.recFirstName)) {
          agg.orQuery('match', REFERRAL_SEARCH_FIELD.recFirstName, query);
        }
        if (fields.includes(REFERRAL_SEARCH_FIELD.recLastName)) {
          agg.orQuery('match', REFERRAL_SEARCH_FIELD.recLastName, query);

          if (fields.includes(REFERRAL_SEARCH_FIELD.recFirstName)) {
            agg.orQuery('multi_match', {
              query,
              fields: [
                REFERRAL_SEARCH_FIELD.recFirstName,
                REFERRAL_SEARCH_FIELD.recLastName,
              ],
              type: 'cross_fields',
            });
            agg.orQuery('multi_match', {
              query,
              fields: [
                `${REFERRAL_SEARCH_FIELD.recFirstName}.joined`,
                `${REFERRAL_SEARCH_FIELD.recLastName}.joined`,
              ],
              type: 'cross_fields',
            });
          }
        }
        if (fields.includes(REFERRAL_SEARCH_FIELD.refFirstName)) {
          agg.orQuery('match', REFERRAL_SEARCH_FIELD.refFirstName, query);
        }
        if (fields.includes(REFERRAL_SEARCH_FIELD.refLastName)) {
          agg.orQuery('match', REFERRAL_SEARCH_FIELD.refLastName, query);

          if (fields.includes(REFERRAL_SEARCH_FIELD.refFirstName)) {
            agg.orQuery('multi_match', {
              query,
              fields: [
                REFERRAL_SEARCH_FIELD.refFirstName,
                REFERRAL_SEARCH_FIELD.refLastName,
              ],
              type: 'cross_fields',
            });
            agg.orQuery('multi_match', {
              query,
              fields: [
                `${REFERRAL_SEARCH_FIELD.refFirstName}.joined`,
                `${REFERRAL_SEARCH_FIELD.refLastName}.joined`,
              ],
              type: 'cross_fields',
            });
          }
        }

        return agg;
      },
    );
  }

  // Market filter.
  if (marketId != null && marketId !== ALL_MARKETS.id) {
    builder.andFilter('term', 'market_id', marketId);
  }

  // Region filter.
  if (regionId != null) {
    builder.andFilter('term', 'region_id', regionId);
  }

  return builder.build();
};

export default buildReferralsQuery;
