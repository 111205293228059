
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Connection": [
      "OrganizationCompanyConnection"
    ],
    "Node": [
      "Asset",
      "Company",
      "CompanyV2",
      "Estimate",
      "EstimateAttachment",
      "EstimateLineItem",
      "EstimateRevision",
      "JobEstimate",
      "JobEstimateItem",
      "JobEstimateItemMaterial",
      "OrganizationCompany"
    ],
    "UserBase": [
      "ContactInfo"
    ],
    "WorkbenchItem": [
      "ConsumerRequest",
      "Job",
      "Workorder"
    ]
  }
};
      export default result;
    