/**
 * An extension of `Error` specifically designed for SWR. Includes
 * `{ status: number }` for HTTP statuses.
 *
 * Note the various allowed types for service error responses.
 */

interface ErrorMessageString {
  message: string;
}

interface ErrorMessage {
  error: ErrorMessageString | string;
}

function instanceOfErrorMessage(e: any): e is ErrorMessage {
  return 'error' in e;
}

function instanceOfString(e: any): e is string {
  return e;
}

function instanceOfMessage(e: any): e is ErrorMessageString {
  return 'message' in e;
}

export class SWRError extends Error {
  constructor(errorData: any, status: number) {
    // I've noticed sometimes an 'error' property is returned, but other times
    // the error is directly serialized
    let message = 'An unknown error occurred';

    if (instanceOfErrorMessage(errorData)) {
      if (instanceOfString(errorData.error)) {
        message = errorData.error;
      } else if (instanceOfMessage(errorData.error)) {
        message = errorData.error?.message;
      }
    } else if (instanceOfString(errorData)) {
      message = errorData;
    }

    super(message);
    this.status = status;
  }

  status: number;
}

export default {};
