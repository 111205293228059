import axios from 'axios';

export const actionTypes = {
  CATCH_TEAM_MEMBERS_ERROR: 'CATCH_TEAM_MEMBERS_ERROR',
  RECEIVE_TEAM_MEMBERS: 'RECEIVE_TEAM_MEMBERS',
  CLEAR_TEAM_MEMBERS: 'CLEAR_TEAM_MEMBERS',
  REQUEST_TEAM_MEMBERS: 'REQUEST_TEAM_MEMBERS',
};

export const catchTeamMembersError = ({ error, id }) => ({
  type: actionTypes.CATCH_TEAM_MEMBERS_ERROR,
  error,
  id,
});

export const receiveTeamMembers = (team_members) => ({
  type: actionTypes.RECEIVE_TEAM_MEMBERS,
  team_members,
});

export const clearTeamMembers = (id) => ({
  type: actionTypes.CLEAR_TEAM_MEMBERS,
  id,
});

export const requestTeamMembers = (id) => ({
  type: actionTypes.REQUEST_TEAM_MEMBERS,
  id,
});

export const fetchTeamMembers = (id) => async (dispatch) => {
  dispatch(requestTeamMembers());

  try {
    const teamData = await axios({
      method: 'GET',
      url: `/v2/organizations/${id}/members`,
    });
    const results = teamData.data.data.results; // eslint-disable-line prefer-destructuring

    return dispatch(receiveTeamMembers(results));
  } catch (e) {
    return dispatch(catchTeamMembersError(e));
  }
};

const shouldFetchTeamMembers = (state) => {
  try {
    return !state.team_members.isLoading;
  } catch (e) {
    return true;
  }
};

export const fetchTeamMembersIfNeeded = (id) => async (dispatch, getState) => {
  if (shouldFetchTeamMembers(getState())) {
    return dispatch(fetchTeamMembers(id));
  }
  return Promise.resolve();
};
