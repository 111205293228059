export const PROVIDER_FILTERS_UPDATE = 'PROVIDER_FILTERS_UPDATE';
export const CLEAR_PROVIDER_FILTERS = 'CLEAR_PROVIDER_FILTERS';

export function updateProviderFilters(filters) {
  return {
    type: 'PROVIDER_FILTERS_UPDATE',
    filters,
  };
}

export function clearProviderFilters() {
  return {
    type: CLEAR_PROVIDER_FILTERS,
  };
}
