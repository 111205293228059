export const BREAKPOINT_VALUE = Object.freeze({
  xs: 321,
  sm: 577,
  md: 769,
  lg: 993,
  xl: 1201,
  xxl: 1441,
  xxxl: 1920,
});

export const COLOR = {
  brandBlue: '#3C97D3',
  brandNavy: '#0F385C',
  brandRed: '#E74C3C',
  brandYellow: '#ECC11C',
  black: '#0D131A',
  darkGray: '#636466',
  lightGray: '#DCDDDE',
  softGray: '#F2F2F3',
  offWhite: '#FAFAFA',
  white: '#FFFFFF',
  blue: '#0059A0',
  blueHover: '#003866',
  blueStatus: '#008CFF',
  green: '#4F7F48',
  greenHover: '#2E6127',
  greenStatus: '#70C246',
  red: '#9A3433',
  redHover: '#662222',
  orangeStatus: '#F58616',
  yellowStatus: '#FFD11E',
  orange: '#bd6a17',
  overlay: 'rgb(13, 19, 26, .8)',
  // old colors
  blue0: '#EEF7FF',
  blue5: '#D2E8FE',
  blue10: '#B7DAFD',
  blue20: '#80BEF8',
  blue30: '#51A4F2',
  blue40: '#2A90E8',
  blue50: '#0E7FDC',
  blue60: '#0075CC',
  blue70: '#006FB7',
  blue80: '#00659F',
  blue90: '#005783',
  blue100: '#004766',
  green0: '#E9FFE6',
  green5: '#D3F5CD',
  green10: '#B4EBAA',
  green20: '#7ED76D',
  green30: '#54C33E',
  green40: '#36AE1B',
  green50: '#239A05',
  green60: '#1F8600',
  green70: '#207200',
  green80: '#205E00',
  green90: '#1F4A00',
  green100: '#1B3600',
  neutral0: '#FFFFFF',
  neutral5: '#F6F7F8',
  neutral10: '#E5EBF0',
  neutral20: '#C3C8CD',
  neutral30: '#B2BBC2',
  neutral40: '#93A2B0',
  neutral50: '#6D8190',
  neutral60: '#41596D',
  neutral70: '#2A3F50',
  neutral80: '#1F2F3C',
  neutral90: '#17232D',
  neutral100: '#000000',
  orange0: '#FFF6F2',
  orange5: '#FDE2D5',
  orange10: '#FCCEB8',
  orange20: '#FAAA84',
  orange30: '#F58552',
  orange40: '#EC6827',
  orange50: '#E24B00',
  orange60: '#D73D00',
  orange70: '#C73500',
  orange80: '#B32D00',
  orange90: '#9D2500',
  orange100: '#871C00',
  purple0: '#FBF2FF',
  purple5: '#F3DCFC',
  purple10: '#EAC6F9',
  purple20: '#D99DF2',
  purple30: '#CA7BEC',
  purple40: '#BB5EE6',
  purple50: '#AC49DF',
  purple60: '#9D39D9',
  purple70: '#8C2DD2',
  purple80: '#7A24CC',
  purple90: '#661FC6',
  purple100: '#4717A6',
  red0: '#FFF2F2',
  red5: '#FED6D7',
  red10: '#FDBBBC',
  red20: '#FA8688',
  red30: '#F5575B',
  red40: '#ED3036',
  red50: '#E4131B',
  red60: '#D7000A',
  red70: '#C7000C',
  red80: '#B3000D',
  red90: '#9E000E',
  red100: '#87000E',
  teal0: '#DCF6F7',
  teal5: '#B8ECEE',
  teal10: '#95E2E4',
  teal20: '#59CED1',
  teal30: '#2ABCBE',
  teal40: '#09ABAB',
  teal50: '#009894',
  teal60: '#00857D',
  teal70: '#007165',
  teal80: '#005E4E',
  teal90: '#004B38',
  teal100: '#003824',
  yellow0: '#FFF8C9',
  yellow5: '#FEF4B2',
  yellow10: '#FDF09C',
  yellow20: '#FBE770',
  yellow30: '#F9DE4A',
  yellow40: '#F7D52B',
  yellow50: '#F5CB13',
  yellow60: '#F3BF00',
  yellow70: '#F1B200',
  yellow80: '#EFA200',
  yellow90: '#ED8E00',
  yellow100: '#EB7900',
  textGray: '#647887',
} as const;

export const Z_INDEX = Object.freeze({
  // bootstrap navbar           1000
  // bootstrap dropdown         1000
  // bootstrap fixed            1030
  // bootstrap modal-background 1040
  // bootstrap modal            1050
  // bootstrap popover          1060
  // bootstrap tooltip          1070

  dropdownMenu: 2000,
  calendar: 1,
  actionList: 2,
  mainHeader: 5,
  carousel: 2001,
  mobileMenu: 2002,
  toast: 2003,
});

export const SPACING = {
  xxs: 4,
  xs: 8,
  sm: 16,
  md: 24,
  lg: 48,
  xl: 96,
} as const;

const baseFontSize = 16;
const rem = (pixels: number): string => `${pixels / baseFontSize}rem`;

export const FONT_SIZE = {
  s8: rem(8),
  s11: rem(11),
  s13: rem(13),
  s16: rem(16),
  s18: rem(18),
  s24: rem(24),
  s32: rem(32),
  s40: rem(40),
  s64: rem(64),
} as const;

export const LINE_HEIGHT = {
  s8: rem(12),
  s11: rem(16),
  s13: rem(20),
  s16: rem(24),
  s18: rem(27),
  s24: rem(36),
  s32: rem(48),
  s40: rem(48),
  s64: rem(96),
} as const;

export const FONT_WEIGHT = {
  heading: 800,
  bold: 700,
  semi: 600,
  regular: 400,
} as const;

export const LETTER_SPACING = {
  xxs: rem(0.32),
  xs: rem(0.38),
  sm: rem(0.5),
  md: rem(1.11),
  lg: rem(1.5),
  xl: rem(1.6),
} as const;

export const TRANSITION = {
  duration: '150ms',
  timingFunction: 'linear',
} as const;

export const SHADOW = Object.freeze({
  dp2: '0 3px 1px -2px rgba(0,0,0,0.20), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
  dp6: '0 3px 5px -1px rgba(0,0,0,0.20), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12)',
  dp12: '0 7px 8px -4px rgba(0,0,0,0.20), 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12)',
  dp24: '0 11px 15px -7px rgba(0,0,0,0.20), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12)',
});

export const MOBILE_HEADER_HEIGHT = 64;

export const MOBILE_HEADER_HEIGHT_INTERNAL = 120;
