import classnames from 'classnames';
import css from 'styled-jsx/css';
import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

import Heading from './Heading';
import Icon from './Icon';
import Image from './Image';
import Text from './Text';

import { COLOR, SPACING, TRANSITION, BREAKPOINT_VALUE } from './theme';

try {
  // Next js uses #__next as app element
  ReactModal.setAppElement('#__next');
} catch (errorOne) {
  try {
    // Storybook uses #root as app element
    ReactModal.setAppElement('#root');
  } catch (errorTwo) {
    // Do nothing; nothing will break, but a11y won't be 100%
  }
}

const { className: overlayClassName, styles: overlayStyles } = css.resolve`
  div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(31, 47, 60, 0.9);
    z-index: 10;
  }
`;

const { className: modalClassName, styles: modalStyles } = css.resolve`
  div {
    background: ${COLOR.offWhite};
    border-radius: 4px;
    left: 50%;
    max-height: 90vh;
    width: 90%;
    outline: none;
    overflow: auto;
    padding: 0;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: ${BREAKPOINT_VALUE.xs}px) {
    div {
      width: 75%;
    }
  }

  @media (min-width: ${BREAKPOINT_VALUE.sm}px) {
    div {
      width: 55%;
    }
  }

  @media (min-width: ${BREAKPOINT_VALUE.md}px) {
    div {
      width: 40%;
    }
  }
`;

const { className: titleClassName, styles: titleStyles } = css.resolve`
  h5 {
    padding: 0;
  }
`;

const { className: subtitleClassName, styles: subtitleStyles } = css.resolve`
  p {
    padding: 0;
  }
`;

const { className: contentClassName, styles: contentStyles } = css.resolve`
  div {
    padding: 0 32px;
  }
`;

type ModalSize = 'small' | 'medium';

type Props = PropsWithChildren<{
  banner?: React.ReactNode;
  className?: string;
  headerIcon?: React.ReactNode;
  isOpen?: boolean;
  onRequestClose: () => void | Promise<void>;
  size?: ModalSize;
  subTitle?: string;
  title?: string;
}>;

const Modal: React.FC<Props> = ({
  banner,
  children,
  className,
  headerIcon,
  isOpen,
  onRequestClose,
  size,
  subTitle,
  title,
}) => {
  const bannerOnly: boolean = !children && !title && !subTitle;
  const bannerStyles: React.CSSProperties = bannerOnly
    ? { maxWidth: '100%', maxHeight: '100%', margin: 'auto' }
    : {
        height: 191,
        width: '100%',
      };

  let renderedBanner: React.ReactNode = null;
  if (banner && typeof banner === 'string') {
    renderedBanner = (
      <figure
        className="banner"
        style={{
          marginBottom: bannerOnly ? 0 : 'inherit',
        }}
      >
        <Image alt="" src={banner} style={bannerStyles} />
        <style jsx>
          {`
            .banner {
              background-color: ${COLOR.offWhite};
            }
          `}
        </style>
      </figure>
    );
  } else if (banner) {
    renderedBanner = banner;
  }

  let renderedIcon: React.ReactNode = null;
  if (!renderedBanner) {
    if (typeof headerIcon === 'string') {
      renderedIcon = (
        <div className="header-icon">
          <Icon icon={headerIcon} size={48} />
          <style jsx>
            {`
              .header-icon {
                align-items: center;
                background-color: ${COLOR.softGray};
                border-radius: ${SPACING.xl}px;
                display: flex;
                justify-content: center;
                margin: 0 auto ${SPACING.sm}px;
                height: ${SPACING.xl}px;
                overflow: hidden;
                padding: 0;
                width: ${SPACING.xl}px;
              }
            `}
          </style>
        </div>
      );
    } else if (headerIcon) {
      renderedIcon = headerIcon;
    }
  }

  const headingIsCentered = !!renderedIcon;

  return (
    <ReactModal
      className={classnames(className, modalClassName)}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={overlayClassName}
      style={
        bannerOnly ? { content: { width: 'fit-content', maxWidth: '90%' } } : {}
      }
    >
      <div
        className="inner"
        style={{
          padding: 0,
          paddingBottom: bannerOnly ? 0 : SPACING.md,
        }}
      >
        <header
          style={{
            background:
              size === 'medium' && !renderedIcon && !renderedBanner
                ? COLOR.neutral5
                : 'none',
            textAlign: headingIsCentered ? 'center' : 'left',
            paddingTop: renderedBanner ? 0 : SPACING.md,
            paddingBottom: bannerOnly ? 0 : SPACING.sm,
            paddingLeft: renderedBanner ? 0 : 32,
            paddingRight: renderedBanner ? 0 : 32,
            marginBottom:
              size === 'medium' && !renderedIcon && !renderedBanner
                ? SPACING.sm
                : 0,
          }}
        >
          {renderedBanner || renderedIcon}
          <div
            style={{
              paddingTop: renderedBanner && !bannerOnly ? SPACING.sm : 0,
              paddingBottom: 0,
              paddingLeft: renderedBanner && !bannerOnly ? 32 : 0,
              paddingRight: renderedBanner && !bannerOnly ? 32 : 0,
            }}
          >
            {title && (
              <Heading className={titleClassName} variant="h5">
                {title}
              </Heading>
            )}
            {subTitle && (
              <Text className={subtitleClassName} size="large">
                {subTitle}
              </Text>
            )}
          </div>
        </header>
        {children && (
          <Text className={contentClassName} element="div">
            {children}
          </Text>
        )}
        <button
          className="exit"
          type="button"
          onClick={() => {
            if (typeof onRequestClose === 'function') {
              onRequestClose();
            }
          }}
        >
          <Icon icon="close" style={{ fontSize: 14 }} />
        </button>
      </div>
      <style jsx>
        {`
          .inner {
            padding: 0 0 ${SPACING.md}px 0;
            position: relative;
          }

          .exit {
            align-items: center;
            background: transparent;
            border: none;
            color: ${COLOR.darkGray};
            cursor: pointer;
            display: flex;
            height: ${SPACING.md}px;
            justify-content: center;
            margin: 0;
            padding: 0;
            position: absolute;
            top: ${SPACING.xs}px;
            right: ${SPACING.xs}px;
            width: ${SPACING.md}px;
            transition: color ${TRANSITION.duration}
              ${TRANSITION.timingFunction};
          }

          .exit:hover,
          .exit:focus {
            color: ${COLOR.blue};
          }
        `}
      </style>
      {modalStyles}
      {overlayStyles}
      {titleStyles}
      {subtitleStyles}
      {contentStyles}
    </ReactModal>
  );
};

Modal.defaultProps = {
  banner: undefined,
  className: undefined,
  headerIcon: undefined,
  isOpen: false,
  size: 'small',
  subTitle: undefined,
  title: undefined,
};

export default Modal;
