import { datadogRum } from '@datadog/browser-rum';
import clientConstants from './src/config';

export function initializeDatadog() {
  if (clientConstants.DATADOG_ENABLED) {
    if (
      !clientConstants.DATADOG_APPLICATION_ID ||
      !clientConstants.DATADOG_CLIENT_TOKEN
    ) {
      throw new Error(
        'Failed to initialize Datadog RUM, missing application id and/or client token',
      );
    }

    datadogRum.init({
      applicationId: clientConstants.DATADOG_APPLICATION_ID,
      clientToken: clientConstants.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      env: clientConstants.NODE_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      traceSampleRate: 100,
      service: clientConstants.DATADOG_SERVICE,
      datacenter: 'us',
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [(url) => url.includes('homee.com')],
    });

    datadogRum.startSessionReplayRecording();
  }
}
