import React from 'react';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const CLEAR_ALL_TOASTS = 'CLEAR_ALL_TOASTS';

export enum ToastType {
  Standard,
  Error,
  Warn,
}

interface AddToastAction {
  type: typeof ADD_TOAST;
  message: string;
  toastType: ToastType;
  element?: JSX.Element;
  hideClose?: boolean;
}

interface RemoveToastAction {
  type: typeof REMOVE_TOAST;
  index: number;
}

interface ClearAllToastsAction {
  type: typeof CLEAR_ALL_TOASTS;
}

export type ToastsActionTypes =
  | AddToastAction
  | RemoveToastAction
  | ClearAllToastsAction;
