import { valuesIn } from 'lodash';

export const NETWORK_TYPE = Object.freeze({
  inhouse: 1,
  preferred: 2,
  open: 3,
});

export const ALL_NETWORK_TYPES = Object.freeze(valuesIn(NETWORK_TYPE));

export const NETWORK_TYPE_LABEL = Object.freeze({
  [NETWORK_TYPE.inhouse]: 'In-House',
  [NETWORK_TYPE.preferred]: 'Preferred',
  [NETWORK_TYPE.open]: 'Open',
});

export default NETWORK_TYPE;
