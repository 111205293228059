/* eslint-disable no-param-reassign */
import moment from 'moment';
import produce from 'immer';
import { actionTypes } from '../actions/organizationActions';

const {
  CATCH_ORGANIZATION_ERROR,
  RECEIVE_ORGANIZATION,
  REQUEST_ORGANIZATION,
  CLEAR_ORGANIZATION,
} = actionTypes;

const defaultState = {
  data: [],
  errors: undefined,
  finishedLoadAt: undefined,
  isLoading: false,
  startedLoadAt: undefined,
};

const organizationReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case CATCH_ORGANIZATION_ERROR:
        draft.errors = Array.isArray(action.errors)
          ? [...draft.errors, action.error.message || action.error]
          : [action.error.message || action.error];
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case RECEIVE_ORGANIZATION:
        draft.data = action.organization;
        draft.errors = undefined;
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case CLEAR_ORGANIZATION:
        draft.data = [];
        draft.errors = undefined;
        draft.finishedLoadAt = moment().format();
        draft.isLoading = false;
        break;
      case REQUEST_ORGANIZATION:
        draft.errors = undefined;
        draft.finishedLoadAt = undefined;
        draft.isLoading = true;
        draft.startedLoadAt = moment().format();
    }
  });

export default organizationReducer;
