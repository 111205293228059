// This is a temporary file!
// Once these enums are fixed on the backend, we can remove these overrides

export enum GetJobsOrderBy {
  Created_At = 'created_at',
  CreatedAt = 'createdAt',
  Finalized_At = 'finalized_at',
  FinalizedAt = 'finalizedAt',
  AppointmentDate = 'appointmentDate',
  Appointment_Date = 'appointment_date',
}

export enum GetWorkbenchListOrderBy {
  Created_At = 'created_at',
  CreatedAt = 'createdAt',
  Finalized_At = 'finalized_at',
  FinalizedAt = 'finalizedAt',
  AppointmentDate = 'appointmentDate',
  Appointment_Date = 'appointment_date',
}
