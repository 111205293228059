import React from 'react';

import paths from './paths.json';

export type Icons = keyof typeof paths;

export type Props = {
  icon: string;
  size?: number | string;
  tooltip?: React.ReactElement<any>;
} & React.SVGProps<SVGSVGElement>;

export const Icon: React.FunctionComponent<Props> = ({
  icon,
  size,
  tooltip,
  ...svgProps
}: Props) => (
  <React.Fragment>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={size}
      width={size}
      fill="currentColor"
      data-for={tooltip && tooltip.props.id}
      data-tip={tooltip ? 'true' : undefined}
      viewBox="0 0 24 24"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...svgProps}
    >
      <path d={paths[icon]} />
    </svg>
    {tooltip}
  </React.Fragment>
);

Icon.defaultProps = {
  size: '1.5em',
};

export default Icon;
