import getInputAccentColor from '../utils/getInputAccentColor';
import { COLOR, Z_INDEX } from '../theme';

const styles = {
  container: (provided) => ({
    ...provided,
    minHeight: 40,

    '&:focus': {
      outline: 'none',
    },
  }),
  control: (provided, state) => {
    const color = getInputAccentColor({
      disabled: state.isDisabled,
      isFocused: state.isFocused,
      isInvalid: state.selectProps.isInvalid,
    });

    return {
      ...provided,
      backgroundColor: state.isDisabled ? '#fbfcfc' : COLOR.white,
      borderRadius: 0,
      border: 'none',
      borderBottom: `2px solid ${color}`,
      boxShadow: 'none',
      minHeight: 40,
      padding: state.selectProps.showGlyph ? '0 0 0 32px' : '0', // make space for glyph

      '&:hover': {}, // Needed to override default hover attributes
    };
  },
  indicatorsContainer: (provided) => ({
    ...provided,
    boxShadow: 'unset',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? COLOR.neutral30 : COLOR.darkGray,
    fontSize: '1rem',
    lineHeight: '1rem',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    boxShadow: '1px 0 6px rgba(0, 0, 0, 0.3)',
    margin: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: Z_INDEX.dropdownMenu,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    boxShadow: 'unset',
    color: state.isDisabled ? COLOR.neutral30 : COLOR.darkGray,
    fontSize: '1rem',
    lineHeight: '1rem',
  }),
};

export default styles;
