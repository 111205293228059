const isServerRender = typeof window === 'undefined';
let enabled = false;

const init = (options) => {
  enabled = options.enabled;

  if (isServerRender || !enabled) {
    return;
  }

  window.intercomSettings = {
    app_id: options.app_id,
  };

  if (typeof window.Intercom === 'function') {
    window.Intercom('reattach_activator');
    window.Intercom('update', window.intercomSettings);
  } else {
    const intercomClient = (...args) => intercomClient.c(args);
    intercomClient.q = [];
    intercomClient.c = (args) => intercomClient.q.push(args);

    window.Intercom = intercomClient;
  }
};

const update = (userInfo) => {
  if (isServerRender || !enabled) {
    return;
  }

  window.Intercom('update', userInfo);
};

export default {
  init,
  update,
};
