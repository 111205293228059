import axios from 'axios';

import { SEARCH_BASE_URL, SEARCH_CATEGORY_INDEX } from '../../constants/search';
import SEARCH_INDEX_QUERY_BUILDER from '../../constants/searchIndexQueryBuilders';
import { search } from '../../services/search/useSearchQuery';

export const createRequests = (searchMap, page = 1, limit = 3) =>
  Promise.allSettled(
    Object.keys(searchMap).map((category) => {
      const { index, query } = searchMap[category];
      const from = limit * (page - 1);
      const paginatedSearchBody = query.from(from).size(limit);

      return search(index, paginatedSearchBody).then((res) => {
        const { hits, total } = res.data.hits;
        return {
          category,
          query: paginatedSearchBody,
          hits,
          total,
        };
      });
    }),
  ).then((results) =>
    results.reduce((acc, current) => {
      if (current?.value) {
        const { category, hits, total } = current.value;
        return { ...acc, [category]: { hits, total } };
      }
      return { ...acc };
    }, {}),
  );

export const createMultiRequest = (query, csrf) => {
  const data = {};
  Object.keys(query.option).forEach((category) => {
    const index = SEARCH_CATEGORY_INDEX[category];
    const builder = SEARCH_INDEX_QUERY_BUILDER[index];
    const option = query.option[category];
    data[index] = builder(query.text, option);
  });
  const headers = {
    'Content-Type': 'application/json',
  };
  if (csrf != null) {
    headers['X-CSRF-Token'] = csrf;
  }
  return axios
    .post(SEARCH_BASE_URL, { data: JSON.stringify(data) }, { headers })
    .then((response) => {
      const { error, responses } = response.data;
      if (error) {
        throw error;
      }
      return responses
        .map(({ hits: { hits, total } }, i) => {
          const category = Object.keys(query.option)[i];
          const option = query.option[category];
          return {
            category,
            hits,
            option,
            total,
          };
        })
        .reduce((previous, result) => {
          const { category, option, hits, total } = result;
          return { ...previous, [category]: { option, hits, total } };
        }, {});
    });
};

export default createRequests;
