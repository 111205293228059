// TODO move to homee-utils
export enum IdentityVerificationStatus {
  Cleared = 'cleared',
  Failed = 'failed',
  Pending = 'pending',
}

/**
 * @deprecated use enum
 */
export const IDENTITY_VERIFICATION_STATUS = {
  cleared: 'cleared',
  failed: 'failed',
  // declined: 'declined',
  pending: 'pending',
};

export const ALL_IDENTITY_VERIFICATION_STATUSES = Object.values(
  IdentityVerificationStatus,
);
