import { MyTasksFiltersState } from '../reducers/myTasksFiltersReducer';

export const CLEAR_MY_TASKS_FILTERS = 'CLEAR_MY_TASKS_FILTERS';
export const UPDATE_MY_TASKS_FILTERS = 'UPDATE_MY_TASKS_FILTERS';

interface ClearMyTasksFiltersAction {
  type: typeof CLEAR_MY_TASKS_FILTERS;
}

interface UpdateMyTasksFiltersAction {
  type: typeof UPDATE_MY_TASKS_FILTERS;
  updated: Partial<MyTasksFiltersState>;
}

export type MyTasksFiltersActionTypes =
  | ClearMyTasksFiltersAction
  | UpdateMyTasksFiltersAction;
