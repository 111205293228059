import React from 'react';

const Image: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({
  alt,
  onLoad,
  src,
  ...extraProps
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const [imgSrc, setImgSrc] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (src) {
      setHasLoaded(false);
      setImgSrc(src);
    }
  }, [src]);

  return (
    <React.Fragment>
      <img
        alt={alt}
        onLoad={(event) => {
          setHasLoaded(true);
          if (typeof onLoad === 'function') {
            onLoad(event);
          }
        }}
        src={imgSrc || undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...extraProps}
      />
      <style jsx>
        {`
          img {
            display: block;
            object-fit: cover;
            opacity: ${hasLoaded ? 1 : 0};
            padding: 0;
            transition: opacity 0.3s ease;
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default Image;
