import hoistNonReactStatic from 'hoist-non-react-statics';
import { connect } from 'react-redux';

import { SplitFeatureFlagProvider } from './FeatureFlagsProvider';

// pull user id from redux
const mapStateToProps = (state) => ({
  userId: state.user && state.user.data && state.user.data.id,
});

const ConnectedSplitFeatureFlagProvider = connect(mapStateToProps)(
  SplitFeatureFlagProvider,
);

hoistNonReactStatic(
  SplitFeatureFlagProvider,
  ConnectedSplitFeatureFlagProvider,
);

export { ConnectedSplitFeatureFlagProvider };
