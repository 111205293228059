import React from 'react';
import dynamic from 'next/dynamic';

import clientConstants from '@app/config';
import { useFlags as useSplitFlags } from './split';

// we want to dynamically get the provider to work around
// the issue that Split's react lib does not play nicely with
// Next's SSR. Split's lib is not isomorphic js
const DynamicSplitProvider = dynamic(
  () => import('./split').then((mod) => mod.ConnectedSplitFeatureFlagProvider),
  {
    ssr: false,
  },
);

const getDynamicFlagProvider = () => {
  switch (clientConstants.FLAG_PROVIDER) {
    case 'split':
      return DynamicSplitProvider;
    default:
      throw new Error(`Invalid feature flag provider provided`);
  }
};

export function FeatureFlagsProvider(props) {
  const Provider = getDynamicFlagProvider();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Provider {...props} />;
}

const getDynamicFlagHook = () => {
  switch (clientConstants.FLAG_PROVIDER) {
    case 'split':
      return useSplitFlags;
    default:
      throw new Error(`Invalid feature flag provider provided`);
  }
};

export const useFlags = (flagNames) => {
  const flagHookFn = getDynamicFlagHook();

  return flagHookFn(flagNames);
};

export const setFlag = (_flagName, _flagValue) => {
  throw new Error(
    'You cannot set flag values outside of tests! Or you maybe forgot to call jest.mock!',
  );
};
