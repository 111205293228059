import {
  FETCH_RATES_FAILURE,
  FETCH_RATES_REQUEST,
  FETCH_RATES_SUCCESS,
  RateLookupActionTypes,
} from '../constants';
import { GetRatesResponse } from '../../services/api/RatesService';

export interface RateLookupState {
  error?: Error;
  isFetching: boolean;
  results: GetRatesResponse['data'];
}

const initialState: RateLookupState = {
  isFetching: false,
  results: {},
};

const rateLookupReducer = (
  state: RateLookupState = initialState,
  action: RateLookupActionTypes,
): RateLookupState => {
  switch (action.type) {
    case FETCH_RATES_REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };
    case FETCH_RATES_SUCCESS:
      return {
        ...state,
        error: undefined,
        isFetching: false,
        results: action.payload,
      };
    case FETCH_RATES_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        results: {},
      };
    default:
      return state;
  }
};

export default rateLookupReducer;
