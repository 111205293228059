import css from 'styled-jsx/css';
import React from 'react';

import getInputAccentColor from './utils/getInputAccentColor';
import getInputTextColorName from './utils/getInputTextColorName';
import Icon from './Icon';
import InputHelperText from './InputHelperText';
import Text from './Text';
import { COLOR, SPACING } from './theme';

const { className: glyphClassName, styles: glyphStyles } = css.resolve`
  svg {
    left: 8px;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const { className: labelSpanClassName, styles: labelSpanStyles } = css.resolve`
  span {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 1px 0;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

type Props = {
  glyph?: string;
  glyphRight?: string;
  hasVisibilityToggle?: boolean;
  helperText?: string;
  imitateFocused?: boolean;
  isInvalid?: boolean;
  label?: React.ReactNode;
  hideLabel?: boolean;
  ariaLabel?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = ({
  className,
  disabled = false,
  glyph,
  glyphRight,
  hasVisibilityToggle = false,
  helperText,
  imitateFocused = false,
  isInvalid = false,
  label,
  onBlur,
  onFocus,
  required = false,
  type = 'text',
  hideLabel = false,
  ariaLabel,
  ...inputAttrs
}) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isFieldValueVisible, setIsFieldValueVisible] =
    React.useState<boolean>(false);

  const labelColor = getInputTextColorName({
    disabled,
    isFocused: isFocused || imitateFocused,
    isInvalid,
  });
  const accentColor = getInputAccentColor({
    disabled,
    isFocused: isFocused || imitateFocused,
    isInvalid,
  });

  let fieldType: string = type;
  if (hasVisibilityToggle) {
    fieldType = isFieldValueVisible ? 'text' : 'password';
  }

  return (
    <div className={className}>
      <label>
        {label && !hideLabel && (
          <Text
            className={labelSpanClassName}
            color={labelColor}
            element="span"
            size="medium"
            weight="bold"
            style={{ paddingTop: 0, paddingBottom: SPACING.xxs }}
          >
            {required && '* '}
            {label}
          </Text>
        )}
        <div>
          <input
            disabled={disabled}
            onBlur={(event) => {
              setIsFocused(false);
              if (onBlur) {
                onBlur(event);
              }
            }}
            onFocus={(event) => {
              setIsFocused(true);
              if (onFocus) {
                onFocus(event);
              }
            }}
            required={required}
            aria-required={required ? 'true' : 'false'}
            aria-label={ariaLabel && hideLabel ? ariaLabel : undefined}
            type={fieldType}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputAttrs}
          />
          {glyph && (
            <Icon
              className={glyphClassName}
              icon={glyph}
              style={{
                color: accentColor,
              }}
            />
          )}
          {glyphRight && !hasVisibilityToggle && (
            <Icon
              className={glyphClassName}
              icon={glyphRight}
              style={{
                color: COLOR.darkGray,
                left: 'unset',
                right: SPACING.xs,
              }}
            />
          )}
          {hasVisibilityToggle && (
            <button
              className="glyph-wrapper"
              type="button"
              onClick={() => {
                setIsFieldValueVisible(!isFieldValueVisible);
              }}
            >
              <Icon
                icon={isFieldValueVisible ? 'visibility' : 'visibilityOff'}
              />
            </button>
          )}
        </div>
        <style jsx>
          {`
            div {
              position: relative;
            }

            label {
              display: block;
              margin: 0;
              padding: 0;
              text-align: left;
            }

            input {
              border: none;
              border-bottom: 2px solid;
              border-radius: 0;
              display: block;
              font-size: 1rem;
              line-height: 1rem;
              margin: 0;
              min-height: 40px;
              width: 100%;
            }

            input::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+  */
              color: ${COLOR.darkGray};
              opacity: 1;
            }
            input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: ${COLOR.darkGray};
            }
            input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: ${COLOR.darkGray};
            }

            input:active,
            input:focus {
              outline: none;
            }

            .glyph-wrapper {
              border: none;
              background: none;
              color: ${COLOR.darkGray};
              padding: 0;
              margin: 0;
              line-height: 1;
              position: absolute;
              top: 0;
              right: 8px;
              height: 40px;
            }

            input::-webkit-strong-password-auto-fill-button {
              visibility: hidden;
              display: none !important;
              pointer-events: none;
              position: absolute;
              right: 0;
            }
          `}
        </style>
        <style jsx>
          {`
            input {
              background-color: ${disabled ? '#fbfcfc' : COLOR.white};
              border-bottom-color: ${accentColor};
              color: ${disabled ? COLOR.neutral30 : COLOR.black};
              padding: 0 ${glyphRight || hasVisibilityToggle ? 42 : 10}px 0
                ${glyph ? 42 : 10}px;
            }
          `}
        </style>
        {glyphStyles}
        {labelSpanStyles}
      </label>
      {helperText && (
        <InputHelperText isInvalid={isInvalid}>{helperText}</InputHelperText>
      )}
    </div>
  );
};

export default Input;
