import {
  CREATE_WORKORDER_ACTION_TYPE,
  CREATE_WORKORDER_MODAL_TAB,
} from '../constants/createWorkorderConstants';
import { WORKORDER_PRIORITY } from '../../constants/workorderPriorities';

export const initialEditsState = {
  occupied: true,
  proMustBeInMarket: true,
  residentIsConsumer: false,
  assignToCompany: false,
  limitTotal: '',
  priority: WORKORDER_PRIORITY.low,
  autoAssignToCompany: true,
};

export const initialCreateWorkorderState = {
  defaultLimit: 0,
  defaultLimitLoading: false,
  edits: initialEditsState,
  error: undefined,
  errorMessage: undefined,
  isSubmitting: false,
  tab: CREATE_WORKORDER_MODAL_TAB.customer_details,
};

const createWorkorderReducer = (
  state = initialCreateWorkorderState,
  action,
) => {
  switch (action.type) {
    case CREATE_WORKORDER_ACTION_TYPE.CREATE_WORKORDER_CATCH_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case CREATE_WORKORDER_ACTION_TYPE.CREATE_WORKORDER_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
      };
    case CREATE_WORKORDER_ACTION_TYPE.CREATE_WORKORDER_UPDATE_EDITS:
      return {
        ...state,
        edits: { ...state.edits, ...action.edits },
      };
    case CREATE_WORKORDER_ACTION_TYPE.CREATE_WORKORDER_CLEAR_EDITS:
      return {
        ...state,
        edits: initialEditsState,
        tab: initialCreateWorkorderState.tab,
      };
    case CREATE_WORKORDER_ACTION_TYPE.CREATE_WORKORDER_SET_IS_SUBMITTING:
      return {
        ...state,
        error: action.isSubmitting ? undefined : state.error,
        errorMessage: action.isSubmitting ? undefined : state.errorMessage,
        isSubmitting: action.isSubmitting,
      };
    case CREATE_WORKORDER_ACTION_TYPE.CREATE_WORKORDER_SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case 'CREATE_WORKORDER_FETCH_DEFAULT_LIMIT':
      return {
        ...state,
        defaultLimit: 0,
        defaultLimitLoading: true,
      };
    case 'CREATE_WORKORDER_SET_DEFAULT_LIMIT':
      return {
        ...state,
        defaultLimit: action.defaultLimit,
        defaultLimitLoading: false,
      };
    default:
      return state;
  }
};

export default createWorkorderReducer;
