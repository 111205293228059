import {
  DetailsModalOverlay,
  WORKBENCH_CATCH_ERROR,
  WORKBENCH_CHANGE_VIEW,
  WORKBENCH_CLEAR_ERROR,
  WORKBENCH_SET_DETAILS_MODAL_OVERLAY,
  WORKBENCH_SET_MODAL,
  WorkbenchActionTypes,
  WorkbenchModal,
  WorkbenchView,
} from '../constants';

export type WorkbenchState = {
  detailsModalOverlay?: DetailsModalOverlay | null;
  error?: Error;
  errorMessage?: string;
  modal?: WorkbenchModal;
  view: WorkbenchView;
};

const initialState: WorkbenchState = {
  view: WorkbenchView.List,
};

const workbenchReducer = (
  state: WorkbenchState = initialState,
  action: WorkbenchActionTypes,
): WorkbenchState => {
  switch (action.type) {
    case WORKBENCH_CATCH_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case WORKBENCH_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
      };
    case WORKBENCH_SET_MODAL:
      return {
        ...state,
        detailsModalOverlay: undefined,
        modal: action.modal,
      };
    case WORKBENCH_SET_DETAILS_MODAL_OVERLAY:
      return {
        ...state,
        detailsModalOverlay: action.overlay,
      };
    case WORKBENCH_CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    default:
      return state;
  }
};

export default workbenchReducer;
