import { invert } from 'lodash';

import DATE_PICKER_DEFAULT from '../components/common/DatePickerGroup/utils/getDatePickerDefaults';

export const {
  date_start: DATE_PICKER_LIFETIME_DEFAULT_GTE,
  date_end: DATE_PICKER_LIFETIME_DEFAULT_LTE,
} = DATE_PICKER_DEFAULT.lifetime;

export const SEARCH_HISTORY_MAX_LENGTH = 10;

export const SEARCH_QUERY_TEXT_MIN_LENGTH = 3;

export const SEARCH_QUERY_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const SEARCH_DEFAULT_LIMIT = 20;

export const SEARCH_DEBOUNCE_DELAY = 300;

export const SEARCH_DEFAULT_DECAY_PARAMS = Object.freeze({
  origin: 'now',
  scale: '365d',
  offset: '2d',
  decay: 0.9,
});

export enum SEARCH_SORT_DIRECTION {
  asc = 'asc',
  desc = 'desc',
}

export enum SearchIndex {
  ConsolidatedJobs = 'consolidated_jobs',
  Coupons = 'coupons',
  Jobs = 'jobs',
  Organizations = 'organizations',
  Promocodes = 'promocodes',
  Properties = 'properties',
  Referrals = 'referrals',
  Users = 'users',
}

export enum SearchCategory {
  Jobs = 'jobs',
  People = 'people',
  Properties = 'properties',
  Organizations = 'organizations',
  Promotions = 'promotions',
  Referrals = 'referrals',
}

export const SEARCH_DEFAULT_FILTER = Object.freeze({
  marketId: null,
  regionId: null,
});

export const SEARCH_DEFAULT_OPTION = Object.freeze({
  filter: SEARCH_DEFAULT_FILTER,
  sort: Object.freeze({}),
  page: 1,
  limit: SEARCH_DEFAULT_LIMIT,
});

export const SEARCH_BASE_URL = '/v1/elasticsearch';

/**
 * @deprecated use enum `SearchCategory`
 */
export const SEARCH_CATEGORY = Object.freeze({
  jobs: 'jobs',
  people: 'people',
  properties: 'properties',
  organizations: 'organizations',
  promotions: 'promotions',
  referrals: 'referrals',
});

export const SEARCH_CATEGORY_INDEX = Object.freeze({
  [SEARCH_CATEGORY.jobs]: SearchIndex.Jobs,
  [SEARCH_CATEGORY.organizations]: SearchIndex.Organizations,
  [SEARCH_CATEGORY.people]: SearchIndex.Users,
  [SEARCH_CATEGORY.promotions]: SearchIndex.Promocodes,
  [SEARCH_CATEGORY.properties]: SearchIndex.Properties,
  [SEARCH_CATEGORY.referrals]: SearchIndex.Referrals,
});

export const SEARCH_INDEX_CATEGORY = invert(SEARCH_CATEGORY_INDEX);

export const ALL_SEARCH_CATEGORIES = Object.freeze(
  Object.values(SEARCH_CATEGORY),
);

export const ALL_SEARCH_INDEXES = Object.freeze(Object.values(SearchIndex));
export const ALL_SEARCH_SORT_DIRECTIONS = Object.freeze(
  Object.values(SEARCH_SORT_DIRECTION),
);
