import { valuesIn } from 'lodash';

export enum Priority {
  Low = 1,
  Normal = 2,
  High = 3,
}

/**
 * @deprecated use enum instead
 */
export const WORKORDER_PRIORITY = Object.freeze({
  low: 1,
  normal: 2,
  high: 3,
});

export const ALL_WORKORDER_PRIORITIES = Object.freeze(
  valuesIn(WORKORDER_PRIORITY),
);

export const WORKORDER_PRIORITY_LABEL = Object.freeze({
  [WORKORDER_PRIORITY.low]: 'Low',
  [WORKORDER_PRIORITY.normal]: 'Normal',
  [WORKORDER_PRIORITY.high]: 'High',
});

export const WORKORDER_PRIORITY_LABEL_NEW = Object.freeze({
  [WORKORDER_PRIORITY.low]: 'Non-Emergency',
  [WORKORDER_PRIORITY.normal]: 'Non-Emergency',
  [WORKORDER_PRIORITY.high]: 'Emergency',
});

export default WORKORDER_PRIORITY;
