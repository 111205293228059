import { datadogRum } from '@datadog/browser-rum';
import * as ImportedFormData from 'form-data';

declare global {
  interface Window {
    __CSRF_TOKEN: string | null;
  }
}

type Methods<Data> = {
  get(): Promise<Data>;
  delete(body?: BodyInit): Promise<Data>;
  post(body?: BodyInit | ImportedFormData): Promise<Data>;
  put(body?: BodyInit): Promise<Data>;
  patch(body?: BodyInit): Promise<Data>;
};

type Options = {
  headers?: Record<string, string>;
  params?: URLSearchParams;
  impersonatedUserId?: string;
};

function isJSON(json: any): boolean {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
}

function setContentType(request: Request, body?: BodyInit): void {
  if (!body) {
    return;
  }

  if (body instanceof URLSearchParams) {
    // Let the browser set the Content-Type and boundary when sending URLSearchParams
    return;
  }

  if (body instanceof FormData) {
    // Let the browser set the Content-Type and boundary when sending FormData
    // https://stackoverflow.com/a/58254799
    return;
  }

  if (isJSON(body)) {
    request.headers.set('Content-Type', 'application/json');
    return;
  }

  throw new Error('Unsupported body payload');
}

function requestPathWithParams(requestPath: string, options: Options): string {
  const updatedParams: URLSearchParams =
    options.params ?? new URLSearchParams();
  if (options.impersonatedUserId) {
    updatedParams.set('impersonatedUserId', options.impersonatedUserId);
  }

  const queryString = updatedParams.toString();
  if (queryString) {
    return `${requestPath}?${queryString}`;
  }
  return requestPath;
}

export async function parseV1Response<Data = any>(
  response: Response,
): Promise<Data> {
  const data = await response.json();
  if (!response.ok || data.error) {
    datadogRum.addError(data.message);
    throw new Error(data.message || 'Request failed');
  }

  return data;
}

export function v1<Data = any>(
  endpointPath: string,
  options: Options = {},
): Methods<Data> {
  const { headers = {} } = options;
  const requestPath = requestPathWithParams(`/api/v1/${endpointPath}`, options);

  const baseRequest = new Request(requestPath, { headers });

  return {
    get(): Promise<Data> {
      return fetch(baseRequest).then(parseV1Response);
    },
    delete(): Promise<Data> {
      const request = new Request(baseRequest, { method: 'DELETE' });

      return fetch(request).then(parseV1Response);
    },
    post(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'POST',
        body,
      });
      setContentType(request, body);
      if (typeof window !== 'undefined' && window.__CSRF_TOKEN) {
        request.headers.append('X-CSRF-Token', window.__CSRF_TOKEN);
      }

      return fetch(request).then(parseV1Response);
    },
    put(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PUT',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(parseV1Response);
    },
    patch(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PUT',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(parseV1Response);
    },
  };
}

export async function checkV2Response(
  response: Response,
  defaultErrorMessage = 'Request failed',
): Promise<Response> {
  if (response.ok) {
    return response;
  }

  const json = await response.json();
  datadogRum.addError(json?.error);
  throw new Error(json?.error?.message ?? defaultErrorMessage);
}

export async function parseV2Response<Data = any>(
  response: Response,
): Promise<Data | null> {
  if (response.status !== 204) {
    return response.json();
  }
  return null;
}

export function v2<Data = any>(
  endpointPath: string,
  options: Options = {},
): Methods<Data> {
  const { headers = {} } = options;
  const requestPath = requestPathWithParams(`/v2/${endpointPath}`, options);

  const baseRequest = new Request(requestPath, { headers });

  return {
    get(): Promise<Data> {
      return fetch(baseRequest).then(checkV2Response).then(parseV2Response);
    },
    delete(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, { method: 'DELETE', body });
      setContentType(request, body);

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
    post(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'POST',
        body,
      });
      setContentType(request, body);
      if (typeof window !== 'undefined' && window.__CSRF_TOKEN) {
        request.headers.append('X-CSRF-Token', window.__CSRF_TOKEN);
      }

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
    put(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PUT',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
    patch(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PATCH',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
  };
}

export async function checkTaskResponse(response: Response): Promise<Response> {
  if (response.ok) {
    return response;
  }

  const json = await response.json();
  datadogRum.addError(json?.error);
  throw new Error(json?.error?.message ?? 'Request failed');
}

export async function parseTaskResponse<Data = any>(
  response: Response,
): Promise<Data | null> {
  if (response.status !== 204) {
    return response.json() || response.text();
  }
  return null;
}

export async function parsePutTaskResponse<Data = any>(
  response: Response,
): Promise<Data | null | string> {
  if (response.status !== 204) {
    return response.text();
  }
  return null;
}

export function task<Data = any>(
  endpointPath: string,
  options: Options = {},
): Methods<Data> {
  const { headers = {} } = options;
  const requestPath = requestPathWithParams(
    `/service/${endpointPath}`,
    options,
  );

  const baseRequest = new Request(requestPath, { headers });

  return {
    get(): Promise<Data> {
      return fetch(baseRequest).then(checkTaskResponse).then(parseTaskResponse);
    },
    delete(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, { method: 'DELETE', body });
      setContentType(request, body);

      return fetch(request).then(checkTaskResponse).then(parsePutTaskResponse);
    },
    post(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'POST',
        body,
      });
      setContentType(request, body);
      if (typeof window !== 'undefined' && window.__CSRF_TOKEN) {
        request.headers.append('X-CSRF-Token', window.__CSRF_TOKEN);
      }

      return fetch(request).then(checkTaskResponse).then(parseTaskResponse);
    },
    put(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PUT',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(checkTaskResponse).then(parsePutTaskResponse);
    },
    patch(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PATCH',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(checkTaskResponse).then(parseTaskResponse);
    },
  };
}

export function v3<Data = any>(
  endpointPath: string,
  options: Options = {},
): Methods<Data> {
  const { headers = {} } = options;
  const requestPath = requestPathWithParams(`/v3/${endpointPath}`, options);

  const baseRequest = new Request(requestPath, { headers });

  return {
    get(): Promise<Data> {
      return fetch(baseRequest).then(checkV2Response).then(parseV2Response);
    },
    delete(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, { method: 'DELETE', body });
      setContentType(request, body);

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
    post(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'POST',
        body,
      });
      setContentType(request, body);
      if (typeof window !== 'undefined' && window.__CSRF_TOKEN) {
        request.headers.append('X-CSRF-Token', window.__CSRF_TOKEN);
      }

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
    put(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PUT',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
    patch(body?: BodyInit): Promise<Data> {
      const request = new Request(baseRequest, {
        method: 'PATCH',
        body,
      });
      setContentType(request, body);

      return fetch(request).then(checkV2Response).then(parseV2Response);
    },
  };
}
