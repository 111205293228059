export const WORKBENCH_CATCH_ERROR = 'WORKBENCH_CATCH_ERROR';
export const WORKBENCH_CLEAR_ERROR = 'WORKBENCH_CLEAR_ERROR';
export const WORKBENCH_SET_MODAL = 'WORKBENCH_SET_MODAL';
export const WORKBENCH_SET_CREATE_WO_OVERLAY =
  'WORKBENCH_SET_CREATE_WO_OVERLAY';
export const WORKBENCH_SET_DETAILS_MODAL_OVERLAY =
  'WORKBENCH_SET_DETAILS_MODAL_OVERLAY';
export const WORKBENCH_CHANGE_VIEW = 'WORKBENCH_CHANGE_VIEW';

export enum WorkbenchModal {
  AdvancedFilters,
  CreateWorkOrder,
  Details,
  DetailsSubmitWorkOrder,
  Error,
}

export enum DetailsModalOverlay {
  ConsumerReqDeclineForPM,
  ConsumerReqCancel,
  DiagnosticReport,
  DeleteDraft,
  RejectWorkOrder,
  JobSuspend,
  JobRollback,
  JobCancel,
  UnlinkTimerAndLabor,
  RequestLimitExtension,
  SendProInvoice,
}

export enum WorkbenchView {
  List,
  Split,
  Map,
}

interface CatchErrorAction {
  type: typeof WORKBENCH_CATCH_ERROR;
  error: Error;
  errorMessage: string;
}

interface ClearErrorAction {
  type: typeof WORKBENCH_CLEAR_ERROR;
}

interface SetModalAction {
  type: typeof WORKBENCH_SET_MODAL;
  modal?: WorkbenchModal;
}

interface SetDetailsModalOverlayAction {
  type: typeof WORKBENCH_SET_DETAILS_MODAL_OVERLAY;
  overlay: DetailsModalOverlay | null;
}

interface ChangeViewAction {
  type: typeof WORKBENCH_CHANGE_VIEW;
  view: WorkbenchView;
}

export type WorkbenchActionTypes =
  | CatchErrorAction
  | ClearErrorAction
  | SetModalAction
  | SetDetailsModalOverlayAction
  | ChangeViewAction;
