import { JobFiltersState } from '../reducers/jobFiltersReducer';

export const CLEAR_JOB_FILTERS = 'CLEAR_JOB_FILTERS';
export const UPDATE_JOB_FILTERS = 'UPDATE_JOB_FILTERS';
export const CLEAR_PROVIDER_FILTERS = 'CLEAR_PROVIDER_FILTERS';

interface ClearJobFiltersAction {
  type: typeof CLEAR_JOB_FILTERS;
}

interface UpdateJobFiltersAction {
  type: typeof UPDATE_JOB_FILTERS;
  updated: Partial<JobFiltersState>;
}

interface ClearProviderFiltersAction {
  type: typeof CLEAR_PROVIDER_FILTERS;
}

export type JobFiltersActionTypes =
  | ClearJobFiltersAction
  | UpdateJobFiltersAction
  | ClearProviderFiltersAction;
