// Original source: https://github.com/zeit/next.js/blob/canary/examples/with-apollo-and-redux/lib/initApollo.js
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import generatedIntrospection from '../../__generated__/intropsection-result';

let apolloClient = null;

const create = (initialState) => {
  const isBrowser = typeof window !== 'undefined';

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // TODO: Handle graphql errors
      // graphQLErrors.map(({ message, locations, path }) =>
      //   console.error(
      //     new Error(
      //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      //     ),
      //   ),
      // );
    }

    if (networkError) {
      // TODO: Handle graphql errors
      // console.error(new Error(`[Network error]: ${networkError}`));
    }
  });

  const uri = isBrowser
    ? `${window.location.origin}/graphql`
    : `http://localhost:${process.env.PORT}/graphql`;

  const uploadLink = createUploadLink({
    uri,
    fetch: !isBrowser && fetch,
    headers: { 'Apollo-Require-Preflight': true },
    credentials: 'same-origin',
  });

  return new ApolloClient({
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries only run once)
    link: ApolloLink.from([errorLink, uploadLink]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }).restore(initialState || {}),
  });
};

const initApollo = (initialState) => {
  // Make sure to create a new client for every sever-side request so that the data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return create(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
};

export default initApollo;
