import {
  SearchCategory,
  SearchIndex,
  SEARCH_QUERY_TEXT_MIN_LENGTH,
} from '../../constants/search';
import { createRequests } from '../../utils/search/createRequests';
import { generateConsolidatedJobSearchPageSearch } from '../../services/search/ConsolidatedJobSearch';
import { generateUserSearchPageSearch } from '../../services/search/UserSearch';
import { generateUserPropertySearchPage } from '../../services/search/UserPropertySearch';
import { generateCouponSearchPage } from '../../services/search/CouponSearch';
import { generateUserReferralSearchPage } from '../../services/search/UserReferralSearch';
import { generateOrganizationSearchPage } from '../../services/search/OrganizationSearch';

export const SEARCH_HISTORY_ADD = 'SEARCH_HISTORY_ADD';
export const SEARCH_HISTORY_REMOVE = 'SEARCH_HISTORY_REMOVE';

export const SEARCH_HEADER_RESULTS_DEFAULT = 'SEARCH_HEADER_RESULTS_DEFAULT';
export const SEARCH_HEADER_RESULTS_FAILURE = 'SEARCH_HEADER_RESULTS_FAILURE';
export const SEARCH_HEADER_RESULTS_REQUEST = 'SEARCH_HEADER_RESULTS_REQUEST';
export const SEARCH_HEADER_RESULTS_UPDATED = 'SEARCH_HEADER_RESULTS_UPDATED';

export const SEARCH_PAGE_RESULTS_DEFAULT = 'SEARCH_PAGE_RESULTS_DEFAULT';
export const SEARCH_PAGE_RESULTS_FAILURE = 'SEARCH_PAGE_RESULTS_FAILURE';
export const SEARCH_PAGE_RESULTS_REQUEST = 'SEARCH_PAGE_RESULTS_REQUEST';
export const SEARCH_PAGE_RESULTS_UPDATED = 'SEARCH_PAGE_RESULTS_UPDATED';

export const removeValueFromSearchHistory = (value) => (dispatch) => {
  dispatch({ type: SEARCH_HISTORY_REMOVE, value });
};

export const resetSearchHeaderResults = () => (dispatch) => {
  dispatch({ type: SEARCH_HEADER_RESULTS_DEFAULT });
};

export const fetchSearchHeaderResults =
  (searchText, searchMap) => (dispatch) => {
    if (searchText.length < SEARCH_QUERY_TEXT_MIN_LENGTH) {
      return null;
    }

    dispatch({ type: SEARCH_HISTORY_ADD, value: searchText });
    dispatch({ type: SEARCH_HEADER_RESULTS_REQUEST });

    return createRequests(searchMap, 1, 3)
      .then((data) => {
        dispatch({
          type: SEARCH_HEADER_RESULTS_UPDATED,
          text: searchText,
          data,
        });
      })
      .catch((error) => {
        dispatch({ type: SEARCH_HEADER_RESULTS_FAILURE, error });
      });
  };

export const fetchSearchHeaderResultsLegacy = (query, csrf) => (dispatch) => {
  const { text } = query;
  if (text.length < SEARCH_QUERY_TEXT_MIN_LENGTH) {
    return null;
  }
  dispatch({ type: SEARCH_HISTORY_ADD, value: text });
  dispatch({ type: SEARCH_HEADER_RESULTS_REQUEST });

  return createRequests(query, csrf)
    .then((data) => {
      dispatch({ type: SEARCH_HEADER_RESULTS_UPDATED, text, data });
    })
    .catch((error) => {
      dispatch({ type: SEARCH_HEADER_RESULTS_FAILURE, error });
    });
};

export const resetSearchPageResults = () => (dispatch) => {
  dispatch({ type: SEARCH_PAGE_RESULTS_DEFAULT });
};

export const fetchSearchPageResults = (filters) => (dispatch) => {
  if (filters.searchText?.length < SEARCH_QUERY_TEXT_MIN_LENGTH) {
    return null;
  }

  dispatch({ type: SEARCH_HISTORY_ADD, value: filters.searchText });
  dispatch({ type: SEARCH_PAGE_RESULTS_REQUEST });

  const searchMap = {
    [SearchCategory.Jobs]: {
      index: SearchIndex.ConsolidatedJobs,
      query: generateConsolidatedJobSearchPageSearch({ ...filters }),
    },
    [SearchCategory.People]: {
      index: SearchIndex.Users,
      query: generateUserSearchPageSearch({ ...filters }),
    },
    [SearchCategory.Properties]: {
      index: SearchIndex.Properties,
      query: generateUserPropertySearchPage({ ...filters }),
    },
    [SearchCategory.Organizations]: {
      index: SearchIndex.Organizations,
      query: generateOrganizationSearchPage({ ...filters }),
    },
    [SearchCategory.Promotions]: {
      index: SearchIndex.Promocodes,
      query: generateCouponSearchPage({ ...filters }),
    },
    [SearchCategory.Referrals]: {
      index: SearchIndex.Referrals,
      query: generateUserReferralSearchPage({ ...filters }),
    },
  };

  return Promise.all([
    createRequests(searchMap, filters.page, 10)
      .then((data) => {
        dispatch({
          type: SEARCH_PAGE_RESULTS_UPDATED,
          text: filters.searchText,
          data,
        });
      })
      .catch((error) => {
        dispatch({ type: SEARCH_PAGE_RESULTS_FAILURE, error });
      }),
  ]);
};
