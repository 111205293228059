import React, { useEffect, useCallback, PropsWithChildren } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import { COLOR, SPACING, TRANSITION, Z_INDEX } from './theme';
import Text from './Text';
import Icon from './Icon';

export type Action = {
  href?: string;
  text?: string;
  element?: JSX.Element;
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

type Props = PropsWithChildren<{
  className?: string;
  list: Action[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => any | Promise<any>;
  position?: 'left' | 'right';
}>;

const ActionList: React.FC<Props> = ({
  className,
  list,
  isOpen,
  setIsOpen,
  children,
  position = 'left',
}) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const handleOutsideClick = useCallback(
    (event) => {
      if (isOpen && !wrapperRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [isOpen, setIsOpen],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick, false);
    window.addEventListener('touchstart', handleOutsideClick, false);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false);
      window.removeEventListener('touchstart', handleOutsideClick, false);
    };
  }, [handleOutsideClick]);

  return (
    <div
      className={classnames('action-list-wrapper', className)}
      ref={wrapperRef}
      style={{ display: !list.length ? 'none' : '' }}
    >
      <div
        className={classnames(
          'box-and-arrow',
          position,
          isOpen ? 'visible' : '',
        )}
        id="action-list-wrapper"
        data-testid="actions-tooltip"
      >
        <div className="main-box">
          {list.map((action, index) => {
            if (action?.element) {
              return (
                <React.Fragment key={action.text}>
                  {action.element}
                  {index + 1 === list.length ? null : <hr />}
                </React.Fragment>
              );
            }
            if (action?.href) {
              return (
                <React.Fragment key={action.text}>
                  <Link href={action.href} className="no-button">
                    <Text
                      className="action-text"
                      size="medium"
                      onDark
                      element="span"
                    >
                      {action?.text}
                    </Text>
                  </Link>
                  {index + 1 === list.length ? null : <hr />}
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={action?.text}>
                <button
                  className="no-button"
                  type="button"
                  onClick={action?.clickHandler}
                >
                  <Text
                    className="action-text"
                    size="medium"
                    onDark
                    element="span"
                  >
                    {action?.text}
                  </Text>
                </button>

                {index + 1 === list.length ? null : <hr />}
              </React.Fragment>
            );
          })}
        </div>

        <div className="arrow" />
      </div>
      <div className="trigger">
        {children === undefined ? (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(true);
            }}
          >
            <Icon icon="moreVert" />
          </button>
        ) : (
          children
        )}
      </div>
      <style jsx>
        {`
          .action-list-wrapper {
            display: inline-block;
            width: fit-content;
            position: relative;
          }
          .arrow {
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 10px solid ${COLOR.black};
            margin-top: 10px;
          }

          button {
            border: none;
            background-color: inherit;
            display: flex;
            align-items: center;
            padding: ${SPACING.xs}px;
          }
          button:hover :global(.action-text),
          a:hover :global(.action-text) {
            text-shadow: -1px 0px 1px ${COLOR.white}; // use shadow instead of weight to prevent width change on hover
          }
          .box-and-arrow {
            display: none;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity ${TRANSITION.duration} linear;
            position: absolute;
            top: -${SPACING.xs}%;
            z-index: ${Z_INDEX.actionList};
          }
          .box-and-arrow.left {
            right: 100%;
            margin-right: ${SPACING.xs}px;
            flex-direction: row;
          }
          .box-and-arrow.right {
            left: 100%;
            margin-left: ${SPACING.xs}px;
            flex-direction: row-reverse;
          }
          .box-and-arrow.visible {
            display: flex;
            visibility: visible;
            opacity: 1;
          }
          .box-and-arrow.right .arrow {
            transform: rotateY(180deg);
          }
          hr {
            border: 0;
            width: 100%;
            background-color: ${COLOR.white};
            height: 1px;
            margin: ${SPACING.xs}px auto;
          }
          .main-box {
            background-color: ${COLOR.black};
            border-radius: 4px;
            padding-top: ${SPACING.xs}px;
            padding-right: ${SPACING.sm}px;
            padding-bottom: ${SPACING.xs}px;
            padding-left: ${SPACING.xs}px;
          }
          .no-button {
            background-color: ${COLOR.black};
            border: none;
            width: 100%;
            text-align: left;
            padding: 0;
            text-decoration: none;
            display: block;
            white-space: nowrap;
          }
          .trigger {
            position: relative;
          }
        `}
      </style>
    </div>
  );
};

export default ActionList;
