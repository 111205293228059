import { buildJobsQuery } from '../utils/search/queryBuilders/buildJobsQuery';
import { buildOrganizationsQuery } from '../utils/search/queryBuilders/buildOrganizationsQuery';
import { buildPromocodesQuery } from '../utils/search/queryBuilders/buildPromocodesQuery';
import { buildPropertiesQuery } from '../utils/search/queryBuilders/buildPropertiesQuery';
import { buildReferralsQuery } from '../utils/search/queryBuilders/buildReferralsQuery';
import { buildUsersQuery } from '../utils/search/queryBuilders/buildUsersQuery';
import { SearchIndex } from './search';

// TODO type query builder functions
const SEARCH_INDEX_QUERY_BUILDER = {
  [SearchIndex.Jobs]: buildJobsQuery as any,
  [SearchIndex.Organizations]: buildOrganizationsQuery as any,
  [SearchIndex.Promocodes]: buildPromocodesQuery as any,
  [SearchIndex.Properties]: buildPropertiesQuery as any,
  [SearchIndex.Referrals]: buildReferralsQuery as any,
  [SearchIndex.Users]: buildUsersQuery as any,
};

export default SEARCH_INDEX_QUERY_BUILDER;
