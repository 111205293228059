import React, { Component } from 'react';
import PropTypes from 'prop-types';
import initApollo from './initApollo';

const ConnectApollo = (App) => {
  class Apollo extends Component {
    apolloClient;

    static displayName = 'withApollo(App)';

    static async getInitialProps(ctx) {
      let appProps = {};

      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      const apollo = initApollo();

      // if (typeof window === 'undefined') {
      //   try {
      //     await getDataFromTree(
      //       // eslint-disable-next-line react/jsx-props-no-spreading
      //       <AppTree {...appProps} apolloClient={apollo} />,
      //     );
      //   } catch (error) {
      //     // Do nothing
      //   }
      //   Head.rewind();
      // }

      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
      };
    }

    constructor(props) {
      super(props);
      this.apolloClient = initApollo(props.apolloState);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <App apolloClient={this.apolloClient} {...this.props} />;
    }
  }

  Apollo.defaultProps = {
    apolloState: {},
  };

  Apollo.propTypes = {
    apolloState: PropTypes.object,
  };

  return Apollo;
};

export default ConnectApollo;
