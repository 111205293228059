import css from 'styled-jsx/css';
import React from 'react';

import Icon from './Icon';
import { COLOR } from './theme';

const { className: iconClassName, styles: iconStyles } = css.resolve`
  i {
    border-radius: 2px;
    display: block;
    font-size: 18px;
    width: 18px;
    height: 18px;
  }

  i:hover {
    background-color: ${COLOR.softGray};
  }
`;

type Props = {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  indeterminate?: boolean;
  name?: string;
  onChange?: (isChecked: boolean) => any | Promise<any>;
  style?: Record<string, string | number>;
};

const Checkbox: React.FC<Props> = ({
  checked,
  className,
  disabled,
  id,
  indeterminate,
  name,
  onChange,
  style,
}: Props) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(!!checked);

  React.useEffect(() => {
    setIsChecked(!!checked);
  }, [checked]);

  let color: string = isChecked || indeterminate ? COLOR.blue : COLOR.darkGray;
  if (disabled) {
    color = COLOR.softGray;
  }

  let icon;
  if (isChecked) {
    icon = (
      <Icon
        className={iconClassName}
        icon={indeterminate ? 'indeterminateCheckBox' : 'checkBox'}
        style={{ color }}
      />
    );
  } else {
    icon = (
      <Icon
        className={iconClassName}
        icon="checkBoxOutlineBlank"
        style={{ color }}
      />
    );
  }

  return (
    <button
      aria-checked={isChecked}
      className={className}
      disabled={disabled}
      id={id}
      name={name}
      onClick={(event) => {
        event.preventDefault();
        if (typeof onChange === 'function') {
          onChange(!isChecked);
        } else {
          setIsChecked((prev) => !prev);
        }
      }}
      role="checkbox"
      style={style}
      type="button"
    >
      {icon}
      <style jsx>
        {`
          button {
            align-items: unset;
            background-color: transparent;
            border: none;
            cursor: pointer;
            display: block;
            height: 24px;
            font-size: 18px;
            margin: 0;
            padding: 0;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          button:disabled {
            pointer-events: none;
          }

          button :global(svg) {
            width: 100%;
            height: auto;
            padding-bottom: 1px;
          }
        `}
      </style>
      {iconStyles}
    </button>
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  id: undefined,
  indeterminate: false,
  name: undefined,
  onChange: undefined,
  style: undefined,
};

export default Checkbox;
