const initialEditsState = {};

const initialState = {
  edits: initialEditsState,
  error: undefined,
  errorMessage: undefined,
  id: undefined,
  inEditMode: false,
  isUpdating: false,
};

const workorderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'WORKORDER_CATCH_ERROR':
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case 'WORKORDER_SET_IN_EDIT_MODE':
      return {
        ...state,
        edits: initialEditsState,
        inEditMode: action.inEditMode,
      };
    case 'WORKORDER_UPDATE_EDITS':
      return {
        ...state,
        edits: { ...state.edits, ...action.edits },
      };
    case 'WORKORDER_CLEAR_EDITS':
      return {
        ...state,
        edits: initialEditsState,
      };
    case 'WORKORDER_SET_IS_UPDATING':
      return {
        ...state,
        error: action.isUpdating ? undefined : state.error,
        errorMessage: action.isUpdating ? undefined : state.errorMessage,
        id: action.isUpdating ? action.id : undefined,
        isUpdating: action.isUpdating,
      };
    default:
      return state;
  }
};

export default workorderReducer;
