import { valuesIn } from 'lodash';

export const CONSUMER_REQUEST_STATUS = Object.freeze({
  scheduled: 65,
  pending: 70,
  pending_approval: 80,
  pending_tenant: 90,
  pending_PM: 100,
  timeout: 101,
  accepted_PR: 110,
  rejected_PR: 120,
  cancelled: 130,
  declined_PM: 126,
  manual_assign: 140,
});

export default CONSUMER_REQUEST_STATUS;

export const ALL_CONSUMER_REQUEST_STATUSES = valuesIn(CONSUMER_REQUEST_STATUS);
