import { combineReducers } from 'redux';

import activeMarketLabels from './activeMarketLabelsReducer';
import consumerReq from './consumerReqReducer';
import createWorkorder from './createWorkorderReducer';
import claimsFilters from './claimsFiltersReducer';
import companyTasksFilters from './companyTasksFiltersReducer';
import companiesFilters from './companiesFiltersReducer';
import editRequest from './editRequestReducer';
import filters from './filtersReducer';
import formReducer from './formReducer';
import job from './jobReducer';
import jobFilters from './jobFiltersReducer';
import jobsPage from './jobsPageReducer';
import myTasksFilters from './myTasksFiltersReducer';
import providerFilters from './providerFiltersReducer';
import rateLookup from './rateLookupReducer';
import regions from './regionsReducer';
import requestsPage from './requestsPageReducer';
import search from './searchReducer';
import organization from './organizationReducer';
import team_members from './teamMemberReducer';
import billing_records from './billingRecordsReducer';
import toasts from './toastsReducer';
import user from './userReducer';
import workbench from './workbenchReducer';
import workorder from './workorderReducer';

export default combineReducers({
  activeMarketLabels,
  claimsFilters,
  consumerReq,
  createWorkorder,
  companyTasksFilters,
  companiesFilters,
  editRequest,
  filters,
  formReducer,
  job,
  jobFilters,
  jobsPage,
  requestsPage,
  myTasksFilters,
  providerFilters,
  rateLookup,
  regions,
  search,
  organization,
  team_members,
  billing_records,
  toasts,
  user,
  workbench,
  workorder,
});
