import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { SPACING, BREAKPOINT_VALUE } from '../homee-ui/theme';

type Props = PropsWithChildren<{
  marginBottom?: number;
  marginTop?: number;
  maxWidth?: number;
  stackMobile?: boolean;
  autoWidth?: boolean;
  style?: Record<string, string | number>;
  className?: string;
}>;

function ButtonGroup({
  children,
  marginBottom,
  marginTop,
  maxWidth,
  stackMobile,
  autoWidth = false,
  style,
  className,
}: Props): JSX.Element {
  return (
    <div
      className={classnames('button-group', className, {
        'stack-mobile': stackMobile,
        'auto-width': autoWidth,
      })}
      style={{ ...style, maxWidth }}
    >
      {children}
      <style jsx>
        {`
          .button-group {
            display: flex;
          }

          .button-group:not(.auto-width) :global(button) {
            flex: 1 1 0;
          }

          .button-group :global(button):not(:last-of-type) {
            margin: 0 ${SPACING.sm}px 0 0;
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm}px) {
            .button-group.stack-mobile {
              flex-direction: column;
            }

            .button-group.stack-mobile :global(button):not(:last-of-type) {
              margin: 0 0 ${SPACING.xs}px 0;
            }
          }
        `}
      </style>
      <style jsx>
        {`
          .button-group {
            margin: ${marginTop}px 0 ${marginBottom}px;
          }
        `}
      </style>
    </div>
  );
}

ButtonGroup.defaultProps = {
  marginBottom: SPACING.md,
  marginTop: SPACING.lg,
  stackMobile: true,
};

export default ButtonGroup;
