import {
  CLEAR_JOB_FILTERS,
  JobFiltersActionTypes,
  UPDATE_JOB_FILTERS,
} from '../constants';

export type JobFiltersState = {
  internalAssigneeIds: string[] | null;
  internalAssigneeName: string[] | null;
  following: boolean;
  priority: number | null;
  buckets: string[] | null;
  subBuckets: string[] | null;
  serviceTypes: number[] | null;
  sort: {
    orderBy: string;
    sortOrder: string;
  };
  estimateStatuses: string[] | null;
  estimateStatusLabels: string[] | null;
  organizationIds: string[] | null;
  organizationNames: string[] | null;
  appointmentDateStart: string | null;
  appointmentDateEnd: string | null;
  completedDateStart: string | null;
  completedDateEnd: string | null;
  origin: string | null;
  providerIds: string[] | null;
  providerName: string[] | null;
  createdDateStart: string | null;
  createdDateEnd: string | null;
  scheduledDateStart: string | null;
  scheduledDateEnd: string | null;
  nullScheduledDate: boolean;
  nullAppointmentDate: boolean;
  companyIds: string[] | null;
  companyNames: string[] | null;
};

export const initialState: JobFiltersState = {
  internalAssigneeIds: null,
  internalAssigneeName: null,
  following: false,
  priority: null,
  buckets: null,
  subBuckets: null,
  serviceTypes: null,
  sort: {
    orderBy: 'scheduledDate',
    sortOrder: 'descending',
  },
  estimateStatuses: null,
  estimateStatusLabels: null,
  organizationIds: null,
  organizationNames: null,
  appointmentDateStart: null,
  appointmentDateEnd: null,
  completedDateStart: null,
  completedDateEnd: null,
  origin: null,
  providerIds: null,
  providerName: null,
  createdDateStart: null,
  createdDateEnd: null,
  scheduledDateStart: null,
  scheduledDateEnd: null,
  nullScheduledDate: false,
  nullAppointmentDate: false,
  companyIds: null,
  companyNames: null,
} as const;

// Keys for filtering
export const FILTER_KEYS = [
  'priority',
  'subBuckets',
  'serviceTypes',
  'following',
  'estimateStatuses',
  'organizationIds',
  'appointmentDateStart',
  'appointmentDateEnd',
  'completedDateStart',
  'completedDateEnd',
  'origin',
  'internalAssigneeIds',
  'providerIds',
  'createdDateStart',
  'createdDateEnd',
  'scheduledDateStart',
  'scheduledDateEnd',
  'nullScheduledDate',
  'nullAppointmentDate',
  'companyIds',
] as const;

const jobFiltersReducer = (
  state: JobFiltersState = initialState,
  action: JobFiltersActionTypes,
): JobFiltersState => {
  switch (action.type) {
    case CLEAR_JOB_FILTERS:
      return {
        ...state,
        internalAssigneeIds: null,
        internalAssigneeName: null,
        priority: null,
        buckets: null,
        subBuckets: null,
        serviceTypes: null,
        following: false,
        estimateStatuses: null,
        estimateStatusLabels: null,
        organizationIds: null,
        organizationNames: null,
        appointmentDateStart: null,
        appointmentDateEnd: null,
        completedDateStart: null,
        completedDateEnd: null,
        origin: null,
        providerIds: null,
        providerName: null,
        createdDateStart: null,
        createdDateEnd: null,
        scheduledDateStart: null,
        scheduledDateEnd: null,
        nullScheduledDate: false,
        nullAppointmentDate: false,
        companyIds: null,
        companyNames: null,
      };
    case UPDATE_JOB_FILTERS:
      return { ...state, ...action.updated };
    default:
      return state;
  }
};

export default jobFiltersReducer;
