export const REQUEST_ACTION_TYPE = Object.freeze({
  REQUEST_CATCH_ERROR: 'REQUEST_CATCH_ERROR',
  REQUEST_CLEAR_ERROR: 'REQUEST_CLEAR_ERROR',
  REQUEST_UPDATE_EDITS: 'REQUEST_UPDATE_EDITS',
  REQUEST_CLEAR_EDITS: 'REQUEST_CLEAR_EDITS',
  REQUEST_SET_IS_SUBMITTING: 'REQUEST_SET_IS_SUBMITTING',
  REQUEST_SET_TAB: 'REQUEST_SET_TAB',
  REQUEST_FETCH_DEFAULT_LIMIT: 'REQUEST_FETCH_DEFAULT_LIMIT',
  REQUEST_SET_DEFAULT_LIMIT: 'REQUEST_SET_DEFAULT_LIMIT',
});

export const REQUEST_ERROR_MESSAGE = Object.freeze({
  submit_fail: 'Failed to create workorder. Please try again.',
  unauthorized:
    'You are not authorized to create a work order on behalf of this customer',
});

export default REQUEST_ACTION_TYPE;
