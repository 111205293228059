import React from 'react';
import { RequestBodySearch } from 'elastic-builder';
import { debounce } from 'lodash';

import {
  SearchIndex,
  SEARCH_DEFAULT_LIMIT,
  SEARCH_DEBOUNCE_DELAY,
} from '../../constants/search';
import { v2 } from '../api/ApiService';
import { Hit, hitTransformers } from './common';

export type SearchResponse<Index extends SearchIndex> = {
  data: {
    hits: {
      hits: Hit<Index>[];
      max_score: number;
      total: {
        relation: string;
        value: number;
      };
    };
    timed_out: boolean;
    took: number;
    _shards: {
      total: number;
      successful: number;
      skipped: number;
      failed: number;
    };
  };
};

type UseSearchQueryResult<Index extends SearchIndex> = {
  hits: Hit<Index>[];
  error: Error | null;
  loading: boolean;
  fetchMore: () => void;
  refetch: () => void;
  fetchPage: (page: number) => void;
  total: number;
  page: number;
  setHits: React.Dispatch<React.SetStateAction<Hit<Index>[]>>;
  isInfiniteScroll?: boolean;
};

export async function search<Index extends SearchIndex>(
  index: Index,
  searchBody: RequestBodySearch,
): Promise<SearchResponse<Index>> {
  const json = searchBody.toJSON();
  const body = JSON.stringify(json);

  return v2<Promise<SearchResponse<Index>>>(`search/${index}`, {}).post(body);
}

export function useSearchQuery<Index extends SearchIndex>(
  index: Index,
  searchBody: RequestBodySearch,
  limit = SEARCH_DEFAULT_LIMIT,
  debounceDelay = SEARCH_DEBOUNCE_DELAY,
  skip = false,
  selectedPage = 1,
  isInfiniteScroll = true,
): UseSearchQueryResult<Index> {
  const [hits, setHits] = React.useState<Hit<Index>[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(selectedPage);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const optionsKey = JSON.stringify(searchBody);

  const fetchMore = () => {
    if (page === 1 || hits.length <= (total || Number.MAX_SAFE_INTEGER)) {
      setPage((prev) => prev + 1);
    }
  };

  const fetchData = async () => {
    if (skip) {
      setLoading(false);
      return;
    }

    // Check if all results have already been fetched
    if (page !== 1 && (!total || hits.length >= total)) {
      setLoading(false);
      return;
    }

    const transform =
      hitTransformers[index as string] || ((_hits: Hit<Index>[]) => _hits);

    // Add pagination
    let paginatedSearchBody;
    const from = limit * (page - 1);
    if (isInfiniteScroll) {
      if (from === hits.length || page === 1)
        paginatedSearchBody = searchBody.from(from).size(limit);
      else
        paginatedSearchBody = searchBody
          .from(hits.length)
          .size(page * SEARCH_DEFAULT_LIMIT - hits.length);
    } else {
      paginatedSearchBody = searchBody.from(from).size(limit);
    }

    setLoading(true);
    // TODO figure out if this is needing to be done this way.
    // const transform =
    //   hitTransformers[index as string] || ((_hits: Hit<Index>[]) => _hits);
    // setHits((prevHits) => {
    //   const transformedNewHits = transform(newHits);
    //   /**
    //    * I thought the `page === 1` check might have contributed to
    //    * https://homee.atlassian.net/browse/INT-1579, but it appears this is
    //    * not the cause. We should be able to safely remove this, but am
    //    * leaving it in until we have more time for a thorough QA
    //    *
    //    */

    //   return page === 1
    //     ? transformedNewHits
    //     : [...prevHits, ...transformedNewHits];
    // });
    try {
      const {
        data: {
          hits: {
            total: { value: newTotal },
            hits: newHits,
          },
        },
      } = await search(index, paginatedSearchBody);

      setTotal(newTotal);
      setHits((prevHits) => {
        const transformedNewHits = transform(newHits);
        return page === 1 || !isInfiniteScroll
          ? transformedNewHits
          : [...prevHits, ...transformedNewHits];
      });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const debouncedFetch = debounce(fetchData, debounceDelay);

  const refetch = () => debouncedFetch();

  React.useEffect(() => {
    if (page === 1) {
      setHits([]);
    }
    setLoading(true);
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [index, page, optionsKey, skip]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setPage(selectedPage);
  }, [selectedPage]);

  return {
    hits,
    error,
    loading,
    page,
    fetchMore,
    refetch,
    fetchPage: (newPage: number) => {
      setPage(newPage);
    },
    total,
    setHits,
  };
}
