import React from 'react';
import {
  ADD_TOAST,
  CLEAR_ALL_TOASTS,
  REMOVE_TOAST,
  ToastsActionTypes,
  ToastType,
} from '../constants';

export type ToastsState = {
  message: string;
  type: ToastType;
  element?: JSX.Element;
  hideClose?: boolean;
}[];

const toastsReducer = (
  state: ToastsState = [],
  action: ToastsActionTypes,
): ToastsState => {
  switch (action.type) {
    case ADD_TOAST:
      return [
        ...state,
        {
          message: action.message,
          type: action.toastType,
          element: action.element,
          hideClose: action.hideClose,
        },
      ];
    case REMOVE_TOAST:
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1),
      ];
    case CLEAR_ALL_TOASTS:
      return [];
    default:
      return state;
  }
};

export default toastsReducer;
