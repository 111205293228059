import { COLOR } from '../../components/homee-ui/theme';

export const breakpointValues = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const breakpoints = {
  xsUp: `@media (min-width: ${breakpointValues.sm}px)`,
  smUp: `@media (min-width: ${breakpointValues.sm}px)`,
  mdUp: `@media (min-width: ${breakpointValues.md}px)`,
  lgUp: `@media (min-width: ${breakpointValues.lg}px)`,
  xlUp: `@media (min-width: ${breakpointValues.xl}px)`,
  xxlUp: `@media (min-width: ${breakpointValues.xxl}px)`,
  xsDown: `@media (max-width: ${breakpointValues.sm - 0.02}px)`,
  smDown: `@media (max-width: ${breakpointValues.sm - 0.02}px)`,
  mdDown: `@media (max-width: ${breakpointValues.md - 0.02}px)`,
  lgDown: `@media (max-width: ${breakpointValues.lg - 0.02}px)`,
  xlDown: `@media (max-width: ${breakpointValues.xl - 0.02}px)`,
  xxlDown: `@media (max-width: ${breakpointValues.xxl - 0.02}px)`,
  values: breakpointValues,
};

// https://zeroheight.com/83195d329/p/1582b4/b/dd060
export const colors = {
  ...COLOR,

  // ↓ Deprecated as of 2019-08-22 ↓ - use the colors above
  activeBlue: '#184D70',
  activeBrown: '#9B5E11',
  activeGray: '#464646',
  activeGreen: '#10511F',
  activeTeal: '#14595D',
  black: '#000000',
  blackMediumLight: 'rgba(0,0,0,0.5)',
  blue: '#3498DB',
  borderGray: '#D5D7CA',
  buttonGreen: '#1B8633',
  cancelGray: '#C3C8CD',
  completedBlue: '#2980B9',
  concentratedBlue: '#26436F',
  concentratedRed: '#993025',
  fbBlue: '#3B5998',
  flatBlue: '#E5EBF0',
  gplusRed: '#E74C3C',
  gplusRed10: 'rgba(231, 76, 60, 0.1)',
  gplusRed50: 'rgba(231, 76, 60, 0.5)',
  gamboge: '#FFC674',
  gray: '#747474',
  green: '#1DA213',
  homeDepotOrange: '#EE7125',
  inputBlue: '#F1F8FC',
  inputBlueDark: 'rgba(52, 152, 219, 0.3)',
  inputRed: 'rgba(231, 76, 60, 0.3)',
  lightBlue: '#34AEFF',
  lightGray: '#D3D3D3',
  lightRed: '#D65842',
  mediumGray: '#808080',
  navyDark: '#1F2F3C',
  navyDark50: 'rgba(31, 47, 60, 0.5)',
  navyLight: '#B2BBC2',
  navyLight30: 'rgba(178, 187, 194, 0.3)',
  navyLight8: '#F9FAFB',
  navyLight10: 'rgba(178, 187, 194, 0.1)',
  navyMediumDark: '#2A3F50',
  // navyMediumDark95: 'rgba(42, 63, 80, 0.95)',
  navyMediumLight: '#6D8190',
  navyMediumLight20: 'rgba(109,129,144,0.2)',
  navyMediumLight10: 'rgba(109,129,144,0.1)',
  orange: '#FF9C1D',
  progressBlue: '#277AB1',
  progressGreen: '#1F973A',
  progressOrange: '#F5951B',
  purple: '#6A5C7F',
  purpleMedium: '#836AA6',
  red: '#E74C3C',
  rust: '#8A3400',
  saveGreen: '#3ECF2B',
  selectedItem: '#F6F6F6',
  tan: '#E0E2D5',
  teal: '#229499',
  tealDark: '#146E72',
  white: '#FFFFFF',
  whiteFullLight20: 'rgba(255,255,255,0.2)',
  whiteFullLight: 'rgba(255,255,255,0.3)',
  whiteHalfLight: 'rgba(255,255,255,0.5)',
  whiteMediumLight: 'rgba(255,255,255,0.8)',

  buckets: {
    drafts: '#B2BCC5',
    needs_approval: '#B2BCC5',
    requests: '#747474',
    queued: '#3C627D',
    active: '#1B8633',
    suspended: '#CA7C27',
    cancelled: '#E74C3C',
    completed: '#3498DB',
    rejected: '#E74C3C',
  },

  subBuckets: {
    pm_approval: '#697C8B',
    integrations: '#697C8B',
    accepted: '#697C8B',
    rejected: '#983025',
    scheduled: '#B2BBC2',
    timed_out: '#993025',
    requested: '#747474',
    queued: '#3C627D',
    en_route: '#229499',
    on_site: '#2A3F50',
    in_progress: '#1B8633',
    paused: '#FF9C1D',
    suspended: '#CA7C27',
    terminating: '#E74C3C',
    cancelled: '#E74C3C',
    completing: '#9FCFEE',
    completed: '#3498DB',
    manual_assign: '#747474',
  },

  userRoles: {
    consumer: '#E74C3C',
    internal: '#F5BD00',
    provider: '#3497DB',
  },
};

export const font = {
  colors: {
    dark: colors.navyDark,
    light: colors.white,
  },
  family: 'Montserrat',
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};

export const zIndices = {
  fab: 100,
};

export const spacer = (size = 1) => `${size * 0.5}rem`;

const theme = {
  breakpoints,
  colors,
  font,
  spacer,
  zIndices,
};

export default theme;
