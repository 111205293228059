import axios from 'axios';

export const actionTypes = {
  CATCH_BILLING_RECORDS_ERROR: 'CATCH_BILLING_RECORDS_ERROR',
  RECEIVE_BILLING_RECORDS: 'RECEIVE_BILLING_RECORDS',
  CLEAR_BILLING_RECORDS: 'CLEAR_BILLING_RECORDS',
  REQUEST_BILLING_RECORDS: 'REQUEST_BILLING_RECORDS',
};

export const catchBillingRecordsError = ({ error, org_id }) => ({
  type: actionTypes.CATCH_BILLING_RECORDS_ERROR,
  error,
  org_id,
});

export const receiveBillingRecords = (billing_records) => ({
  type: actionTypes.RECEIVE_BILLING_RECORDS,
  billing_records,
});

export const clearBillingRecords = (org_id) => ({
  type: actionTypes.CLEAR_BILLING_RECORDS,
  org_id,
});

export const requestBillingRecords = (org_id) => ({
  type: actionTypes.REQUEST_BILLING_RECORDS,
  org_id,
});

export const fetchBillingRecords = (org_id) => async (dispatch) => {
  dispatch(requestBillingRecords());

  try {
    const params = {};
    params.limit = 30;
    params.sort = 'createdAt:desc';
    params.offset = 0;
    const recordsData = await axios({
      method: 'GET',
      url: `/v2/organizations/${org_id}/billings`,
      params,
    });
    const results = recordsData.data.data.results; // eslint-disable-line prefer-destructuring

    return dispatch(receiveBillingRecords(results));
  } catch (e) {
    return dispatch(catchBillingRecordsError(e));
  }
};

const shouldFetchBillingRecords = (state) => {
  try {
    return !state.billing_records.isLoading;
  } catch (e) {
    return true;
  }
};

export const fetchBillingRecordsIfNeeded =
  (org_id) => async (dispatch, getState) => {
    if (shouldFetchBillingRecords(getState())) {
      return dispatch(fetchBillingRecords(org_id));
    }
    return Promise.resolve();
  };
