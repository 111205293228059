import React from 'react';
import App from 'next/app';
import { datadogRum } from '@datadog/browser-rum';
import { Provider as ReduxProvider } from 'react-redux';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { compose } from 'recompose';
import moment from 'moment';
import 'moment/locale/fr';
import { Montserrat } from 'next/font/google';
import { MuiThemeProvider, createTheme } from '@material-ui/core';

import { appWithTranslation } from 'next-i18next';
import { SWRConfig } from 'swr';
import connectApollo from '../components/Apollo/connectApollo';
import withReduxStore from '../components/withReduxStore';
import { FeatureFlagsProvider } from '../components/FeatureFlags';
import withRoot from '../components/withRoot';
import Intercom from '../utils/analytics/intercom';
import '../components/common/ValueSelectSlider/slider.scss';

import 'bootstrap/scss/bootstrap.scss';
import 'nprogress/nprogress.css';
import '../styles/index.scss';
import ToastMessageModal from '../components/common/ToastMessageModal';
import v2SWRConfig from '../utils/swr';
import clientConstants from '../config';
import { initializeDatadog } from '../../datadog';

const { BRAND_NAME } = process.env;

Intercom.init({
  app_id: clientConstants.INTERCOM_APP_ID,
  enabled: ['development', 'production'].includes(clientConstants.NODE_ENV),
});

const montserrat = Montserrat({
  weight: 'variable',
  style: ['normal', 'italic'],
  subsets: ['latin'],
});

const theme = createTheme({
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
});

class CustomApp extends App {
  componentDidMount() {
    initializeDatadog();

    // check if auth cookie is expired, if so redirect to /login
    const { reduxStore } = this.props;

    if (
      window &&
      reduxStore &&
      reduxStore.getState() &&
      reduxStore.getState().user &&
      reduxStore.getState().user.data &&
      reduxStore.getState().user.data.csrf
    ) {
      window.__CSRF_TOKEN = reduxStore.getState().user.data.csrf;
    }

    moment.locale(window.navigator.language);
  }

  componentDidUpdate() {
    if (!window.previouslyLoaded) {
      window.previouslyLoaded = true;
    }
  }

  render() {
    const { apolloClient, Component, pageProps, reduxStore } = this.props;
    const state = reduxStore.getState();
    const { user } = state;

    // If a user ID is present we know they're logged in
    if (user.data.id) {
      // Intercom is the chat app service
      Intercom.update({
        user_id: user.data.id,
        name: `${user.data.first_name} ${user.data.last_name}`,
        email: user.data.email,
      });

      datadogRum.setUser({
        id: user.data.id,
        permissions: user.data.permissions,
        roles: user.data.roles,
        loginRole: user.data.loginRole,
      });
    }

    return (
      <React.Fragment>
        <Head>
          <title>{pageProps.title || BRAND_NAME}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {['development', 'production'].includes(clientConstants.NODE_ENV) && (
            <script
              key="intercom"
              src={`https://widget.intercom.io/widget/${clientConstants.INTERCOM_APP_ID}`}
              type="text/javascript"
              async
            />
          )}
        </Head>
        <style jsx global>{`
          * {
            font-family: ${montserrat.style.fontFamily}, 'Helvetica Neue Light',
              'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
          }
        `}</style>
        <SWRConfig value={v2SWRConfig}>
          <ApolloProvider client={apolloClient}>
            <ReduxProvider store={reduxStore}>
              <FeatureFlagsProvider>
                <MuiThemeProvider theme={theme}>
                  <Component
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...pageProps}
                  />
                </MuiThemeProvider>
                <ToastMessageModal />
              </FeatureFlagsProvider>
            </ReduxProvider>
          </ApolloProvider>
        </SWRConfig>
      </React.Fragment>
    );
  }
}

export default compose(
  appWithTranslation,
  withRoot,
  withReduxStore,
  connectApollo,
)(CustomApp);
