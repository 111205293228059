import { GetRatesResponse } from '../../services/api/RatesService';

export const FETCH_RATES_REQUEST = 'FETCH_RATES_REQUEST';
export const FETCH_RATES_SUCCESS = 'FETCH_RATES_SUCCESS';
export const FETCH_RATES_FAILURE = 'FETCH_RATES_FAILURE';

interface FetchRatesAction {
  type: typeof FETCH_RATES_REQUEST;
}

interface FetchRatesSuccessAction {
  type: typeof FETCH_RATES_SUCCESS;
  payload: GetRatesResponse['data'];
}

interface FetchRatesFailureAction {
  type: typeof FETCH_RATES_FAILURE;
  error: Error;
}

export type RateLookupActionTypes =
  | FetchRatesAction
  | FetchRatesSuccessAction
  | FetchRatesFailureAction;
