import { ClaimsFiltersState } from '../reducers/claimsFiltersReducer';

export const CLEAR_CLAIMS_FILTERS = 'CLEAR_CLAIMS_FILTERS';
export const UPDATE_CLAIMS_FILTERS = 'UPDATE_CLAIMS_FILTERS';

interface ClearClaimsFiltersAction {
  type: typeof CLEAR_CLAIMS_FILTERS;
}

interface UpdateClaimsFiltersAction {
  type: typeof UPDATE_CLAIMS_FILTERS;
  updated: Partial<ClaimsFiltersState>;
}

export type ClaimsFiltersActionTypes =
  | ClearClaimsFiltersAction
  | UpdateClaimsFiltersAction;
