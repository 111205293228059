import classnames from 'classnames';
import css from 'styled-jsx/css';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Heading from './Heading';
import Text from './Text';
import { COLOR, SPACING } from './theme';

type TooltipProps = React.ComponentProps<typeof ReactTooltip>;

const { className, styles } = css.resolve`
  div {
    background-color: ${COLOR.black} !important;
    padding: ${SPACING.sm}px;
  }

  .large {
    max-width: 320px;
  }

  div.place-top:after {
    border-top-color: ${COLOR.black} !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
  }

  div.place-right:after {
    border-right-color: ${COLOR.black} !important;
    border-right-style: solid !important;
    border-right-width: 6px !important;
  }

  div.place-bottom:after {
    border-bottom-color: ${COLOR.black} !important;
    border-bottom-style: solid !important;
    border-bottom-width: 6px !important;
  }

  div.place-left:after {
    border-left-color: ${COLOR.black} !important;
    border-left-style: solid !important;
    border-left-width: 6px !important;
  }
`;

type Props = {
  clickable?: TooltipProps['clickable'];
  delayHide?: TooltipProps['delayHide'];
  delayShow?: TooltipProps['delayShow'];
  id: TooltipProps['id'];
  place?: TooltipProps['place'];
  primaryText?: string;
  secondaryText?: string;
  size?: 'large' | 'small';
  children?: React.ReactNode;
};

const Tooltip: React.FC<Props> = ({
  clickable,
  delayHide,
  delayShow,
  id,
  place,
  primaryText,
  secondaryText,
  size,
  children,
}): JSX.Element | null => {
  if (!primaryText && !secondaryText && !children) return null;

  return (
    <ReactTooltip
      clickable={clickable}
      delayHide={delayHide}
      delayShow={delayShow}
      // @ts-expect-error legacy behavior
      anchorId={id}
      id={id}
      place={place}
      wrapper="div"
      className={classnames(className, size)}
    >
      {primaryText && size === 'large' && (
        <Heading onDark variant="h5">
          {primaryText}
        </Heading>
      )}
      {primaryText && size === 'small' && (
        <Text onDark weight="bold">
          {primaryText}
        </Text>
      )}
      {secondaryText && <Text onDark>{secondaryText}</Text>}
      {children}
      {styles}
    </ReactTooltip>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.defaultProps = {
  clickable: false,
  delayHide: 0,
  delayShow: 500,
  place: 'top',
  primaryText: undefined,
  secondaryText: undefined,
  size: 'small',
};

export default Tooltip;
