/* source: https://github.com/mui-org/material-ui/blob/master/examples/nextjs/src/withRoot.js */
import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';

import getPageContext from '../utils/getPageContext';

function withRoot(Component) {
  class WithRoot extends React.Component {
    pageContext = null;

    constructor(props) {
      super(props);

      const { pageContext } = this.props;
      this.pageContext = pageContext || getPageContext();
    }

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      // MuiThemeProvider makes the theme available down the React tree thanks to React context.
      return (
        <MuiThemeProvider theme={this.pageContext.theme}>
          <Component
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
          />
        </MuiThemeProvider>
      );
    }
  }

  WithRoot.defaultProps = {
    pageContext: undefined,
  };

  WithRoot.propTypes = {
    pageContext: PropTypes.object,
  };

  if (Component.getInitialProps) {
    WithRoot.getInitialProps = async (appContext) => {
      const appProps = await Component.getInitialProps(appContext);
      return { ...appProps };
    };
  }

  return WithRoot;
}

export default withRoot;
