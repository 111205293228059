import { defaultsDeep, valuesIn } from 'lodash';
import { ALL_MARKETS } from '../../../constants/markets';

import {
  SEARCH_DEFAULT_DECAY_PARAMS,
  SEARCH_DEFAULT_OPTION,
} from '../../../constants/search';
import createQueryBuilder from '../createQueryBuilder';

export const PROPERTY_SEARCH_FIELD = Object.freeze({
  address: 'address',
  id: 'id',
  name: 'name',
  zipcode: 'zipcode',
});

export const ALL_PROPERTY_SEARCH_FIELDS = Object.freeze(
  valuesIn(PROPERTY_SEARCH_FIELD),
);

export const buildPropertiesQuery = (query, option) => {
  const {
    fields = ALL_PROPERTY_SEARCH_FIELDS,
    filter: { marketId, propertyClasses, regionId },
  } = defaultsDeep(option, SEARCH_DEFAULT_OPTION);

  const builder = createQueryBuilder(option);

  if (query && fields.length) {
    // Define the minimum score.
    builder.rawOption('min_score', 0.01);

    if (fields.includes(PROPERTY_SEARCH_FIELD.id)) {
      // Exclude matches on `id` from function score.
      builder.orQuery('match', PROPERTY_SEARCH_FIELD.id, query);
    }

    builder.orQuery(
      'function_score',
      {
        exp: {
          created_at: SEARCH_DEFAULT_DECAY_PARAMS,
        },
      },
      (agg) => {
        if (fields.includes(PROPERTY_SEARCH_FIELD.zipcode)) {
          agg.orQuery('match', PROPERTY_SEARCH_FIELD.zipcode, query);
        }

        if (fields.includes(PROPERTY_SEARCH_FIELD.address)) {
          agg.orQuery('match', PROPERTY_SEARCH_FIELD.address, query);
        }

        if (fields.includes(PROPERTY_SEARCH_FIELD.name)) {
          agg.orQuery('multi_match', {
            query,
            fields: [
              PROPERTY_SEARCH_FIELD.name,
              `${PROPERTY_SEARCH_FIELD.name}.joined`,
            ],
          });
        }
        return agg;
      },
    );
  }

  // Market filter.
  if (marketId != null && marketId !== ALL_MARKETS.id) {
    builder.andFilter('term', 'market_id', marketId);
  }

  // Region filter.
  if (regionId != null) {
    builder.andFilter('term', 'region_id', regionId);
  }

  // Property class filter.
  if (propertyClasses && propertyClasses.length) {
    builder.andFilter('terms', 'class', propertyClasses);
  }

  return builder.build();
};

export default buildPropertiesQuery;
