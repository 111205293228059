import { activeMarketLabelsConstants } from '../constants/activeMarketLabelsConstants';

const {
  CATCH_ACTIVE_MARKET_LABELS_ERROR,
  RECEIVE_ACTIVE_MARKET_LABELS,
  REQUEST_ACTIVE_MARKET_LABELS,
} = activeMarketLabelsConstants;

const defaultState = {
  markets: undefined,
  errors: undefined,
  finishedLoadAt: undefined,
  isLoading: false,
  startedLoadAt: undefined,
};

const activeMarketLabelsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CATCH_ACTIVE_MARKET_LABELS_ERROR:
      return {
        ...state,
        errors: Array.isArray(state.errors)
          ? [...state.errors, action.error]
          : [action.error],
        finishedLoadAt: Date.now(),
        isLoading: false,
      };
    case RECEIVE_ACTIVE_MARKET_LABELS:
      return {
        ...state,
        finishedLoadAt: Date.now(),
        isLoading: false,
        markets: action.markets,
      };
    case REQUEST_ACTIVE_MARKET_LABELS:
      return {
        ...state,
        finishedLoadAt: undefined,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default activeMarketLabelsReducer;
