import builder from 'elastic-builder';
import {
  ConsumerRequestStatus,
  JobStatus,
  ServiceType,
} from '@internal-tools/utils';

import { BackgroundAdjudicationStatus } from '../../constants/backgroundAdjudicationStatuses';
import { BackgroundStatus } from '../../constants/backgroundStatuses';
import { InsuranceStatus } from '../../constants/insuranceStatuses';
import { RegistrationStatus } from '../../constants/RegistrationStatus';
import { SearchIndex, SEARCH_SORT_DIRECTION } from '../../constants/search';
import {
  UserDisplayRole,
  UserRole,
  USER_ROLE_LABEL,
} from '../../constants/userRoles';
import { UserStatus } from '../../constants/userStatuses';

type JobSource = {
  address: string;
  consumer_first_name: string;
  consumer_id: string;
  consumer_last_name: string;
  consumer_request_created_at: string | null;
  consumer_request_id: string | null;
  consumer_request_status: ConsumerRequestStatus | null;
  job_code: string;
  job_code_number: string;
  job_created_at: string;
  job_id: string;
  job_invoice_external_id: string | null;
  job_invoice_id: string | null;
  job_status: JobStatus;
  market_id: string | null;
  provider_first_name: string;
  provider_id: string;
  provider_last_name: string;
  region_id: string | null;
  service_type: ServiceType;
  workorder_created_at: string | null;
  workorder_external_id: string | null;
  workorder_id: string | null;
  workorder_name: string | null;
  workorder_priority: number | null;
  workorder_status: number | null;
  zipcode: string | null;
};

type OrganizationSource = {
  created_at: string;
  id: string;
  internal_name: string | null;
  job_count: number;
  market_count: number;
  market_id: string[];
  name: string;
  region_id: string[];
  status: number;
};

type PromocodeSource = {
  code: string;
  created_at: string;
  description: string;
  end_date: string | null;
  id: string;
  redemption_limit: number;
  redemption_limit_per_user: number;
  start_date: string | null;
  type: number;
  value: number;
  value_unit: number;
};

type PropertySource = {
  address: string;
  class: number;
  created_at: string;
  id: string;
  market_id: string;
  name: string;
  region_id: string;
  type: number;
};

type ReferralSource = {
  claimed: boolean;
  code: string;
  count: number;
  created_at: string;
  market_id: string;
  rec_first_name: string;
  rec_last_name: string;
  rec_user_id: string;
  ref_first_name: string;
  ref_last_name: string;
  ref_user_id: string;
  referrer_id: string;
  region_id: string;
  type: number;
};

export type UserSourceService = {
  experience: number;
  id: string;
  licensed: boolean;
  type: ServiceType;
};

export type UserSource = {
  available: boolean;
  background_adjudication: BackgroundAdjudicationStatus | null;
  background_status: BackgroundStatus | null;
  basic_status: BackgroundStatus | null;
  created_at: string;
  email: string;
  first_name: string | null;
  ghost: boolean;
  id: string;
  identity_confidence: number;
  identity_fail_count: number;
  in_house_organization_ids: string[];
  insurance_grace_override: string | null;
  insurance_status: InsuranceStatus | null;
  insurance_submitted_coterie_tos: 0 | 1;
  insured: 0 | 1;
  job_count: number;
  last_name: string | null;
  market_id: string | null;
  market_name: string | null;
  mobile: string | null;
  organizations: string[];
  partners: string[];
  preferred_organization_ids: string[];
  pro_status: BackgroundStatus | null;
  property_ids_where_resident: string[];
  provider_active_job_count: number;
  region_id: string | null;
  registration_status: RegistrationStatus;
  roles: UserRole[] | UserDisplayRole[];
  // roles: number[];
  services: UserSourceService[];
  status: UserStatus;
  test: boolean;
  verified: 0 | 1;
  zipcode: string | null;
};

type Type<Index> = Index extends SearchIndex.Jobs
  ? 'job'
  : Index extends SearchIndex.Organizations
  ? 'organization'
  : Index extends SearchIndex.Promocodes
  ? 'promocode'
  : Index extends SearchIndex.Properties
  ? 'property'
  : Index extends SearchIndex.Referrals
  ? 'referral'
  : Index extends SearchIndex.Users
  ? 'user'
  : never;

type Source<Index> = Index extends SearchIndex.Jobs
  ? JobSource
  : Index extends SearchIndex.Organizations
  ? OrganizationSource
  : Index extends SearchIndex.Promocodes
  ? PromocodeSource
  : Index extends SearchIndex.Properties
  ? PropertySource
  : Index extends SearchIndex.Referrals
  ? ReferralSource
  : Index extends SearchIndex.Users
  ? UserSource
  : never;

export type Hit<Index extends SearchIndex> = {
  _id: string;
  _index: Index;
  _score: number;
  _type: Type<Index>;
  _source: Source<Index>;
};

export type MustQuery =
  | builder.BoolQuery
  | builder.MatchQuery
  | builder.NestedQuery
  | builder.RangeQuery
  | builder.TermQuery
  | builder.TermsQuery;

export type MustNotQuery = builder.TermQuery | builder.ExistsQuery;

export type SearchFilters = {
  searchTerm: string;
  roles: number[];
  rangeStart: string;
  rangeEnd: string;
  numberJobsStart: number | null;
  numberJobsEnd: number | null;
  marketId?: string;
  regionId?: string;
  propertyClasses?: number[];
  sortBy: string[];
  sortDirection?: SEARCH_SORT_DIRECTION | null;
  serviceTypes: number[];
  jobStatuses: number[];
  consumerRequestStatuses: number[];
  marketCountRange?: { lte: number; gte: number };
  limit: number;
  page: number;
  reset: boolean;
};

export type GenerateConsolidatedJobQuery = {
  statuses: JobStatus[];
  companyId: string;
  userId?: string;
  isEstimate?: boolean;
};

function transformUserRoles(hits: Array<{ _source: UserSource }>) {
  return hits.map((hit) => {
    const roles = hit._source.roles as Array<UserRole>;
    if (roles && !!(roles as Array<UserRole>).length) {
      // eslint-disable-next-line no-param-reassign
      hit._source.roles = roles
        .filter((role) =>
          [
            UserRole.ProAdmin,
            UserRole.ProDispatcher,
            UserRole.ProTech,
          ].includes(role),
        )
        .map((role) => ({
          role,
          name: USER_ROLE_LABEL[role],
        }))
        .sort((x) => x.role);
    }
    return hit;
  });
}

export const hitTransformers = {
  [SearchIndex.Users]: transformUserRoles,
};
