import { CONSUMER_REQ_ACTION_TYPE } from '../constants/consumerReqConstants';

const initialEditsState = {};

const initialState = {
  edits: initialEditsState,
  error: undefined,
  errorMessage: undefined,
  id: undefined,
  inEditMode: false,
  isUpdating: false,
  overlay: undefined,
};

const consumerReqReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_CATCH_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
        errorMessage: undefined,
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_SET_OVERLAY:
      return {
        ...state,
        overlay: action.overlay,
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_SET_IN_EDIT_MODE:
      return {
        ...state,
        edits: initialEditsState,
        inEditMode: action.inEditMode,
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_UPDATE_EDITS:
      return {
        ...state,
        edits: { ...state.edits, ...action.edits },
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_CLEAR_EDITS:
      return {
        ...state,
        edits: initialEditsState,
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_SET_IS_UPDATING:
      return {
        ...state,
        error: action.isUpdating ? undefined : state.error,
        errorMessage: action.isUpdating ? undefined : state.errorMessage,
        isUpdating: action.isUpdating,
      };
    case CONSUMER_REQ_ACTION_TYPE.CONSUMER_REQ_SET_ID:
      return {
        ...state,
        id: action.id,
      };
    default:
      action.type; // eslint-disable-line no-unused-expressions
      return state;
  }
};

export default consumerReqReducer;
