import valuesIn from 'lodash/valuesIn';

export default {
  WORKORDER_DELETED: -1,
  WORKORDER_REQUESTED: 5,
  WORKORDER_PENDING: 10,
  WORKORDER_DRAFT: 15,
  WORKORDER_INPROGRESS: 20,
  WORKORDER_COMPLETED: 30,
};
export const WORKORDER_STATUS = Object.freeze({
  deleted: -1,
  rejected: 2,
  approval_needed: 5,
  accepted: 10,
  draft: 15,
  in_progress: 20,
  completed: 30,
});

export const ALL_WORKORDER_STATUSES = valuesIn(WORKORDER_STATUS);
