export enum UserRole {
  Consumer = 1,
  Provider = 2,
  Internal = 3,
  ClaimsInternal = 4,
  ClaimsAdjuster = 5,
  ProTech = 6,
  ProDispatcher = 7,
  ProAdmin = 8,
}

export type UserDisplayRole = {
  role: UserRole;
  name: (typeof USER_ROLE_LABEL)[keyof typeof USER_ROLE_LABEL];
};

/**
 * @deprecated use enum instead
 */
export const USER_ROLE = Object.freeze({
  consumer: 1,
  customer: 1,
  provider: 2,
  internal: 3,
  ClaimsInternal: 4,
  claimsAdjuster: 5,
  proTech: 6,
  proDispatcher: 7,
  proAdmin: 8,
});

export const USER_ROLE_LABEL = {
  [UserRole.Consumer]: 'Consumer',
  [UserRole.Provider]: 'Provider',
  [UserRole.Internal]: 'Internal',
  [UserRole.ClaimsInternal]: 'Claims Internal',
  [UserRole.ClaimsAdjuster]: 'Claims Adjuster',
  [UserRole.ProTech]: 'Tech',
  [UserRole.ProDispatcher]: 'Dispatcher',
  [UserRole.ProAdmin]: 'Admin',
} as const;

export default USER_ROLE;

export const ALL_USER_ROLES = [
  UserRole.Consumer,
  UserRole.Provider,
  UserRole.Internal,
  UserRole.ClaimsInternal,
  UserRole.ClaimsAdjuster,
  UserRole.ProAdmin,
  UserRole.ProDispatcher,
  UserRole.ProTech,
];

export const PROFILE_ROLES = [
  UserRole.Consumer,
  UserRole.Provider,
  UserRole.Internal,
];
