import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  styled,
  useRadioGroup,
} from '@mui/material';

interface CustomFormControlLabelType extends FormControlLabelProps {
  labelColor?: string;
}

const SelectedFormControlLabel = styled((props: CustomFormControlLabelType) => {
  const { control, label, value, className } = props;
  return (
    <FormControlLabel
      control={control}
      label={label}
      value={value}
      className={className}
    />
  );
})(({ checked, labelColor }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: labelColor,
  },
}));
/** This is a custom Mui FormControlLabel component to dynamically change the color of a label upon selection.
 * This works for Mui Radio, Checkbox, and Switch components.
 * @default labelColor:'#0059A0'
 *  */
export default function CustomFormControlLabel(
  props: CustomFormControlLabelType,
) {
  const radioGroup = useRadioGroup();
  let checked = false;
  const { value, control, label, labelColor = '#0059A0', className } = props;
  if (radioGroup) {
    checked = radioGroup.value === value;
  }
  return (
    <SelectedFormControlLabel
      checked={checked}
      control={control}
      label={label}
      labelColor={labelColor}
      value={value}
      className={className}
    />
  );
}
