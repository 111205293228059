import React, { PropsWithChildren } from 'react';

import Icon from './Icon';
import { COLOR, SPACING, FONT_SIZE } from './theme';
import Text from './Text';

type Props = PropsWithChildren<{
  isError?: boolean;
  dataTestId?: string;
  idForText?: string;
}>;

const ErrorNote: React.FC<Props> = ({
  children,
  isError,
  idForText,
  dataTestId,
}) => (
  <div className="root" data-testId={dataTestId}>
    <Icon
      icon="error"
      size={FONT_SIZE.s18}
      style={{
        flex: 'none',
        marginRight: SPACING.sm,
        color: isError ? COLOR.red : COLOR.blue,
      }}
    />
    <Text
      color={isError ? 'error' : 'default'}
      size="medium"
      style={{ margin: 0 }}
      weight="regular"
      id={idForText}
    >
      {children}
    </Text>
    <style jsx>
      {`
        .root {
          align-items: center;
          padding: 12px;
          background-color: ${COLOR.white};
          border: 1px solid ${isError ? COLOR.red : COLOR.blue};
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          margin-top: ${SPACING.sm}px;
          overflow: hidden;
          width: 100%;
        }
      `}
    </style>
  </div>
);

ErrorNote.displayName = 'ErrorNote';

ErrorNote.defaultProps = {
  isError: false,
  dataTestId: undefined,
  idForText: undefined,
};

export default ErrorNote;
