import React from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import clientConstants from '@app/config';

function SplitFeatureFlagProvider({ userId, children }) {
  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: clientConstants.SPLIT_API_KEY,
          key: userId || 'anonymous',
        },
      }}
      updateOnSdkUpdate
    >
      {children}
    </SplitFactory>
  );
}

SplitFeatureFlagProvider.displayName = 'SplitFeatureFlagsProvider';

SplitFeatureFlagProvider.defaultProps = {
  userId: null,
};

SplitFeatureFlagProvider.propTypes = {
  userId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export { SplitFeatureFlagProvider };
