import color from 'color';
import css from 'styled-jsx/css';
import React from 'react';

import Heading, { HeadingVariant } from './Heading';
import Image from './Image';
import Text from './Text';
import { COLOR } from './theme';

const img = css.resolve`
  img {
    border-radius: 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const figcaption = css.resolve`
  figcaption {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const sizePx = {
  large: 160,
  medium: 96,
  small: 48,
  mini: 32,
} as const;

export type AvatarSize = keyof typeof sizePx;

type Props = {
  backgroundColor?: string;
  indicatorColor?: string;
  initials?: string;
  size?: number | AvatarSize;
  dataTestId?: string;
} & Omit<React.HTMLProps<HTMLElement>, 'size'>;

const Avatar: React.FC<Props> = ({
  backgroundColor,
  className,
  indicatorColor,
  initials,
  size,
  src,
  dataTestId,
  ...extraProps
}) => {
  let diameter = 0;
  if (typeof size === 'string') {
    diameter = sizePx[size];
  } else if (typeof size === 'number') {
    diameter = Math.max(0, size);
  }

  if (!diameter) {
    diameter = sizePx.medium;
  }

  const renderInitials = () => {
    if (!initials) {
      return null;
    }

    const twoInitials = initials.substr(0, 2).toUpperCase();

    if (diameter <= sizePx.mini) {
      return (
        <Text
          className={figcaption.className}
          element="figcaption"
          onDark={color(backgroundColor).isDark()}
          size="small"
          weight="semi"
        >
          {twoInitials}
        </Text>
      );
    }

    let headingVariant: HeadingVariant = 'h3';
    if (diameter <= sizePx.small) {
      headingVariant = 'h6';
    } else if (diameter <= sizePx.medium) {
      headingVariant = 'h5';
    }

    return (
      <Heading
        className={figcaption.className}
        element="figcaption"
        onDark={color(backgroundColor).isDark()}
        variant={headingVariant}
      >
        {twoInitials}
      </Heading>
    );
  };

  return (
    <figure
      data-testid={dataTestId}
      className={className}
      style={{ backgroundColor }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...extraProps}
    >
      {src && <Image alt="" className={img.className} src={src} />}
      {renderInitials()}
      {img.styles}
      {figcaption.styles}
      <style jsx>
        {`
          figure {
            border-radius: 50%;
            display: block;
            height: ${diameter}px;
            min-height: ${diameter}px;
            margin: 0;
            padding: 0;
            position: relative;
            width: ${diameter}px;
            min-width: ${diameter}px;
          }

          figure:after {
            background-color: ${indicatorColor || 'transparent'};
            bottom: 0;
            border: 2px solid #fff;
            border-radius: 50%;
            content: '';
            display: ${indicatorColor ? 'block' : 'none'};
            height: 35%;
            position: absolute;
            right: 0;
            width: 35%;
          }
        `}
      </style>
    </figure>
  );
};

Avatar.defaultProps = {
  backgroundColor: COLOR.darkGray,
  size: 'medium',
};

export default Avatar;
