import React from 'react';

import Text from './Text';

type Props = {
  isInvalid?: boolean;
} & Omit<React.HTMLAttributes<HTMLElement>, 'color'>;

const InputHelperText: React.FC<Props> = ({
  children,
  isInvalid,
  ...extraProps
}) => {
  if (typeof children !== 'string') return null;

  return (
    <Text
      color={isInvalid ? 'error' : 'gray'}
      size="medium"
      style={{ marginBottom: 0, marginTop: 4 }}
      weight="regular"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...extraProps}
    >
      {children}
    </Text>
  );
};

InputHelperText.defaultProps = {
  isInvalid: false,
};

export default InputHelperText;
