/* eslint-disable no-case-declarations */
import { defaultsDeep, isEqual, omit } from 'lodash';

import {
  DATE_PICKER_LIFETIME_DEFAULT_GTE,
  DATE_PICKER_LIFETIME_DEFAULT_LTE,
  SEARCH_CATEGORY,
  SEARCH_DEFAULT_OPTION,
} from '../../constants/search';

import {
  SEARCH_HEADER_RESULTS_DEFAULT,
  SEARCH_HEADER_RESULTS_FAILURE,
  SEARCH_HEADER_RESULTS_REQUEST,
  SEARCH_HEADER_RESULTS_UPDATED,
  SEARCH_HISTORY_ADD,
  SEARCH_HISTORY_REMOVE,
  SEARCH_PAGE_RESULTS_DEFAULT,
  SEARCH_PAGE_RESULTS_FAILURE,
  SEARCH_PAGE_RESULTS_REQUEST,
  SEARCH_PAGE_RESULTS_UPDATED,
} from '../actions/searchActions';

export const defaultState = {
  history: {
    items: [],
  },
  results: {
    header: {
      data: {},
      error: null,
      isLoading: true,
      text: '',
    },
    page: {
      data: {
        [SEARCH_CATEGORY.jobs]: {
          option: {
            ...SEARCH_DEFAULT_OPTION,
            filter: {
              ...SEARCH_DEFAULT_OPTION.filter,
              consumerRequestStatuses: [],
              dateRange: {
                gte: DATE_PICKER_LIFETIME_DEFAULT_GTE,
                lte: DATE_PICKER_LIFETIME_DEFAULT_LTE,
              },
              jobStatuses: [],
              serviceTypes: [],
            },
          },
          hits: null,
          total: null,
        },
        [SEARCH_CATEGORY.people]: {
          option: {
            ...SEARCH_DEFAULT_OPTION,
            filter: {
              ...SEARCH_DEFAULT_OPTION.filter,
              jobCountRange: {},
              roles: [],
            },
          },
          hits: null,
          total: null,
        },
        [SEARCH_CATEGORY.properties]: {
          option: {
            ...SEARCH_DEFAULT_OPTION,
            filter: {
              ...SEARCH_DEFAULT_OPTION.filter,
              propertyClasses: [],
            },
          },
          hits: null,
          total: null,
        },
        [SEARCH_CATEGORY.organizations]: {
          option: {
            ...SEARCH_DEFAULT_OPTION,
            filter: {
              ...SEARCH_DEFAULT_OPTION.filter,
              marketCountRange: {},
            },
          },
          hits: null,
          total: null,
        },
        [SEARCH_CATEGORY.promotions]: {
          option: { ...SEARCH_DEFAULT_OPTION },
          hits: null,
          total: null,
        },
        [SEARCH_CATEGORY.referrals]: {
          option: { ...SEARCH_DEFAULT_OPTION },
          hits: null,
          total: null,
        },
      },
      error: null,
      isLoading: true,
      text: '',
    },
  },
};

const searchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_HISTORY_ADD: {
      const items = state.history.items.filter(
        (item) => item.value !== action.value,
      );
      items.unshift({ value: action.value, date: new Date() });
      return {
        ...state,
        history: { items: items.slice(0, 10) },
      };
    }

    case SEARCH_HISTORY_REMOVE: {
      const items = state.history.items.filter(
        (item) => item.value !== action.value,
      );
      return { ...state, history: { items } };
    }

    case SEARCH_PAGE_RESULTS_DEFAULT:
      return {
        ...state,
        results: {
          ...state.results,
          page: {
            ...defaultsDeep(
              {
                data: {
                  [SEARCH_CATEGORY.jobs]: { hits: null, total: null },
                  [SEARCH_CATEGORY.people]: { hits: null, total: null },
                  [SEARCH_CATEGORY.properties]: { hits: null, total: null },
                  [SEARCH_CATEGORY.organizations]: { hits: null, total: null },
                  [SEARCH_CATEGORY.promotions]: { hits: null, total: null },
                  [SEARCH_CATEGORY.referrals]: { hits: null, total: null },
                },
              },
              state.results.page,
            ),
            error: null,
            isLoading: false,
          },
        },
      };

    case SEARCH_PAGE_RESULTS_REQUEST:
      return {
        ...state,
        results: {
          ...state.results,
          page: {
            ...state.results.page,
            error: null,
            isLoading: true,
          },
        },
      };

    case SEARCH_PAGE_RESULTS_UPDATED:
      const { text, data } = action;
      if (state.results.page.text === action.text) {
        // $FlowFixMe
        Object.keys(data).forEach((category) => {
          const sItem = state.results.page.data[category];
          const aItem = data[category];
          if (
            sItem &&
            sItem.hits &&
            isEqual(omit(sItem.option, 'page'), omit(aItem.option, 'page'))
          ) {
            aItem.hits = [
              ...sItem.hits,
              // $FlowFixMe
              ...aItem.hits.filter(
                (aHit) => !sItem.hits.some((sHit) => sHit._id === aHit._id),
              ),
            ];
          }
        });
      }
      return {
        ...state,
        results: {
          ...state.results,
          page: {
            ...state.results.page,
            data: { ...state.results.page.data, ...data },
            error: null,
            isLoading: false,
            text,
          },
        },
      };

    case SEARCH_PAGE_RESULTS_FAILURE:
      return {
        ...state,
        results: {
          ...state.results,
          page: {
            ...state.results.page,
            data: { ...defaultState.results.page.data },
            error: action.error,
            isLoading: false,
          },
        },
      };

    case SEARCH_HEADER_RESULTS_DEFAULT:
      return {
        ...state,
        results: {
          ...state.results,
          header: {
            ...defaultState.results.header,
            isLoading: false,
          },
        },
      };

    case SEARCH_HEADER_RESULTS_REQUEST:
      return {
        ...state,
        results: {
          ...state.results,
          header: {
            ...state.results.header,
            data: {},
            error: null,
            isLoading: true,
          },
        },
      };

    case SEARCH_HEADER_RESULTS_UPDATED:
      return {
        ...state,
        results: {
          ...state.results,
          header: {
            ...state.results.header,
            data: action.data,
            error: null,
            isLoading: false,
          },
        },
      };

    case SEARCH_HEADER_RESULTS_FAILURE:
      return {
        ...state,
        results: {
          ...state.results,
          header: {
            ...state.results.header,
            data: { ...defaultState.results.header.data },
            error: action.error,
            isLoading: false,
          },
        },
      };

    default:
      return state;
  }
};

export default searchReducer;
