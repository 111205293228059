import builder, { Sort } from 'elastic-builder';
import { SEARCH_SORT_DIRECTION } from '../../constants/search';
import { SearchFilters } from './common';

export const generateUserReferralSearchBarSearch = (
  searchTerm: string,
): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder.boolQuery().should([
        builder.matchQuery('code', searchTerm),
        builder.wildcardQuery('code', `*${searchTerm}*`),
        builder
          .functionScoreQuery()
          .functions([
            builder
              .decayScoreFunction('exp', 'created_at')
              .origin('now')
              .scale('365d')
              .offset('2d')
              .decay(0.9),
          ])
          .query(
            builder
              .boolQuery()
              .should([
                builder.matchQuery('rec_first_name', searchTerm),
                builder.matchQuery('rec_last_name', searchTerm),
                builder
                  .multiMatchQuery(
                    ['rec_first_name', 'rec_last_name'],
                    searchTerm,
                  )
                  .type('cross_fields'),
                builder
                  .multiMatchQuery(
                    ['rec_first_name.joined', 'rec_last_name.joined'],
                    searchTerm,
                  )
                  .type('cross_fields'),
                builder.matchQuery('ref_first_name', searchTerm),
                builder.matchQuery('ref_last_name', searchTerm),
                builder
                  .multiMatchQuery(
                    ['ref_first_name', 'ref_last_name'],
                    searchTerm,
                  )
                  .type('cross_fields'),
                builder
                  .multiMatchQuery(
                    ['ref_first_name.joined', 'ref_last_name.joined'],
                    searchTerm,
                  )
                  .type('cross_fields'),
              ]),
          ),
      ]),
    )
    .minScore(0.01);

  return searchBody;
};

export const generateUserReferralSearchPage = ({
  searchTerm,
  sortBy,
  sortDirection,
}: SearchFilters): builder.RequestBodySearch => {
  const searchBody = builder
    .requestBodySearch()
    .query(
      builder.boolQuery().should([
        builder.matchQuery('code', searchTerm),
        builder.wildcardQuery('code', `*${searchTerm}*`),
        builder
          .functionScoreQuery()
          .functions([
            builder
              .decayScoreFunction('exp', 'created_at')
              .origin('now')
              .scale('365d')
              .offset('2d')
              .decay(0.9),
          ])
          .query(
            builder
              .boolQuery()
              .should([
                builder.matchQuery('rec_first_name', searchTerm),
                builder.matchQuery('rec_last_name', searchTerm),
                builder
                  .multiMatchQuery(
                    ['rec_first_name', 'rec_last_name'],
                    searchTerm,
                  )
                  .type('cross_fields'),
                builder
                  .multiMatchQuery(
                    ['rec_first_name.joined', 'rec_last_name.joined'],
                    searchTerm,
                  )
                  .type('cross_fields'),
                builder.matchQuery('ref_first_name', searchTerm),
                builder.matchQuery('ref_last_name', searchTerm),
                builder
                  .multiMatchQuery(
                    ['ref_first_name', 'ref_last_name'],
                    searchTerm,
                  )
                  .type('cross_fields'),
                builder
                  .multiMatchQuery(
                    ['ref_first_name.joined', 'ref_last_name.joined'],
                    searchTerm,
                  )
                  .type('cross_fields'),
              ]),
          ),
      ]),
    )
    .minScore(0.01);

  if (sortBy instanceof Array) {
    const sorts: Sort[] = sortBy.map((sorter) =>
      builder.sort(sorter, sortDirection || SEARCH_SORT_DIRECTION.asc),
    );
    searchBody.sorts(sorts);
  }

  return searchBody;
};
